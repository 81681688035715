export const MESSAGE_CATEGORY = {
  SKYHARBOR_PROJECT_INFO_UPDATE: "SKYHARBOR_PROJECT_INFO_UPDATE",
  SKYHARBOR_PROJECT_COMMENT_NEW: "SKYHARBOR_PROJECT_COMMENT_NEW",
  SKYHARBOR_PROJECT_FINDING_COMMENT_NEW: "SKYHARBOR_PROJECT_FINDING_COMMENT_NEW",
  SKYHARBOR_PROJECT_NEW: "SKYHARBOR_PROJECT_NEW",
  SKYHARBOR_PROJECT_DELETE: "SKYHARBOR_PROJECT_DELETE",
  SKYHARBOR_PROJECT_MEETING_REQUEST: "SKYHARBOR_PROJECT_MEETING_REQUEST",
  SKYHARBOR_USER_LOGIN: "SKYHARBOR_USER_LOGIN",
  SKYHARBOR_USER_INVITE: "SKYHARBOR_USER_INVITE",
  SKYHARBOR_USER_SIGNUP: "SKYHARBOR_USER_SIGNUP",
  SKYHARBOR_REPORT_SIGNOFF: "SKYHARBOR_REPORT_SIGNOFF",
  SKYHARBOR_JIRA_TICKET_CREATE: "SKYHARBOR_JIRA_TICKET_CREATE",
  SKYHARBOR_JIRA_TICKET_DONE: "SKYHARBOR_JIRA_TICKET_DONE",
  SKYHARBOR_MARKETPLACE_PROJECT_BE_INTERESTED: "SKYHARBOR_MARKETPLACE_PROJECT_BE_INTERESTED",
  SKYHARBOR_SKYNET_PROJECT_NEW: "SKYHARBOR_SKYNET_PROJECT_NEW",
  SKYHARBOR_SKYNET_VMS_COMMENT_SYNC: "SKYHARBOR_SKYNET_VMS_COMMENT_SYNC",
  SKYHARBOR_SKYNET_SIGNAL_STATUS_CHANGE: "SKYHARBOR_SKYNET_SIGNAL_STATUS_CHANGE",
  SKYHARBOR_SHARELINK_CREATE_UPDATE: "SKYHARBOR_SHARELINK_CREATE_UPDATE",
  SKYHARBOR_NOTIFY_REPORT_REVIEW: "SKYHARBOR_NOTIFY_REPORT_REVIEW",
  SKYHARBOR_RESEND_INVITATION: "SKYHARBOR_RESEND_INVITATION",
  SKYHARBOR_KYC_REQUEST: "SKYHARBOR_KYC_REQUEST",
  SKYHARBOR_PDF_DOWNLOAD: "SKYHARBOR_PDF_DOWNLOAD",
  SKYHARBOR_WELCOME_MESSAGE: "SKYHARBOR_WELCOME_MESSAGE",
  SKYHARBOR_EMBLEM_REQUEST: "SKYHARBOR_EMBLEM_REQUEST",
  SKYHARBOR_INTERNAL_CHANNEL_UPDATE: "SKYHARBOR_INTERNAL_CHANNEL_UPDATE",
  SKYHARBOR_MEETING_REQUEST_FOR_BADGE: "SKYHARBOR_MEETING_REQUEST_FOR_BADGE",
  SKYHARBOR_CONTACT_BD: "SKYHARBOR_CONTACT_BD",
  SKYHARBOR_RISK_MANAGER_RISK_SCORE: "SKYHARBOR_RISK_MANAGER_RISK_SCORE",
  SKYHARBOR_RISK_MANAGER_MONITORING_GROUP_UPDATE: "SKYHARBOR_RISK_MANAGER_MONITORING_GROUP_UPDATE",
  SKYHARBOR_RISK_MANAGER_ADDRESS_UPDATE: "SKYHARBOR_RISK_MANAGER_ADDRESS_UPDATE",
  SKYHARBOR_RISK_MANAGER_ADDRESS_DECISION_UPDATE: "SKYHARBOR_RISK_MANAGER_ADDRESS_DECISION_UPDATE",
  SKYHARBOR_RISK_MANAGER_ALERTS_TRIGGERED: "SKYHARBOR_RISK_MANAGER_ALERTS_TRIGGERED",
  SKYHARBOR_RISK_MANAGER_EVIDENCE_ADDED: "SKYHARBOR_RISK_MANAGER_EVIDENCE_ADDED",
  SKYHARBOR_RISK_MANAGER_SUMMARY_UPDATE: "SKYHARBOR_RISK_MANAGER_SUMMARY_UPDATE",
  SKYHARBOR_RISK_MANAGER_KYT_ALERTS_TRIGGERED: "SKYHARBOR_RISK_MANAGER_KYT_ALERTS_TRIGGERED",
  SKYHARBOR_RISK_MANAGER_REFRESH_COUNT_TABLE: "SKYHARBOR_RISK_MANAGER_REFRESH_COUNT_TABLE",
};

export const NEW_CLIENT_QUOTE_REQUEST_NOTIFICATION_LIST_PROD = [
  "connie.lam@certik.com",
  "jason.jiang@certik.com",
];
export const NEW_CLIENT_QUOTE_REQUEST_NOTIFICATION_LIST_TEST = [
  "ziyi.zhang@certik.com",
  "kun.zhao@certik.com",
  "yuzhi.han@certik.com",
  "chloe.tan@certik.com",
];

export const MARKETPLACE_NOTIFICATION_LIST_PROD = [
  "connie.lam@certik.com",
  "jason.jiang@certik.com",
];

export const MARKETPLACE_NOTIFICATION_LIST_TEST = [
  "ziyi.zhang@certik.com",
  "kun.zhao@certik.com",
  "yuzhi.han@certik.com",
  "chloe.tan@certik.com",
];

// Spy channels
export const SPY_CHANNEL_LIST_PROD = ["connie.lam@certik.com"];
export const SPY_CHANNEL_LIST_TEST = [
  "kun.zhao@certik.com",
  "chloe.tan@certik.com",
  "yuzhi.han@certik.com",
  "tony.kuo@certik.com",
];

export const MESSAGE_SOURCE = {
  SKYHARBOR: "SKYHARBOR",
};

export const MESSAGE_VERSION = {
  V1: "V1",
};

// Keys that nice to have on top level of the record.
export const NOTIFICATION_RECORD_SELECTED_KEYS = ["projectId", "userId", "reportId", "tenantId"];
export const NOTIFICATION_SCHEDULE_CATEGORY = {
  MARKETPLACE: "marketplace",
  REPORT_REMINDER: "report_reminder",
};

export const MARKETPLACE_NOTIFICATION_EXPONENTIAL_BACKOFF_INTERVAL_SET_IN_DAYS = [2, 4, 8, 15, 30];
export const SIGNUP_NOTIFICATION_BODY = " has accepted invitation.";
export const SIGNUP_NOTIFICATION_TITLE = "Invitation Accepted";
export const AUDIT_PROJECTS_COMMENTS_NOTIFICATION_TITLE = "Comments added";
export const EMBLEM_REQUESTED_NOTIFICATION_TITLE = "Emblem requested";

export const ONBOARD_SLACK_CHANNEL_DESCRIPTION = `This CertiK SkyHarbor Audit channel allows you to collaborate with our BD Representatives and Support team on your project status inquiries, action items, project activity notifications and any other questions.`;
export const ONBOARD_TELEGRAM_CHAT_DESCRIPTION = `This CertiK SkyHarbor Audit chat allows you to collaborate with our BD Representatives and Support team on your project status inquiries, action items, project activity notifications and any other questions.`;

export const APP_TYPE = {
  SLACK: "slack",
  DISCORD: "discord",
  TELEGRAM: "telegram",
  EMAIL: "email",
};

export const APP_TYPE_LABEL_MAP = {
  [APP_TYPE.SLACK]: "Slack",
  [APP_TYPE.DISCORD]: "Discord",
  [APP_TYPE.TELEGRAM]: "Telegram",
};

export const RISK_MANAGER_MONITORING_GROUP_UPDATE_TYPE = {
  MONITORING_GROUP_CREATE: "MONITORING_GROUP_CREATE",
  MONITORING_GROUP_UPDATE: "MONITORING_GROUP_UPDATE",
  ADD_ROLE: "ADD_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  ADD_ADDRESS: "ADD_ADDRESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  /** @deprecated */
  CASE_CREATE: "CASE_CREATE",
  /** @deprecated */
  CASE_UPDATE: "CASE_UPDATE",
};

export const RISK_MANAGER_ADDRESS_UPDATE_TYPE = {
  USER_ADDRESS_UPDATE: "USER_ADDRESS_UPDATE",
  RISK_SCORE_UPDATE: "RISK_SCORE_UPDATE",
};

export const NOTIFICATION_FIELD_NAMES = {
  privacyStatus: "access mode",
};
