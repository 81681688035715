import React from "react";
import { Button, Col } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ListIcon, Paragraph } from "../SharedComponents";
import { COLORS } from "../../const";

const ClickableCol = styled(Col)`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props?.disabled ? 0.5 : 1)};

  ${(props) =>
    !props?.disabled &&
    `
  &:hover > span > svg > path {
    fill: ${COLORS["CK2"]};
  }
  &:hover > span > svg > g > path {
    fill: ${COLORS["CK2"]};
  }
  &:hover > p {
    color: ${COLORS["CK2"]};
  }
  `}
`;

export const StyledViewMoreButton = styled(Button)`
  background-color: var(--gray-100);
  border: none;
  box-shadow: none;
  border-radius: 0px;
  color: var(--gray-800) !important;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    background-color: var(--gray-200);
    border: none;
  }

  &:active,
  &:focus {
    background-color: var(--gray-300);
  }

  margin-bottom: 4px;
  height: 30px;
`;

export const ViewMoreButton = (props) => {
  const { t } = useTranslation("", {
    keyPrefix: "components.SharedComponents.Buttons.ViewMoreButton",
  });
  const defaultChildrenLabel = props.children || t("viewMore");
  return <StyledViewMoreButton {...props}>{defaultChildrenLabel}</StyledViewMoreButton>;
};

export function ViewMoreLinkButton({
  onClick,
  containerStyle = {},
  paragraphProps = {},
  text = "View All",
}) {
  return (
    <ClickableCol
      style={{ gap: 6, padding: "4px 0px", ...containerStyle }}
      onClick={(e) => {
        e?.stopPropagation();
        onClick?.();
      }}
    >
      <ListIcon size={20} />
      <Paragraph
        level={2}
        weight={500}
        fontSize={undefined}
        lineHeight={undefined}
        {...paragraphProps}
      >
        {text}
      </Paragraph>
    </ClickableCol>
  );
}
