export const DEFAULT_ERROR_RETRY_COUNT = 3;

export function onErrorRetry(error, key, config, revalidate, revalidateOpts) {
  /* Only retry 504 */

  if (error.response.status !== 504) {
    return;
  }

  /* Base implementation adopted from SWR follows */

  const maxRetryCount = config.errorRetryCount || DEFAULT_ERROR_RETRY_COUNT;
  const currentRetryCount = revalidateOpts.retryCount;

  // Exponential backoff
  const timeout =
    Math.trunc((Math.random() + 0.5) * 2 ** Math.min(currentRetryCount, 8)) *
    config.errorRetryInterval;

  if (maxRetryCount !== null && currentRetryCount > maxRetryCount) {
    return;
  }

  setTimeout(() => {
    revalidate(revalidateOpts);
  }, timeout);
}

export default async function fetcher(url, options) {
  console.log("fetcher", url, options);
  const res = await fetch(url, {
    credentials: "include",
    ...options,
  });

  if (!res.ok) {
    const body = await res.text();

    let err;

    if (res.status >= 500) {
      err = new ServerError(res.statusText);
    } else if (res.status === 404) {
      err = new NotFoundError(res.statusText);
    } else if (res.status >= 400) {
      err = new ClientError(res.statusText);
    } else {
      err = new Error(res.statusText);
    }

    err.body = body;
    err.status = res.status;

    throw err;
  }

  return res.json();
}
