import React, { CSSProperties } from "react";
import styled from "styled-components";
import { COLORS } from "../../const";
import { addPxIfNumber } from "../../utils/frontend/utils";

export const paragraphStyles = {
  [1]: {
    fontSize: "16px",
    lineHeight: "24px",
    weight: 400,
    color: "gray-800",
    marginBottom: "0px",
  },
  [2]: {
    fontSize: "13px",
    lineHeight: "20px",
    weight: 400,
    color: "gray-800",
    marginBottom: "0px",
  },
  [3]: {
    fontSize: "12px",
    lineHeight: "16px",
    weight: 400,
    color: "gray-800",
    marginBottom: "0px",
  },
};

// type alias for <p> element props
type HTMLParagraphProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export type ParagraphProps = {
  /**  Level for the text (1-3). corresponds with P16r, P14r, and N. */
  level?: number;
  /**  this toggles the font weight to 500, else it's 400. */
  med?: boolean;
  /**  This will override the font size. */
  fontSize?: string | number;
  /**  This will override the line height. */
  lineHeight?: string;
  /** This will override the font weight. */
  weight?: string | number;
  /**  this is override the tag type... default is p, but can be something like span, label, etc. */
  as?: string;
  /**  This will override the margin top. */
  marginTop?: string | number;
  /** This will override the margin bottom. */
  marginBottom?: string | number;
  /**  This will override the margin top+bottom, but specifically-defined top/bottom values will take precedence. */
  marginY?: string | number;
  /**  This will override the padding top. */
  paddingTop?: string | number;
  /**  This will override the padding bottom. */
  paddingBottom?: string | number;
  /** This will override the padding top+bottom, but specifically-defined top/bottom values will take precedence. */
  paddingY?: string | number;
  /**  This will override the color. */
  color?: string;
  /**  Show ellipsis symbol if the text overflow */
  ellipsis?: boolean;
  /**  Will preserve linebreaks using white-space: pre-line */
  linebreak?: boolean;
  textAlign?: CSSProperties["textAlign"];
  display?: CSSProperties["display"];
};

/**
 * The Paragraph component is used to display general text in the application.
 * The Paragraph component should take a `level` prop (1-4) to determine the class of paragraph.
 * The default level is 1.
 * Here are some examples of how to use the Paragraph component:
 * ```jsx
 * <Paragraph level={1}>
 *    This is a generic text
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={1} med>
 *    This is a bolded text
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={2}>
 *     this is the most common type of text.
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={2} med>
 *     This is a strong version of some general text.
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={3}>
 *     This is for footnotes or other small text.
 * </Paragraph>
 * ```
 * #### Levels
 * level | size | height | weight
 * ------|------|--------|--------
 * 1     | 16px | 24px   | 400
 * 2     | 13px | 20px   | 400
 * 3     | 12px | 16px   | 400
 *
 */
export function Paragraph({
  level = 1,
  as = "p",
  med = false,
  color = "gray-800", // TODO i think the default should be inherit or currentColor
  ellipsis = false,
  linebreak,
  fontSize,
  lineHeight,
  weight,
  children,
  marginTop,
  marginBottom,
  marginY,
  paddingTop,
  paddingBottom,
  paddingY,
  textAlign,
  display,
  ...props
}: ParagraphProps & HTMLParagraphProps) {
  if (level > 3 || level < 1) {
    console.error("Paragraph level must be between 1 and 3; defaulting to 1");
    level = 1;
  }
  const {
    fontSize: defaultFontSize,
    lineHeight: defaultLineHeight,
    weight: defaultWeight,
    color: defaultColor,
    marginTop: defaultMarginTop,
    marginBottom: defaultMarginBottom,
    marginY: defaultMarginY,
    paddingTop: defaultPaddingTop,
    paddingBottom: defaultPaddingBottom,
    paddingY: defaultPaddingY,
  } = paragraphStyles[level];

  return (
    <StyledParagraph
      as={as}
      size={fontSize ?? defaultFontSize}
      lineHeight={lineHeight || defaultLineHeight}
      weight={weight ?? (med ? 500 : defaultWeight)}
      color={COLORS[color] || color || COLORS[defaultColor]}
      marginTop={marginTop ?? marginY ?? defaultMarginTop ?? defaultMarginY}
      marginBottom={marginBottom ?? marginY ?? defaultMarginBottom ?? defaultMarginY}
      paddingTop={paddingTop ?? paddingY ?? defaultPaddingTop ?? defaultPaddingY}
      paddingBottom={paddingBottom ?? paddingY ?? defaultPaddingBottom ?? defaultPaddingY}
      ellipsis={ellipsis}
      $linebreak={linebreak}
      $textAlign={textAlign}
      $display={display}
      {...props}
    >
      {children}
    </StyledParagraph>
  );
}

export const StyledParagraph = styled.p`
  font-style: normal;
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => addPxIfNumber(props.size)};
  color: ${(props) => props.color};
  margin-top: ${(props) => addPxIfNumber(props.marginTop)};
  margin-bottom: ${(props) => addPxIfNumber(props.marginBottom)};
  padding-top: ${(props) => addPxIfNumber(props.paddingTop)};
  padding-bottom: ${(props) => addPxIfNumber(props.paddingBottom)};
  display: ${(props) => (props.ellipsis ? "block" : "inherit")};
  white-space: ${(props) => (props.ellipsis ? "nowrap" : props.$linebreak ? "pre-line" : "normal")};
  overflow: ${(props) => (props.ellipsis ? "hidden" : "auto")};
  text-overflow: ${(props) => (props.ellipsis ? "ellipsis" : "initial")};

  ${(p) => p.$textAlign && `text-align: ${p.$textAlign};`}
  ${(p) => p.$display && `display: ${p.$display};`}
  & > b {
    font-weight: 600;
  }
`;

export default Paragraph;
