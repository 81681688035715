import { useRouter } from "next/router";
import { useState, useEffect, createContext, useContext, useMemo, useCallback } from "react";
import { IN_APP_NOTIFICATION_FEATURE_MAP, IN_APP_NOTIFICATION_TYPES } from "../const";
import { getNotificationList } from "../utils/frontend/fetchFromApi";
import { useStore } from "./useStore";
import { PUBLIC_ROUTES } from "../const/appConfigs";

const APP = process.env.NEXT_PUBLIC_APP;

const NotificationsContext = createContext<any>([{}, () => {}]);

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function WithNotifications({ children }) {
  const [store] = useStore();
  const router = useRouter();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const isPublicRoute = useMemo(() => router.pathname in PUBLIC_ROUTES, [router.pathname]);

  // Enabled notification types / categories
  const enabledNotificationTypes = useMemo(() => {
    return Object.values(IN_APP_NOTIFICATION_TYPES).filter(
      (type) =>
        IN_APP_NOTIFICATION_FEATURE_MAP[type] == null ||
        store?.userInfo?.userConfig?.enabledFeatures.includes(IN_APP_NOTIFICATION_FEATURE_MAP[type])
    );
  }, [store?.userInfo?.userConfig?.enabledFeatures]);

  // Notification types to consider under all tab or total count
  const typesUnderAllKey = useMemo(() => {
    return Object.values(IN_APP_NOTIFICATION_TYPES)?.filter(
      (type) =>
        enabledNotificationTypes?.includes(type) &&
        type !== "all" &&
        type !== IN_APP_NOTIFICATION_TYPES.ARCHIVED
    );
  }, [enabledNotificationTypes]);

  const fetchNotifications = useCallback(
    async (type, limit) => {
      if (store.userInfo == null && isPublicRoute) {
        return;
      }
      let fetchedList = await getNotificationList(
        store?.userInfo?.userId,
        store?.userInfo?.authData,
        type,
        limit,
        null
      );

      return (fetchedList || []).length;
    },
    [isPublicRoute, store.userInfo]
  );

  const initializeNotifications = useCallback(async () => {
    if (!isPublicRoute) {
      const INIT_LIMIT = Number.MAX_VALUE;
      // Only count notifications under "All tab"
      let notificationCount = await Promise.all(
        Object.values(typesUnderAllKey).map(async (type) => {
          return await fetchNotifications(type, INIT_LIMIT);
        })
      );
      const notificationTotalCount = notificationCount.reduce((a, b) => a + b, 0);
      setNotificationCount(notificationTotalCount);
    }
  }, [fetchNotifications, isPublicRoute, typesUnderAllKey]);

  useEffect(() => {
    store?.userInfo && !isPublicRoute && typesUnderAllKey?.length && initializeNotifications();
  }, [initializeNotifications, isPublicRoute, store?.userInfo, typesUnderAllKey]);

  // Exposed states
  const state = {
    notificationCount,
    typesUnderAllKey,
    enabledNotificationTypes,
  };

  // Exposed reduces
  const reducers = {
    initializeNotifications,
  };

  return (
    <NotificationsContext.Provider value={[state, reducers]}>
      {children}
    </NotificationsContext.Provider>
  );
}
