import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { isEmpty } from "../../utils/common/array";
import { Box } from "./Box";
import { List } from "antd";
import { Comment } from "./Comment";
import { EmptySegmentV2 } from "./EmptySegment";

export function ListOfComment({
  commentList,
  loading = false,
  forDownload = false,
  skeletonCount = 3,
  marginBottom = 12,
  selectOptions = {
    isSelectCommentMode: false,
    commentOnSelect: () => {},
  },
}) {
  const { t } = useTranslation();
  const comments = useMemo(() => {
    return loading ? Array.from({ length: skeletonCount }, () => ({})) : commentList;
  }, [skeletonCount, commentList, loading]);
  return isEmpty(comments) && !loading ? (
    <Box width={"100%"} pb={30}>
      <EmptySegmentV2
        pluralItemName={t("components.ProjectDetailViewer.ProjectOverview.comments")}
      />
    </Box>
  ) : (
    <List
      style={{ marginBottom: marginBottom }}
      dataSource={comments}
      // Currently in the table, we can guarantee projectId + createdAt will be unique record
      rowKey={"createdAt"}
      renderItem={(item, itemIndex) => (
        <Comment
          key={item?.createdAt}
          comment={item}
          loading={loading}
          forDownload={forDownload}
          selectOptions={selectOptions}
          data-cy="comment"
        />
      )}
    />
  );
}
