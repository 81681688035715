import { Tag as AntdTag } from "antd";
import styled from "styled-components";

const StyledTag = styled(AntdTag)`
  &.ant-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 22px;
    padding: 1px 8px;
    margin: 0;
    gap: 10px;
    border: 0;
    border-radius: ${(props) => (props.rounded ? 11 : 2)}px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  &.ant-tag[type$="White"] {
    background: #fff;
  }

  &.ant-tag[type$="Cyan"] {
    background: #b2ebf2;
    color: #003e46;
  }

  &.ant-tag[type$="Yellow"] {
    background: var(--yellow-100);
    color: #5f5500;
  }

  &.ant-tag[type$="Red"] {
    background: var(--red-100);
    color: #aa0011;
  }

  &.ant-tag[type$="PopRed"] {
    background: var(--red-600);
    color: #ffffff;
  }

  &.ant-tag[type$="Green"] {
    background: var(--green-100);
    color: #006703;
  }

  &.ant-tag[type$="LightBlue"] {
    background: var(--blue-11);
    color: #005881;
  }

  &.ant-tag[type$="DarkGreen"] {
    background: var(--green-800);
    color: #ffffff;
  }

  &.ant-tag[type$="Grey"] {
    background: var(--gray-300);
    color: var(--gray-800);
  }

  &.ant-tag[type|="Med"] {
    height: 20px;
    border-radius: ${(props) => (props.rounded ? 10 : 0)}px;
  }

  &.ant-tag[type|="SM"] {
    height: 16px;
    border-radius: ${(props) => (props.rounded ? 8 : 0)}px;
  }

  &.ant-tag[type="icon"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 2px;
    gap: 2px;
    height: 20px;
    background-color: var(--tw-white);
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: var(--gray-800);

    span:last-child {
      margin: 0;
    }
  }
`;

/**
 * @param {object} props
 * @param {"Med-White" | "Med-DarkGreen" | "Med-Cyan" | "Med-Yellow" | "Med-Grey" | "Med-Red" | "Med-PopRed" | "Med-Green"| "Med-LightBlue" | "SM-White" | "SM-DarkGreen" | "SM-Cyan" | "SM-Yellow" | "SM-Red" | "SM-PopRed" | "SM-Green"| "SM-LightBlue" |"SM-Grey" | "icon"} props.type - Decide the size and color of the tag
 * @param {string | ReactNode} props.children - The content inside the tag
 * @param {ReactNode} [props.icon] - Set the icon of tag
 * @param {boolean} [props.closable] - Whether the Tag can be closed
 * @param {(e) => void} [props.onClose] - Callback executed when tag is closed
 * @param {boolean} [props.rounded=false] - whether round the edges
 * @param {object} [props.style] - Style prop
 * @example
 * ```jsx
 *  <Tag type="Med-Green">Tag</Tag> // a medium green tag
 *  <Tag type="SM-Cyan">any content you want</Tag> // a small cyan tag
 *  <Tag icon={<EthereumIcon />} type="icon">Tag</Tag> // a tag with an icon
 *  <Tag closable={true} onClose={log} type="Med-Red">Tag</Tag> // a medium red closable tag
 * ```
 */

export function Tag(props) {
  let {
    type,
    children,
    icon = null,
    closable = false,
    onClose = () => {},
    rounded = false,
    style = {},
    ...restProps
  } = props;
  if (closable) {
    type = type?.split("-");
    type?.splice(1, 0, "Closable");
    type = type?.join("-");
  }
  return (
    <StyledTag
      icon={icon}
      type={type}
      closable={closable}
      onClose={onClose}
      rounded={rounded}
      style={style}
      {...restProps}
    >
      {children}
    </StyledTag>
  );
}
