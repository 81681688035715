import { Row, Col, Tooltip } from "antd";
import styled from "styled-components";
import { SwitchComponent } from "./SwitchComponent";
import { COLORS } from "../../const";
import { Paragraph } from "./Text";

const StyledRow = styled(Row)`
  margin-bottom: ${(props) => (props.mb ? props.mb : "8px")};
  flex-wrap: nowrap;
`;

const StyledRowFlexibleHeight = styled(Row)`
  margin-bottom: ${(props) => (props.mb ? props.mb : "8px")};
`;

const TruncatedParagraph = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldLabel = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS["gray-800"]};
`;

/**
 * A form/data display row containing a label and the data/input. Switches between a static data display component
 * and an edit component (e.g. input field) when isEditing is true.
 * @param {Boolean} isEditing
 * @param {String} label The field label - will be truncated with ellipses if no space
 * @param {React.ReactNode} staticComponent The component to show when not in edit mode
 * @param {React.ReactNode} editComponent The component to show when editing
 * @param {Boolean} isWide
 * @param {Boolean} isTall If the field is more than one line, this will align the label with the top line
 * @param {String} marginBottom Custom margin-bottom; defaults to 8px
 * @param {any} [componentStyle] Style for the component field (the right side)
 */
export function EditableFieldRow({
  isEditing,
  label,
  labelStyle = {},
  componentStyle = {},
  staticComponent,
  editComponent,
  isWide = false,
  tall = false,
  marginBottom,
  alignTop = false,
  customLabelWidth = 0,
  showTooltip = false,
  tooltipLabel = label,
}) {
  const labelComponent = (
    <Tooltip
      title={showTooltip ? tooltipLabel : ""}
      getPopupContainer={(node) => node.closest(".popup-container") || node.parentElement}
      placement="topLeft"
    >
      <TruncatedParagraph level={2} style={labelStyle}>
        {label}
      </TruncatedParagraph>
    </Tooltip>
  );

  return (
    <>
      {isWide ? (
        <StyledRow align={alignTop ? "top" : "middle"} justify="start" mb={marginBottom}>
          <Col flex="200px">{labelComponent}</Col>
          <Col flex="1 0 200px" style={componentStyle}>
            <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
          </Col>
        </StyledRow>
      ) : (
        <StyledRow align={alignTop ? "top" : "middle"} mb={marginBottom}>
          <Col
            span={customLabelWidth ? undefined : 7}
            style={{
              width: customLabelWidth ?? undefined,
              paddingRight: "16px",
              alignSelf: tall ? "start" : "center",
            }}
          >
            {labelComponent}
          </Col>
          <Col style={{ flex: 1, ...componentStyle }}>
            <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
          </Col>
        </StyledRow>
      )}
    </>
  );
}

export function EditableFieldRowFlexibleHeight({
  isEditing,
  label,
  staticComponent,
  editComponent,
  marginBottom,
}) {
  return (
    <StyledRowFlexibleHeight align="middle" mb={marginBottom}>
      <Col span={7}>
        <TruncatedParagraph level={2}>{label}</TruncatedParagraph>
      </Col>
      <Col span={17}>
        <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
      </Col>
    </StyledRowFlexibleHeight>
  );
}

export function StaticFieldRow({
  label,
  labelStyle = {},
  staticComponent,
  marginBottom = undefined,
  tall = false,
  showTooltip = false,
  tooltipLabel = label,
  tooltipPlacement = undefined,
  customLabelWidth = 0,
  verticalAlign = "middle",
}) {
  return (
    <StyledRow align={verticalAlign} mb={marginBottom}>
      <Tooltip
        title={showTooltip ? tooltipLabel : ""}
        getPopupContainer={(node) => node.closest(".popup-container") || node.parentElement}
        placement={tooltipPlacement}
      >
        <Col
          span={customLabelWidth ? undefined : 7}
          style={{
            width: customLabelWidth ?? undefined,
            paddingRight: "16px",
            alignSelf: tall ? "start" : "center",
          }}
        >
          <TruncatedParagraph level={2} style={labelStyle}>
            {label}
          </TruncatedParagraph>
        </Col>
      </Tooltip>
      <Col style={{ flex: 1, minWidth: 0 }}>{staticComponent}</Col>
    </StyledRow>
  );
}
