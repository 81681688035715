// eslint-disable-next-line import/named
import { v4 as uuid } from "uuid";
import { mutate } from "swr";
import { localStore } from "./localStore";
import {
  DEFAULT_TIMEOUT_MS,
  FETCH_ERROR_ENUM,
  RESPONSE_ERROR_ENUM,
  SELECTED_TENANT_ID,
} from "../../const";
import { AppConfig } from "../../const/appConfigs";
import { Logger } from "./logger/browser-logger";
import { API_BROWSER_LOG_KEYS, LogLevels } from "./logger/config";
import { getDeviceId } from "./device";
import { safeParse } from "./utils";

/* axios POST request not working with nextjs api somehow.
import axios from "axios";

export async function authenticateUser2(platform, authData) {
  return await axios.post("/api/authenticate", {
    platform: platform,
    data: authData,
  });
}
*/
const PROXY_URL = process.env.NEXT_PUBLIC_PROXY_URL; // e.g. https://certik-client-portal-proxy-310dov99c-certik.vercel.app/api/proxy
const PROXY_APP_ID = process.env.NEXT_PUBLIC_PROXY_APP_ID;
const PROXY_API_KEY = process.env.NEXT_PUBLIC_PROXY_API_KEY;
// TODO: Set in env
const MAX_502_503_RETRY_COUNT = 3;

const proxyAuthHeader = {
  "x-certik-skyharbor-app-id": PROXY_APP_ID,
  "x-certik-skyharbor-api-key": PROXY_API_KEY,
};

const BYPASS_PUBLICK_PAGE_PATHS = [
  "/risk-manager/transaction-flow-analyzer",
  "/risk-manager/search",
];

const getCurrentTimeStamp = () => {
  return Date.now();
};

// Clears local storage and forces logout
function on401Status() {
  void mutate((_key) => true, undefined, { revalidate: false }); // clear SWR cache
  localStorage?.clear();
  window?.location?.replace("/login");
}

function on403Status() {
  // TODO: need to create a 403 page instead of using 404 page
  window?.location?.replace("/404");
}

function getAbortSignal(timeoutMs = DEFAULT_TIMEOUT_MS) {
  const abortController = new AbortController();
  const timeoutId = setTimeout(() => {
    abortController.abort();
  }, timeoutMs);

  return { signal: abortController.signal, timeoutId };
}

export async function sendPostRequest(
  url,
  bodyData,
  otherAttributes = {},
  fallbackOptions = {
    401: { isRedirect: true, func: undefined },
    403: { isRedirect: false, func: undefined },
    404: { isRedirect: false, func: undefined },
  },
  textResponse = false,
  timeoutMilliseconds = DEFAULT_TIMEOUT_MS,
  retryCounter = 0
) {
  bodyData.tenantId = bodyData.tenantId ?? localStore?.get(SELECTED_TENANT_ID);
  bodyData.serviceName = bodyData.serviceName ?? AppConfig.serviceName;
  bodyData.domain = bodyData.domain ?? AppConfig.domain;

  const logTraceId = uuid();
  const abortSignal = getAbortSignal(timeoutMilliseconds);

  const isByPassAuth = BYPASS_PUBLICK_PAGE_PATHS.some((path) =>
    window.location.href.includes(path)
  );

  const reqBody = {
    signal: abortSignal.signal,
    method: "POST",
    headers: {
      "x-trace-id": logTraceId,
    },
    // frontend log traceId sent to backend
    body: JSON.stringify({
      ...bodyData,
      isMobile: false,
      deviceId: getDeviceId(),
      isPublicPage: isByPassAuth,
    }),
    ...otherAttributes,
  };

  let resp;
  let respText = "";
  let latency = 0;
  const startTime = getCurrentTimeStamp();

  try {
    // Send one piece of log before every api gets fired
    Logger.log(
      API_BROWSER_LOG_KEYS.API_REQUEST_FIRING,
      { url, reqBody },
      LogLevels.INFO,
      logTraceId
    );

    resp = await fetch(url, reqBody);

    respText = await resp.text();

    latency = getCurrentTimeStamp() - startTime;

    // Send one piece of log for every api fired
    Logger.log(
      API_BROWSER_LOG_KEYS.API_REQUEST_FIRED,
      {
        url,
        latency: latency,
        status: resp.status,
        timeout: timeoutMilliseconds,
        reqBody,
        resp,
      },
      LogLevels.INFO,
      logTraceId
    );

    if (textResponse) {
      return {
        status: resp.status,
        text: respText,
      };
    }
  } catch (err) {
    console.warn("fetch error!", err);
    if (err?.name === FETCH_ERROR_ENUM.ABORT_ERROR) {
      Logger.log(
        API_BROWSER_LOG_KEYS.API_REQUEST_TIMEOUT,
        {
          url,
          reqBody,
          latency: timeoutMilliseconds,
          timeout: timeoutMilliseconds,
          errorType: FETCH_ERROR_ENUM.ABORT_ERROR,
        },
        Logger.levels.ERROR,
        logTraceId
      );
      return {
        error: RESPONSE_ERROR_ENUM.REQUEST_TIMEOUT,
        status: 408,
        text: err.message,
      };
    }
  } finally {
    if (abortSignal.timeoutId) {
      clearTimeout(abortSignal.timeoutId);
    }
  }

  try {
    const respJSON = safeParse(respText);
    const commonCtx = {
      url,
      latency: latency,
      status: resp.status,
      reqBody,
      resp,
      respJSON,
    };

    if (resp.status !== 200) {
      Logger.log(
        API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_NOT_2XX,
        { ...commonCtx },
        LogLevels.WARN,
        logTraceId
      );

      if (resp.status === 401) {
        if (fallbackOptions && fallbackOptions["401"]?.func != null) {
          Logger.log(
            API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_401_FALLBACK,
            { ...commonCtx },
            LogLevels.WARN,
            logTraceId
          );

          // Any custom, higher level logic will be prioritized if its implemented (not undefined or null).
          fallbackOptions["401"].func();
        }
        if (fallbackOptions && fallbackOptions["401"]?.isRedirect) {
          // Regardless of scenario, if its 401, we will clear store and force user to logout.
          on401Status();
        }
        return respJSON;
      } else if (resp.status === 403) {
        if (fallbackOptions && fallbackOptions["403"]?.func != null) {
          Logger.log(
            API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_403_FALLBACK,
            { ...commonCtx },
            LogLevels.WARN,
            logTraceId
          );
          fallbackOptions["403"].func();
        }
        if (fallbackOptions && fallbackOptions["403"]?.isRedirect) {
          on403Status();
        }
        return respJSON;
        //  Window checking here to avoid UT error
      } else if (resp.status === 500 && typeof window !== "undefined") {
        // The if-condition below is to check the account's version mismatch
        if (
          respJSON?.errMsg &&
          (respJSON.errMsg.includes("Version miss match") ||
            respJSON.errMsg.includes("The conditional request failed"))
        ) {
          // We import message component only in browser environment
          const { message } = require("../../components/SharedComponents"); // eslint-disable-line
          message.warning("Session expired, please refresh the page.", 5);
          Logger.log(
            API_BROWSER_LOG_KEYS.API_REQUEST_SESSION_EXPIRED,
            { ...commonCtx },
            LogLevels.WARN,
            logTraceId
          );
        }
        return respJSON;
      } else if (resp.status === 502 || resp.status === 503) {
        if (retryCounter >= MAX_502_503_RETRY_COUNT) {
          // Hit max tries
          Logger.log(
            API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_502_OR_503_LOG,
            { retryCounter, maxRetries: MAX_502_503_RETRY_COUNT, ...commonCtx },
            LogLevels.ERROR,
            logTraceId
          );
        } else {
          // Log retry attempt
          Logger.log(
            API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_502_OR_503_LOG,
            { retryCounter, maxRetries: MAX_502_503_RETRY_COUNT, ...commonCtx },
            LogLevels.INFO,
            logTraceId
          );
          // Retry 1 more time after 1 second while incrementing count
          const newRetryCounter = retryCounter + 1;
          return await sendPostRequest(
            url,
            bodyData,
            otherAttributes,
            fallbackOptions,
            textResponse,
            timeoutMilliseconds,
            newRetryCounter
          );
        }
      } else return respJSON;
    } else {
      return respJSON;
    }
  } catch (e) {
    // TODO debug
    Logger.log(
      API_BROWSER_LOG_KEYS.API_REQUEST_ERROR_PARSE,
      {
        url,
        latency: latency,
        status: resp.status,
        reqBody,
        respText,
      },
      LogLevels.ERROR,
      logTraceId
    );
    return e;
  }
}

export async function sendPostRequestFromServer(
  url,
  bodyData,
  otherAttributes = {},
  fallbackFunc = () => {},
  proxy
) {
  const authHeader = proxy ? proxyAuthHeader : {};
  let domain = new URL(url);
  domain = domain.hostname;
  const finalUrl = proxy ? `${PROXY_URL}?domain=${domain}&url=${encodeURIComponent(url)}` : url;
  console.log(finalUrl);
  const resp = await fetch(finalUrl, {
    method: "POST",
    body: JSON.stringify(bodyData),
    ...otherAttributes,
    headers: {
      ...authHeader,
    },
  });
  const respText = await resp.text();
  const respJSON = JSON.parse(respText);
  if (resp.status !== 200) {
    if (resp.status === 401) {
      fallbackFunc(respJSON);
      return respJSON;
    } else throw respJSON;
  } else return respJSON;
}

export async function sendGetRequest(url, params = {}, attributes = {}) {
  const urlWithParams =
    url +
    "?" +
    Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
  const resp = await fetch(urlWithParams, {
    method: "GET",
    ...attributes,
  });
  const respText = await resp.text();
  const respJSON = JSON.parse(respText);
  if (resp.status !== 200) throw respJSON;
  else return respJSON;
}
