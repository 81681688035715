export function getRegexGroup(expr, value, keys) {
  const matchMap = keys.reduce((acc, curr) => ({ ...acc, [curr]: undefined }), {});
  const matches = expr.exec(value);
  if (matches != null) {
    Object.keys(matches.groups).forEach((groupKey) => {
      if (Object.hasOwnProperty.call(matchMap, groupKey)) {
        matchMap[groupKey] = matches.groups[groupKey];
      }
    });
  }
  return matchMap;
}
