export const PUBLIC_CDN_URL = process.env.NEXT_PUBLIC_PUBLIC_CDN_URL;

export const getAuditEmailHeaderImageUrls = (type) => {
  switch (type) {
    case "createV2":
      return `${PUBLIC_CDN_URL}/create-email-header-v2.png`;
    case "create":
      return `${PUBLIC_CDN_URL}/email/create-email-header.png`;
    case "update":
      return `${PUBLIC_CDN_URL}/email/emailHeader_project_update.jpg`;
    case "delete":
      return `${PUBLIC_CDN_URL}/email/delete-email-header.png`;
    case "comment":
      return `${PUBLIC_CDN_URL}/email/comment-email-header.png`;
  }
};
