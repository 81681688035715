import { DateTime } from "luxon";
import sha256 from "crypto-js/sha256";
import { localStore } from "./localStore";
import { AVATAR_COLOR_LIST } from "../../const/colors";
import {
  ADMIN_PROJECT_ADVANCED_SEARCH,
  AUDIT_ADVANCED_SEARCH,
  AUDIT_PROGRESS_PERCENTAGE,
  AUTH_DATA,
  BUILD_VERSION_CONFIG,
  FINDING_REPORTS_TYPE,
  HASHED_PASSWORD,
  SELECTED_TENANT_ID,
  USER_ID,
} from "../../const";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { chunk } from "../common/utils";
import { filterObject } from "../common/object";
import Delta from "quill-delta";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AVAILABLE_LOCALES, NAVIGATOR_LANGUAGE_MAP } from "../../const/locale";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import koKR from "antd/lib/locale/ko_KR";
import kebabCase from "lodash/kebabCase";
import { SUPPORT_EMAIL_ADDRESS } from "../../const/certik";

const CLIENT_SUPPORT_EMAIL_ADDRESS = SUPPORT_EMAIL_ADDRESS;

/**
 * translates css class names into modular css class names
 * @param {Object} styles typically the imported css styles
 * @param {string[]} moduleClassNames array of module class names to be mapped
 * @param {string[]} globalClassNames array of global class names
 * @returns {string} string of concatenated class names
 */
export const cls = (styles = {}, moduleClassNames = [], globalClassNames = []) => {
  try {
    return moduleClassNames
      .map((item) => styles[item])
      .concat(globalClassNames)
      .join(" ");
  } catch (err) {
    console.error(err.message);
    return "";
  }
};

/**
 * checks if the Telegram ID is valid
 * Ref: https://telegram.org/faq#q-what-can-i-use-as-my-username
 * @param {string} name the given telegram ID to be examined, "@", "_" are preserved chars in telegram ID
 * @returns {boolean} the result
 */
export const validateTelegramId = (name) => {
  const re = /^[a-zA-Z0-9'@_-]+$/;
  return re.test(name);
};

/**
 * checks if the given first name is valid
 * @param {string} name the given first name to be examined
 * @returns {boolean} the result
 */
export const validateFirstNameRegex = (name) => {
  const re = /^[a-zA-Z'-]+$/;
  return re.test(name);
};

/**
 * checks if the given last name is valid
 * @param {string} name the given last name to be examined
 * @returns {boolean} the result
 */
export const validateLastNameRegex = (name) => {
  const re = /^[a-zA-Z'-]+$/;
  return re.test(name);
};

/**
 * checks if the given department is valid (only allow a-z, A-Z, space)
 * @param {string} name the given department to be examined
 * @returns {boolean} the result
 */
export const validateDepartmentRegex = (name) => {
  const re = /^[a-zA-Z ]+$/;
  return re.test(name);
};

/**
 * checks if the given job title is valid (only allow a-z, A-Z, space, period)
 * @param {string} name the given job title to be examined
 * @returns {boolean} the result
 */
export const validateJobTitleRegex = (name) => {
  const re = /^[a-zA-Z. ]+$/;
  return re.test(name);
};

/**
 * purifies file name by replacing each special character (!/\?%*:|"<>) with a dash (-)
 * @param {string} fileName the given file name to be purified
 * @returns {string} the purified file name
 */
export const purifyFileName = (fileName) => {
  try {
    return fileName.replace(/[/\\?%*:|"<>]/g, "-");
  } catch (err) {
    console.error(err.message);
    return "";
  }
};

/**
 * Parse a date to [... ago] format
 * @param {number} date the timestamp property of the object
 * @param {"long" | "short" | "narrow"} style style of the formatted time
 * @returns {string} the formatted time
 */
export const formatTimeAgo = (date, style = "long") => {
  const units = ["year", "month", "week", "day", "hour", "minute", "second"];
  let dateTime = DateTime.fromMillis(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";
  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
    style: style,
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

/**
 * calculates SHA-256 of the given string
 * @param {string} inputStr the given string
 * @returns {string} string of the SHA-256 result
 */
export const onewayHash = (inputStr) => {
  return sha256(inputStr).toString();
};

export const stdLogin = (userID, password, accessToken, idToken, func = () => {}) => {
  localStore.set(USER_ID, userID);
  localStore.set(HASHED_PASSWORD, onewayHash(password));
  localStore.set(
    AUTH_DATA,
    JSON.stringify({
      email: userID,
      accessToken,
      idToken,
    })
  );
  func();
};

export const checkPassword = (password) => {
  return localStore.get(HASHED_PASSWORD) === onewayHash(password);
};

export const stdLogout = () => {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve();
      localStore.set(USER_ID, "");
      localStore.set(HASHED_PASSWORD, "");
      localStore.set(AUTH_DATA, "");
      localStore.set(SELECTED_TENANT_ID, "");
      localStore.set(AUDIT_ADVANCED_SEARCH, "");
      localStore.set(ADMIN_PROJECT_ADVANCED_SEARCH, "");
      sessionStorage.clear();
    }, 0);
  });
};

export const richTxtToPlainTxt = (richTxt) => {
  let html = richTxt.slice();
  html = html.replace(/\n/g, "");
  html = html.replace(/\t/g, "");

  //keep html brakes and tabs
  html = html.replace(/<\/td>/g, "\t");
  html = html.replace(/<\/table>/g, "\n");
  html = html.replace(/<\/tr>/g, "\n");
  html = html.replace(/<\/p>/g, "\n");
  html = html.replace(/<\/ol>/g, "\n");
  html = html.replace(/<\/ul>/g, "\n");
  html = html.replace(/<\/li>/g, "\n");
  html = html.replace(/<\/div>/g, "\n");
  html = html.replace(/<\/h>/g, "\n");
  html = html.replace(/<br>/g, "\n");
  html = html.replace(/<br( )*\/>/g, "\n");

  //parse html into text
  var dom = new DOMParser().parseFromString("<!doctype html><body>" + html, "text/html");
  return dom.body.textContent;
};

export function uploadedReportWithSameName(currentReportList, newReport) {
  return currentReportList.some((reportItem) => {
    console.log(reportItem[0].name, newReport.name);
    return reportItem[0].name === newReport.name;
  });
}

export function uploadedFileWithSameName(currentFileList, newFile) {
  return currentFileList?.some((file) => {
    return file.originFileObj.name === newFile.name;
  });
}

/**
 * adds a suffix to a filename
 * @param {string} filename the filename
 * @param {string} suffix the suffix to add
 * @param {string} seperator the seperator between filename and suffix (default "-")
 * @returns {string} the filename with suffix added
 * @example
 * ```js
 * addSuffixToFilename("some.pdf", "thing") // "some-thing.pdf"
 * addSuffixToFilename("some.pdf", "thing", "+") // "some+thing.pdf"
 * ```
 */
function addSuffixToFilename(filename, suffix, seperator = "-") {
  const parts = filename.split("."); //?
  if (parts.length > 1) {
    const extension = parts.pop();
    return parts.join(".") + seperator + suffix + "." + extension;
  }
  return parts.join(".") + seperator + suffix;
}

// TMP solution to fullfill s3 naming requiement

const removeSpecialCharacters = (x) => x.replace(/[^a-zA-Z0-9.-]/g, "");

export function formUniqueFiles(fileList) {
  return fileList.map((file) => {
    const suffix = uuid();
    if (!file.originFileObj) {
      return {
        uniqueFileName: file.name,
        file: file,
      };
    }

    return {
      uniqueFileName: addSuffixToFilename(removeSpecialCharacters(file.originFileObj.name), suffix),
      file: file,
    };
  });
}

/**
 * util function to upload reports to aws s3
 * @param {Object[]} fileList list of objects in the form {uniqueFileName: <file name>, file: <file object>}
 * @param {Object[]} urlList list of objects in the form {presignedUrl: <s3 presigned url>, cdnPrefix: <cdn url for file type>}
 * @param {string} userId
 * @returns {Object[]} list of objects containing info about uploaded reports
 */
export const uploadReports = async (fileList, urlList, userId) => {
  // upload list of files objects
  // return: list of auditReportList info with files that are successfully uploaded
  if (
    userId == null ||
    fileList.length === 0 ||
    urlList.length === 0 ||
    fileList.length !== urlList.length
  ) {
    return null;
  }

  const uploadedAuditReportList = await Promise.all(
    fileList.map(async (item, index) => {
      const file = item.file;
      const uploadTime = new Date().getTime();
      // we don't grab the pdf from accelerator, so we need to short circuit here
      if (!file.originFileObj) {
        return {
          ...file,
          showSignOff: !!file.showSignOff,
          uploadTime: uploadTime,
          uploaderId: userId,
          originalFileName: file.fileName,
        };
      }

      const fileName = item.uniqueFileName;

      try {
        const { presignedUrl, cdnPrefix } = urlList[index];
        if (presignedUrl == null) return null;
        console.log("presigned url: ", presignedUrl);

        // upload file through presigned url
        const resp = await axios.put(presignedUrl, file.originFileObj, {
          headers: {
            "Content-Type": "application/pdf",
            "Content-Disposition": "inline",
            "Access-Control-Allow-Origin": "*",
          },
        });
        if (!resp || resp.status !== 200) return null;
        console.log("upload resp: ", resp);
        return {
          originalFileName: file.originFileObj.name,
          fileName: fileName,
          fileUrl: cdnPrefix + "/" + fileName,
          uploadTime: uploadTime,
          uploaderId: userId,
          showSignOff: file.showSignOff || false,
        };
      } catch (err) {
        console.log(err);
        return null;
      }
    })
  );

  return uploadedAuditReportList.filter((item) => item != null);
};

/**
 * util function to delete file from aws s3
 * @param {Object[]} urlList list of objects in the form {presignedUrl: <s3 presigned url>, cdnPrefix: <cdn url for file type>}
 * @param {string} userId
 * @returns {boolean} true if file deletion was successful, false otherwise
 */
export const deleteReports = async (urlList, userId) => {
  if (userId == null) {
    return false;
  }
  if (urlList.length === 0) {
    return true;
  }
  console.log("urlList: ", urlList);

  await Promise.all(
    urlList.map(async (urlItem) => {
      try {
        // urlItem = {presignedUrl: <s3 presigned url>, cdnUrl: <cdnprefix for file type>}
        const { presignedUrl } = urlItem;
        if (presignedUrl == null) return null;
        const resp = await axios.delete(presignedUrl);
        console.log("delete resp:", resp);
        if (!resp) {
          throw new Error("error deleting file");
        }
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    })
  );
  return true;
};

/**
 * util function to upload files to aws s3
 * @param {Object[]} fileList list of objects in the form {uniqueFileName: <file name>, file: <file object>}
 * @param {Object[]} urlList list of objects in the form {presignedUrl: <s3 presigned url>, cdnPrefix: <cdn url for file type>}
 * @param {string} userId
 * @returns {Object[]} list of objects containing info about uploaded files
 */
// Ro upload files in different type: .pdf, .txt, .png ....
export const uploadFiles = async (fileList, urlList, userId) => {
  if (
    userId == null ||
    fileList.length === 0 ||
    urlList.length === 0 ||
    fileList.length !== urlList.length
  ) {
    return null;
  }

  console.log("In uploadFiles");

  const uploadedAuditReportList = await Promise.all(
    fileList.map(async (item, index) => {
      const file = item.file;
      const uploadTime = new Date().getTime();
      const fileName = item.uniqueFileName;
      try {
        const { presignedUrl, cdnPrefix } = urlList[index];
        if (presignedUrl == null) return null;
        // upload file through presigned url
        const resp = await axios.put(presignedUrl, file.originFileObj, {
          headers: {
            "Content-Type": file.type,
            "Content-Disposition": "inline",
            "Access-Control-Allow-Origin": "*",
          },
        });
        if (!resp || resp.status !== 200) return null;
        console.log("upload resp: ", resp);
        return {
          originalFileName: file.originFileObj.name,
          fileName: fileName,
          fileUrl: cdnPrefix + "/" + fileName,
          uploadTime: uploadTime,
          uploaderId: userId,
        };
      } catch (err) {
        console.log(err);
        return null;
      }
    })
  );
  return uploadedAuditReportList.filter((item) => item != null);
};

/**
 * `countUnique` counts all of the unique instances of a value in an array
 * @param {any[]} array array of any type
 * @param {(item: any, index: number, array: any[]) => number|string|symbol} accessor function to access the desired value to count
 * @returns {Object}
 */
export function countUnique(array, accessor) {
  const bag = {};
  (array || []).forEach((item, index) => {
    const key = accessor ? accessor(item, index, array) : item;
    if (["number", "string", "symbol"].includes(typeof key)) {
      if (!bag[key]) {
        bag[key] = 0;
      }
      bag[key]++;
    }
  });
  return bag;
}

/**
 * `countUnique` counts all of the unique instances of a value in an array
 * @param {any[]} array array of any type
 * @param {(item: any, index: number, array: any[]) => number|string|symbol} accessor function to access the desired value to count
 * @returns {Object}
 */
export function countUniqueInNestedArray(array, accessor, countEmpty, emptyKeyName) {
  const bag = {};
  (array || []).forEach((item, index) => {
    const keyArray = accessor ? accessor(item, index, array) : item;
    if (Array.isArray(keyArray) && (keyArray || []).length > 0) {
      for (let itemKey of keyArray) {
        if (!bag[itemKey]) {
          bag[itemKey] = 0;
        }
        bag[itemKey]++;
      }
    } else {
      console.log(countEmpty);
      if (countEmpty) {
        if (!bag[emptyKeyName]) {
          bag[emptyKeyName] = 0;
        }
        bag[emptyKeyName]++;
      }
    }
  });
  console.log(bag);
  return bag;
}

/**
 * Pick properties from an object
 * @param {string[]} props
 * @param {Object} obj
 * @returns {Object}
 * @example
 * ```js
 * pick(["dogs"], { cats: 2, dogs: 5 }) // { dogs: 5 }
 * ```
 */
export function pick(props, obj) {
  if (obj == null) {
    return {};
  }
  const keys = Object.keys(obj);
  return keys
    .filter((key) => (props || []).includes(key))
    .reduce((acc, prop) => ({ ...acc, [prop]: obj[prop] }), {});
}

/**
 * Traverse up the DOM until a condition is met
 * @param {HTMLElement|Node} node start node
 * @param {(node: HTMLElement|Node) => boolean} condition traverse until condition is met
 * @returns {HTMLElement|Node|null} node that passed met the condition
 */
export function traverseUpUntil(node, condition) {
  let child = node;
  let parent = child.parentElement;
  if (parent == null) {
    return child;
  }
  if (condition(node)) {
    return node;
  }
  while (parent && !condition(parent)) {
    child = parent;
    parent = child.parentElement;
  }
  return condition(parent) ? parent : null;
}

/**
 * this helper function formats the contract address URL to be
 * displayed in a table.
 * @param {string} contractAddressUrl
 * @param {number} visible number (between 4 and 20) of visible characters at the beggining and end
 * @returns {string}
 */
export function formatContractAddressUrl(contractAddressUrl, visible = 4) {
  // example address Url https://etherscan.io/address/0x773616e4d11a78f511299002da57a0a94577f1f4
  // example output 0x7736…f1f4
  const addressPath = contractAddressUrl.split("/").slice(-1)[0];
  const addressMatch = addressPath.match(/0x[a-fA-F0-9]+/gm);
  const address = addressMatch ? addressMatch[0] : addressPath;
  // clamps the visible value between 4 and 20;
  visible = Math.min(Math.max(visible, 3), 20);
  if (visible === 20) {
    return address; // if visible is 20, theres no need for the '…'
  } else {
    return `${address.slice(0, visible)}…${address.slice(-visible)}`;
  }
}

/**
 * this helper function checks whether user has authorization
 * to view certain page
 * @param {string} feature  the page name
 * @returns {boolean|null} returns null if store not fully loaded
 */
export function checkIfFeatureEnabled(store, feature) {
  if (!store?.userInfo?.userConfig) {
    return null;
  }
  // Check enableFeature contains feature
  if (!(store?.userInfo?.userConfig.enabledFeatures || []).includes(feature)) {
    return false;
  }
  return true;
}

/**
 * Validates the syntax of a contract address url
 * @param {string} contractAddressUrl contract address url
 * @returns {boolean}
 */
export function validateContractAddressUrl(contractAddressUrl) {
  if (contractAddressUrl === "") {
    throw new Error("Contract cannot be empty");
  }
  try {
    let url;
    try {
      url = new URL(contractAddressUrl);
    } catch (error) {
      throw new Error("Invalid URL");
    }
    if (!["etherscan.io", "bscscan.com"].includes(url.host)) {
      throw new Error("Host not supported");
    }
    if (!url.pathname.match(/address\/0x[a-f0-9]{40}/i)) {
      throw new Error("Invalid contract address");
    }
  } catch (error) {
    throw new Error(error.message || "Invalid URL");
  }
  return true;
}

/**
 * formats a currency value
 * @param {number} value number to format
 * @returns {string} formatted value
 */
export function formatCurrency(value, commas = false) {
  if (value == null) {
    throw new Error("formatCurrency received nullish value");
  }
  if (Number.isNaN(value)) {
    throw new Error("formatCurrency received NaN value");
  }
  const negative = value < 0;
  let formatted = Math.abs(value).toFixed(2);
  if (commas) {
    const [before, after] = formatted.split(".");
    const formattedBefore = before.split("").reverse();

    const withCommas = chunk(3)(formattedBefore)
      .map((x) => x.join(""))
      .join(",")
      .split("")
      .reverse()
      .join("");
    formatted = `${withCommas}.${after}`;
  }
  if (negative) {
    return `-$${formatted}`;
  }
  return `$${formatted}`;
}

/**
 * formats a number to K,M,B,T
 * @param {number} value number to format
 */
export function formatTruncated(value, precision = 2, keepTrailingZeros = false) {
  value = +value;
  if (Number.isNaN(value)) {
    throw new Error("invalid number for `value` passed in to `formatTruncated`");
  }
  const negetive = value < 0;
  value = Math.abs(value);
  let formatted = `${value.toFixed(precision)}`;
  let suffix = "";
  if (value >= 1e3 && value < 1e6) {
    formatted = (value / 1e3).toFixed(precision);
    suffix = "K";
  } else if (value >= 1e6 && value < 1e9) {
    formatted = (value / 1e6).toFixed(precision);
    suffix = "M";
  } else if (value >= 1e9 && value < 1e12) {
    formatted = (value / 1e9).toFixed(precision);
    suffix = "B";
  } else if (value >= 1e12 && value < 1e15) {
    formatted = (value / 1e12).toFixed(precision);
    suffix = "T";
  }
  if (!keepTrailingZeros) {
    const [before, after] = formatted.split(".");
    const trimmedAfter = after?.replace(/0+$/gm, "");
    formatted = before;
    if (trimmedAfter?.length > 0) {
      formatted += "." + trimmedAfter;
    }
  }
  if (negetive) {
    formatted = "-" + formatted;
  }
  formatted += suffix;
  return formatted;
}

/**
 * Formats a number to locale string (e.g. "1,625,999"). If the number is greater than or equal
 * to the minimum, it truncates it with an abbreviation (e.g. "1.63M").
 * @param {number} number
 * @param {number} minimum
 * @param {number} precision The fixed point precision for the truncated number
 * @returns {string} Number as a string, truncated w/ suffix or with locale string format
 */
export function numberToLocaleStringOrTruncate(number = 0, minimum, precision = 2) {
  return number < minimum ? number.toLocaleString() : formatTruncated(number, precision);
}

export function isTruncateString(string, visible = 10) {
  visible = Math.max(visible, 1);
  if (string?.length > visible * 2 + 4) {
    return true;
  }
  return false;
}

export function truncateString(string, visible = 10) {
  visible = Math.max(visible, 1);
  if (string?.length > visible * 2 + 4) {
    return string.slice(0, visible) + "...." + string.slice(-visible);
  }
  return string;
}

export function truncateAndShortenStr(str, visiblePrefix = 10, visibleSuffix = 10) {
  if (str?.length > visiblePrefix + visibleSuffix + 4) {
    if (visibleSuffix === 0) return str.slice(0, visiblePrefix) + "...";
    else return str.slice(0, visiblePrefix) + "..." + str.slice(-visibleSuffix);
  } else return str;
}

/**
 *
 * @param {string | number} labelValue the original number
 * @returns
 */
export function convertNumberToAmericaCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "MM"
      : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
        : Math.abs(Number(labelValue)).toFixed(2);
}

/**
 * add `px` css unit to the supplied value if it is a number
 * @param {number|string} val input value
 * @returns {string}
 * @example
 * ```js
 * addPxIfNumber(24) // "24px"
 * addPxIfNumber("0.5em") // "0.5em"
 * ```
 */
export const addPxIfNumber = (val) => (typeof val === "number" ? `${val}px` : val);

resolveErrorMessages.defaultOptions = {
  multiple: true,
  join: true,
  joinCharacter: ". ",
  errorNames: ["errMsg", "error", "reason", "err"],
};

/**
 *
 * @param {string|{errMsg?: string, error?: string, reason?: string}} caughtError
 * @param {Object} options
 * @param {boolean} options.multiple
 * @param {boolean} options.join
 * @param {string} options.joinCharacter
 * @param {Array<"errMsg"|"error"|"reason"|"err">} options.errorNames
 * @returns {string|string[]|undefined} resolvedMessage
 */
export function resolveErrorMessages(caughtError, options = resolveErrorMessages.defaultOptions) {
  options = {
    ...resolveErrorMessages.defaultOptions,
    ...(options || {}),
  };

  if (caughtError == null) {
    return undefined;
  }
  if (typeof caughtError === "string") {
    return caughtError;
  }
  const resolvedErrors = [];

  const resolve = (name) =>
    Object.hasOwnProperty.call(caughtError, name) ? caughtError[name] : undefined;

  for (const name of options.errorNames) {
    const msg = resolve(name);
    if (msg) {
      if (options.multiple) {
        resolvedErrors.push(msg);
      } else {
        return msg;
      }
    }
  }

  if (options.join) {
    return resolvedErrors.join(options.joinCharacter);
  }
  return resolvedErrors;
}

const validateHttps = (protocol) => {
  if (!protocol.match("https")) {
    return { passed: false, reason: "Protocol must be HTTPS" };
  }
  return { passed: true };
};

function debugUrl(value) {
  const modifications = [
    {
      tester: (value) =>
        !value.includes(":") || ["#", "&", "=", "/", "_", "*", " "].includes(value.split(":")[0]),
      reason: "Missing protocol",
      modifier: (value) => `https://${value}`,
    },
  ];

  const check = (val) => {
    try {
      new URL(val);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (check(value)) {
    return { passed: true };
  }

  for (const modification of modifications) {
    if (modification.tester(value) && check(modification.modifier(value))) {
      return { passed: false, reason: modification.reason };
    }
  }
  return { passed: false, reason: "Invalid URL (must be full a url)" };
}

/**
 * Generates a url validator with the supplied rules
 * @param {Object} options
 * @param {string|RegExp|(host: string) => { passed: true } | { passed: false, reason: string }} options.host matcher/validator for the url host
 * @param {string|RegExp|(pathname: string) => { passed: true } | { passed: false, reason: string }} options.pathname matcher/validator for the url path
 * @param {string|RegExp|(origin: string) => { passed: true } | { passed: false, reason: string }} options.origin matcher/validator for the url origin
 * @param {string|RegExp|(searchParams: URLSearchParams) => { passed: true } | { passed: false, reason: string }} options.searchParams matcher/validator for the url search params
 * @param {string|RegExp|(protocol: string) => { passed: true } | { passed: false, reason: string }} options.protocol matcher/validator for the url protocol
 * @returns {(testUrl: string) => { passed: true } | { passed: false, reason: string }} validator
 */
export function generateUrlValidator(options) {
  const {
    host = /.*/gi,
    pathname = /.*/gi,
    origin = /.*/gi,
    searchParams = /.*/gi,
    protocol = validateHttps,
  } = {
    ...(options || {}),
  };
  return (testUrl) => {
    try {
      const url = new URL(testUrl);
      if (origin) {
        if (typeof origin === "function") {
          const res = origin(url.origin);
          if (!res.passed) {
            return res;
          }
        } else if (typeof origin === "string" || origin instanceof RegExp) {
          if (!url.origin.match(origin)) {
            return { passed: false, reason: `URL origin must match ${origin}` };
          }
        }
      }
      if (host) {
        let hostname = url.host || url.hostname;
        if (typeof host === "function") {
          const res = host(hostname);
          if (!res.passed) {
            return res;
          }
        } else if (typeof host === "string" || host instanceof RegExp) {
          if (!hostname.match(host)) {
            return { passed: false, reason: `URL host must match ${host}` };
          }
        }
      }

      if (pathname) {
        if (typeof pathname === "function") {
          const res = pathname(url.pathname);
          if (!res.passed) {
            return res;
          }
        } else if (typeof pathname === "string" || pathname instanceof RegExp) {
          if (!url.pathname.match(pathname)) {
            return { passed: false, reason: `URL pathname must match ${pathname}` };
          }
        }
      }

      if (protocol) {
        if (typeof protocol === "function") {
          const res = protocol(url.protocol);
          if (!res.passed) {
            return res;
          }
        } else if (typeof protocol === "string" || protocol instanceof RegExp) {
          if (!url.protocol.match(protocol)) {
            return { passed: false, reason: `URL protocol must match ${protocol}` };
          }
        }
      }

      if (searchParams && typeof searchParams === "function") {
        const res = searchParams(url.searchParams);
        if (!res.passed) {
          return res;
        }
      }

      return { passed: true };
    } catch (e) {
      console.error(e);
      return debugUrl(testUrl);
    }
  };
}

/**
 * parses a boolean query parameter from next/router
 * @param {string} query query parameter to validate
 * @returns {boolean} parsed query parameter
 */
export function parseBooleanQuery(query) {
  if (query != null) {
    return !["false", "0", ""].includes(query);
  }
  return false;
}

/**
 * Turns an array into an object
 * @param {Array<any>} arr array to turn into object
 * @param {string|number|symbol|value: any) => string|number|symbol} getKey key getter function
 * @returns {Record<string|number|symbol, any>} the object
 */
export const objectFromArray = (arr, getKey) => {
  return arr.reduce((acc, value) => {
    const key = typeof getKey === "function" ? getKey(value) : value[getKey];
    if (!["string", "number", "symbol"].includes(typeof key)) {
      throw new Error(`Invalid key assignment '${key}' with value '${value}'`);
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

/**
 * Sets a shallow query parameter
 * @param {NextRouter} router next/router
 * @param {Record<string, string|string[]|null|boolean>} query query parameters to set
 * @param {boolean?} [overwrite] if true, query parameters will overwrite existing parameters; default `false`
 * @param {Object} [props] props to pass to `router.replace / router.push`; default `{}`
 * @param {boolean} [push] use router.push instead (allows for page history update); default `false`
 * @param {string|undefined} [props.hash] the # to add to the end of the url
 * @returns {Promise<boolean>} whether the query parameters were set
 */
export async function setShallowQuery(router, query, overwrite = false, props = {}, push = false) {
  const navigate = push ? router.push : router.replace;
  return await navigate(
    {
      pathname: router.pathname,
      query: filterObject(
        overwrite ? query : { ...router.query, ...query },
        (value) => value != null
      ),
      ...props,
    },
    undefined,
    { shallow: true }
  );
}

/**
 * removes a query parameter from url
 * @param {NextRouter} router next/router
 * @param {string|string[]} query query parameter(s) to remove
 * @returns {Promise<boolean>} whether the query parameters were removed
 */
export function removeQuery(router, query) {
  const queryObject = Array.isArray(query)
    ? query.reduce((acc, key) => ({ ...acc, [key]: null }), {})
    : { [query]: null };
  return setShallowQuery(router, queryObject);
}

/**
 * checks if the given value nullish, retruns defaultValue if it is
 * @param {T} value value to test if null
 * @param {K} defaultValue default value to return if value is null
 * @returns {T | K}
 */
export function orDefault(value, defaultValue) {
  return value != null && !Number.isNaN(value) ? value : defaultValue;
}

export const tokenValidator = async (token) => {
  try {
    const res = await axios.get("https://api.coingecko.com/api/v3/coins/list");
    const tokenList = res.data;
    if (tokenList.some((x) => x.symbol === token.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const coinGeckoValidator = generateUrlValidator({
  host: (host) => {
    const coinGeckoHostName = "www.coingecko.com";
    if (host !== coinGeckoHostName) {
      return {
        passed: false,
        reason: `Invalid host, expected '${coinGeckoHostName}' but got '${host}'`,
      };
    }
    return { passed: true };
  },
  pathname: (pathname) => {
    const path = pathname.split("/");
    if (!path.includes("coins")) {
      return { passed: false, reason: "Supplied url does not link to a coin" };
    } else if (path.filter((val) => val !== "coins").length === 0) {
      return { passed: false, reason: "Token ID is missing from URL" };
    }
    return { passed: true };
  },
});

export const coinMarketCapValidator = generateUrlValidator({
  host: (host) => {
    const coinMarketCapHostName = "coinmarketcap.com";
    if (host !== coinMarketCapHostName) {
      return {
        passed: false,
        reason: `Invalid host, expected '${coinMarketCapHostName}' but got '${host}'`,
      };
    }
    return { passed: true };
  },
  pathname: (pathname) => {
    const path = pathname.split("/");
    if (!path.includes("currencies")) {
      return { passed: false, reason: "Supplied url does not link to a coin" };
    } else if (path.filter((val) => val !== "coins").length === 0) {
      return { passed: false, reason: "Token ID is missing from URL" };
    }
    return { passed: true };
  },
});

export const twitterLinkValidator = generateUrlValidator({
  host: (host) => {
    if (host !== "twitter.com" && host !== "x.com") {
      return {
        passed: false,
        reason: `Invalid host, expected twitter.com or x.com, but got '${host}'`,
      };
    }
    return { passed: true };
  },
  pathname: (pathname) => {
    const path = pathname.split("/");
    if (path.filter((val) => val !== "").length === 0) {
      return { passed: false, reason: "Twitter username is missing from URL" };
    }
    return { passed: true };
  },
});

export const telegramLinkValidator = generateUrlValidator({
  host: (host) => {
    const telegramHostName = "t.me";
    if (host !== telegramHostName) {
      return {
        passed: false,
        reason: `Invalid host, expected '${telegramHostName}' but got '${host}'`,
      };
    }
    return { passed: true };
  },
  pathname: (pathname) => {
    const path = pathname.split("/");
    if (path.filter((val) => val !== "").length === 0) {
      return { passed: false, reason: "Telegram username / groupname is missing from URL" };
    }
    return { passed: true };
  },
});

export const certikDotComPublishLinkValidator = generateUrlValidator({
  host: (host) => {
    const publishHostName = "www.certik.com";
    const publishHostNameV2 = "skynet.certik.com"; // no www.
    if (host !== publishHostName && host !== publishHostNameV2) {
      return {
        passed: false,
        reason: `Invalid host, expected '${publishHostName}' or '${publishHostNameV2}' but got '${host}'`,
      };
    }
    return { passed: true };
  },
  pathname: (pathname) => {
    const path = pathname.split("/");
    if (path.filter((val) => val !== "").length === 0) {
      return { passed: false, reason: "ProjectId is missing from URL" };
    }
    return { passed: true };
  },
});

/**
 * Trims the white space from rich text
 * @param {string} richText the rich text to trim
 */
export function trimRichWhitespace(richText) {
  richText = richText || "";
  const trailingRegex = /(<p>(<br>|\s|\t)+<\/p>)*$/gm;
  const leadingRegex = /^(<p>(<br>|\s|\t)+<\/p>)*/gm;
  const leadingSpaceRegex = /(^<p>[ \s]*)/;
  const trailingSpaceRegex = /[ \s]+<\/p>$/;
  return richText
    .replace(/&nbsp;/g, " ")
    .replace(trailingRegex, "")
    .replace(leadingRegex, "")
    .replace(leadingSpaceRegex, "<p>")
    .replace(trailingSpaceRegex, "</p>");
}

export function hasNonEnglishCharacters(text) {
  if (!text) return false;
  const reg = new RegExp(
    `^[${allowedCharsForMentionList}\\d-=_+\`~!@#$%^&*(){}\\|[\\]\\\\;':\\",./<>?${allowedEmojis}]+$`,
    "i"
  );
  return !reg.test(text);
}

// use for mention list check in QuickCommentEditor.js and CommentEditor.js
export const allowedCharsForMentionList = "A-Za-z\\sÅÄÖåäö";
export const allowedEmojis = "\u2705\u{1F64F}\u{1F604}\u{1F61E}\u{1F44D}\u{1F44E}";

export function highlightingIllegalCharacters(quill) {
  // Regular expressions for characters allowed to appear in the comment box.
  const reg = new RegExp(
    `[${allowedCharsForMentionList}\\d-=_+\`~!@#$%^&*(){}\\|[\\]\\\\;':\\",./<>?${allowedEmojis}]+`,
    "gim"
  );
  // Keep information about illegal characters,
  const infoOfIllegalCharacters = []; //[start index, the illegal string] e.g.[20, 哈]
  let text = "", // the original comment, where the mentionlist will be replaced with space,
    legalStr = "", // the legal string in the comment
    illegalStr = ""; // the illegal string in the comment
  let startIdx = 0, // start index of illegal string
    foundIllegalCharacters = false; // As a flag for whether an illegal string is found
  const quillContent = quill.getContents();
  for (let content of quillContent.ops) {
    // replace mentionlist with space
    if (content.insert.mention) {
      text += " ";
    } else {
      text += content.insert;
    }
  }
  // If there are legal characters in text, we keep them in legalStr
  if (text.match(reg)) {
    for (const str of text.match(reg)) {
      legalStr += str;
    }
  }

  // Comparing the original comment(text) with the legal string(legalStr) to find the illegal string
  // and keep the information about illegal characters in infoOfIllegalCharacters
  for (let i = 0, j = 0; i < text.length; i++, j++) {
    if (text[i] != legalStr[j]) {
      foundIllegalCharacters = true;
      startIdx = i;
      while (text[i] != legalStr[j]) {
        illegalStr += text[i++];
      }
    }
    if (foundIllegalCharacters) {
      infoOfIllegalCharacters.push([startIdx, illegalStr]);
      illegalStr = "";
      foundIllegalCharacters = false;
    }
  }

  // If we find any illegal character, we first remove its original style and then mark it as red
  while (infoOfIllegalCharacters.length) {
    const [startIdx, illegalStr] = infoOfIllegalCharacters.pop();
    quill.removeFormat(startIdx, illegalStr.length);
    quill.updateContents(
      new Delta().retain(startIdx).retain(illegalStr.length, { color: "red", bold: true })
    );

    // Due to the feature of the quill,
    // we also need to change back the style of the quill content to the default style
    quill.updateContents(
      new Delta().retain(startIdx + illegalStr.length).retain(1, { color: "black", bold: false })
    );
  }

  return infoOfIllegalCharacters;
}

export function handleImgsInInput(inputText) {
  // Temporarily convert inputText into html
  let div = document.createElement("div");
  div.innerHTML = inputText;
  // Get images
  const images = div.getElementsByTagName("img") || new HTMLCollection([]);
  let imgSrcs = [];
  for (let i = 0; i < images.length; i++) {
    // Store img src
    imgSrcs.push(images[i].getAttribute("src"));
    // Remove img tags from html (pass by reference)
    images[i]?.parentNode?.removeChild(images[i]);
  }
  return {
    reformattedInput: div.innerHTML,
    imgSrcs: imgSrcs,
  };
}

export function handleInvisibleCharInInput(inputText) {
  let formattedText = inputText;

  // Replace the html tag of highlighted char with a line break
  formattedText = formattedText.replace(
    /<[^<]+>[\u00A0\u180E\u2000-\u200F\u2028-\u202F\u205F-\u206F\u3000\u3164]<\/[^>]+>/gu,
    "<br>"
  );

  // Replace the inline char with a line break
  formattedText = formattedText.replace(
    /[\u00A0\u180E\u2000-\u200F\u2028-\u202F\u205F-\u206F\u3000\u3164]/gu,
    "<br>"
  );

  return formattedText;
}

const hashCode = (string) => {
  var hash = 0,
    i,
    chr;
  if (string?.length === 0) return hash;
  for (i = 0; i < string?.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function getAvatarColorPerUserId(userId) {
  return AVATAR_COLOR_LIST[Math.abs(hashCode(userId)) % 4];
}

const validateURLFormat = (string) => {
  const re =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  return re.test(string);
};

/**
 * Checks whether the given url is a valid Accelerator report url with placeholder.
 * Current Accelerator report url looks like this: `https://acc.audit.certikpowered.info/project/[pid]/report`
 * @param {string} url the url to be examined
 * @returns {boolean} result
 */
export function validateAcceleratorReportUrlWithPlaceholder(url) {
  const placeholderRe = /(\[pid\])/g;
  return validateURLFormat(url) && placeholderRe.test(url);
}

// For frontend use only
export function parseLocalStorageItemIntoJson(storageKey) {
  const value = localStorage.getItem(storageKey);
  if (value == null || value === "") {
    console.log(`could not find ${storageKey} in localStorage`);
    return null;
  }
  try {
    return JSON.parse(value);
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export const removeDuplicate = (dataList, ID_TYPE) => {
  let map = new Map();
  let result = [];
  if (ID_TYPE === "ID") {
    for (let item of dataList) {
      if (!map.has(item.ID)) {
        result.push(item);
      }
      map.set(item.ID, true);
    }
  } else if (ID_TYPE === "ALERT_ID") {
    for (let item of dataList) {
      if (!map.has(item.ALERT_ID)) {
        result.push(item);
      }
      map.set(item.ALERT_ID, true);
    }
  }
  return result;
};

export const chainAddressBuilder = (chainAlias, address) =>
  `${chainAlias.toLowerCase()}:${address.toLowerCase()}`;

export const findingCombinationKeyBuilder = (findingIdentifier, reportIdentifier) => {
  return `${findingIdentifier}|${reportIdentifier}`;
};

export function buildShareReportOrFindingUrl(reportType, linkId, findingIndex = null) {
  return `${window.location.origin}/shared-report/${
    reportType === FINDING_REPORTS_TYPE.INCREMENTAL ? "incremental/" : ""
  }${linkId}${findingIndex ? `?findingIndex=${findingIndex}` : ""}`;
}

export const wait = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const NOOP = () => {};
export const safeParse = (str, defaultVal = str, onerror = NOOP) => {
  if (typeof str !== "string") {
    return str;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    onerror(e);
    return defaultVal;
  }
};

export const customerServiceIdAdapter = (userId) => {
  if (userId === "superadmin@certik.org") {
    return CLIENT_SUPPORT_EMAIL_ADDRESS;
  } else {
    return userId;
  }
};

export const getProjectStatusPercentage = (projectInfo) => {
  if (projectInfo?.status in AUDIT_PROGRESS_PERCENTAGE) {
    return AUDIT_PROGRESS_PERCENTAGE[projectInfo.status];
  }
  return 0;
};

/**
 * Hook for animating a shrinking container upon load with start & end height set by element heights.
 *
 * Use this with `<ShrinkingContainer/>` below
 *
 * @example
 * // We want to show a loading skeleton, but in some cases if there's no data we replace the skeleton with a different-sized element
 *```jsx
 * const { startHeight, startHeightRef, endHeight, endHeightRef } = useShrinkingContainer();
 *
 * { !loading && (
 *  <ShrinkingContainer
 *    startHeight={startHeight}
 *    endHeight={endHeight}
 *    bgColor={BANNER_BG_COLOR}
 *    fillStatsPlaceholder={Boolean(startHeight && endHeight)}
 *  >
 *    <div ref={endHeightRef}>
 *      // element to replace skeleton here
 *    </div>
 *  </ShrinkingContainer>
 * )}
 * { loading && (
 *  <div ref={endHeightRef}>
 *    // skeleton here
 *  </div>
 * )}
 *```
 *
 */
export function useShrinkingContainer() {
  const [startHeight, setStartHeight] = useState(null);
  const [endHeight, setEndHeight] = useState(null);
  const observers = useRef({
    start: null,
    end: null,
  });
  const observerDismountRef_start = observers.current?.start; // observer ref becomes null before the unmount cleanup can fire so we save it here
  const observerDismountRef_end = observers.current?.end; // observer ref becomes null before the unmount cleanup can fire so we save it here
  useEffect(() => {
    return () => {
      observerDismountRef_start?.disconnect();
      observerDismountRef_end?.disconnect();
    };
  }, [observerDismountRef_start, observerDismountRef_end]); // disconnect ResizeObserver on unmount

  const endHeightRef = useCallback((ref) => {
    if (ref && !observers.current?.end) {
      observers.current.end = new ResizeObserver(setNewHeight(setEndHeight));
      observers.current?.end?.observe(ref);
    }
  }, []);
  const startHeightRef = useCallback((ref) => {
    if (ref && !observers.current?.start) {
      observers.current.start = new ResizeObserver(setNewHeight(setStartHeight));
      observers.current?.start?.observe(ref);
    }
  }, []);
  return { startHeightRef, endHeightRef, startHeight, endHeight };
}

/**
 * An animated container to be used with the `useShrinkingContainer` hook.
 *
 * Shrinks from `startHeight` to `endHeight`. Can specify `bgColor`.
 *
 * @param {number} props.startHeight
 * @param {number} props.endHeight
 * @param {string} [props.bgColor]
 */
export const ShrinkingContainer = styled.div`
  @keyframes shrink {
    from {
      height: ${(props) => props.startHeight}px;
    }
    to {
      height: ${(props) => props.endHeight}px;
    }
  }
  border-radius: 4px;
  ${(props) => props.bgColor && `background: ${props.bgColor};`}
  ${(props) => props.fillStatsPlaceholder && "animation: shrink 0.5s ease;"}
`;

function setNewHeight(setHeight) {
  return (elements) => {
    if (elements[0]?.contentRect?.height) {
      setHeight(elements[0].contentRect?.height);
    }
  };
}

export const toTimePassedText = (since, until) => {
  if (typeof since !== "number" || typeof until !== "number") return "";
  const totalSeconds = Math.floor((until - since) / 1000);
  return `${Math.floor(totalSeconds / 60)} min ${Math.floor(totalSeconds % 60)} sec`;
};

export const getNavigatorLanguage = () => {
  const navigatorLanguage = NAVIGATOR_LANGUAGE_MAP[navigator?.language?.slice(0, 2)];
  console.log("navigatorLanguage:", navigatorLanguage);
  if (navigatorLanguage && navigatorLanguage in AVAILABLE_LOCALES) {
    return navigatorLanguage;
  } else {
    return "en";
  }
};

export const getCurrentLocale = (lanauage) => {
  if (lanauage === "zh-cn") {
    return zhCN;
  } else if (lanauage === "ko-kr") {
    return koKR;
  } else {
    return enUS;
  }
};

/**
 * React does not automatically add `px` to these when used in `style={{}}`
 * From https://github.com/facebook/react/blob/4131af3e4bf52f3a003537ec95a1655147c81270/src/renderers/dom/shared/CSSProperty.js#L15-L59
 */
const unitlessCSSProperties = {
  animationIterationCount: true,
  borderImageOutset: true,
  borderImageSlice: true,
  borderImageWidth: true,
  boxFlex: true,
  boxFlexGroup: true,
  boxOrdinalGroup: true,
  columnCount: true,
  columns: true,
  flex: true,
  flexGrow: true,
  flexPositive: true,
  flexShrink: true,
  flexNegative: true,
  flexOrder: true,
  gridRow: true,
  gridRowEnd: true,
  gridRowSpan: true,
  gridRowStart: true,
  gridColumn: true,
  gridColumnEnd: true,
  gridColumnSpan: true,
  gridColumnStart: true,
  fontWeight: true,
  lineClamp: true,
  lineHeight: true,
  opacity: true,
  order: true,
  orphans: true,
  tabSize: true,
  widows: true,
  zIndex: true,
  zoom: true,
  // SVG-related properties
  fillOpacity: true,
  floodOpacity: true,
  stopOpacity: true,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: true,
  strokeWidth: true,
};

/**
 * Converts a CSSProperties object (i.e. the one used by the `style` prop) to a CSS rule string.
 * Useful for taking the style prop from a component and passing it into the body of a styled-components CSS string.
 * @param {import("react").CSSProperties} cssProperties
 * @param important
 */
export function convertCSSPropertiesToString(cssProperties, important = false) {
  return Object.entries(cssProperties).reduce((prev, [key, val]) => {
    const kebabCasedKey = kebabCase(key);
    const valueWithOrWithoutPx = unitlessCSSProperties[key] ? val : addPxIfNumber(val);
    return prev + `${kebabCasedKey}: ${valueWithOrWithoutPx}${important ? "!important" : ""};`;
  }, "");
}

// TODO fetch git commit hash value both in vercel and ECS
export function getBuildReleaseVersion() {
  const gitSha = process.env.NEXT_PUBLIC_GIT_COMMIT_SHA;
  return gitSha?.slice(0, 8) ?? "skyharbor-web";
}

export function getUserInfo() {
  const userId = localStorage?.getItem(USER_ID) || null;
  const tenantId = localStore?.get(SELECTED_TENANT_ID);
  const authData = localStore?.get(AUTH_DATA);
  // Get current build version
  const localBuildVersionBlob = safeParse(localStore?.get(BUILD_VERSION_CONFIG));
  const localVersion = localBuildVersionBlob?.build?.value;

  return {
    userId,
    tenantId,
    authData,
    localVersion,
  };
}

/**
 *
 * @param {Function} fetchApi
 * @param {Array} dependencies
 * @param {Function} callback
 */
export function useDebounceFetch(fetchApi, dependencies = [], callback) {
  const handler = useCallback(async (abortSignal) => {
    fetchIndexRef.current++;
    const currentFetchIndex = fetchIndexRef.current;
    const data = await fetchApi(abortSignal);
    if (currentFetchIndex !== fetchIndexRef.current) {
      return;
    }
    callback && callback(data);
  }, dependencies);

  useEffect(() => {
    const abortController = new AbortController();
    void handler(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, dependencies);
  const fetchIndexRef = useRef(0);
}
