import { DateTime } from "luxon";
import { AUDIT_STATUS_LABELS } from "../../const/audit";
import { DEFAULT_FALLBACK_LOCALE } from "../../const/locale";
import { getReportName } from "./report";
import { isValid, validateUrlRegex } from "../common/validators";

export const DEFAULT_DATE_FORMAT = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export const DEFAULT_TIME_FORMAT = {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
};

export const DEFAULT_DATE_TIME_FORMAT = {
  ...DEFAULT_DATE_FORMAT,
  ...DEFAULT_TIME_FORMAT,
};

/**
 * Parse a date to Date String format
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {object} [dateFormat=DEFAULT_DATE_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @param {string} [logger=undefined] Optional logger for backend to log errors
 * @returns {string} A string representing the date portion of the given Date instance according to language-specific conventions
 */
export const formatTimestamp = (
  timestamp,
  locale = undefined,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  if (timestamp == null || isNaN(timestamp)) {
    console.warn("formatTimestamp received invalid date input", timestamp);
    return "";
  }
  return new Date(timestamp).toLocaleDateString(locale, dateFormat);
};

/**
 * Parse a date to Date String format with time
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {object} [dateFormat=DEFAULT_DATE_TIME_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @param {string} [logger=undefined] Optional logger for backend to log errors
 * @returns {string} A string representing the date portion of the given Date instance according to language-specific conventions
 */
export const formatTimestampWithTime = (
  timestamp,
  locale = undefined,
  dateTimeFormat = DEFAULT_DATE_TIME_FORMAT
) => {
  return formatTimestamp(timestamp, locale, dateTimeFormat);
};

/**
 * Parse a date to Time String format with only time
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {string} [dateFormat=DEFAULT_TIME_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @returns {string} A string representing the time portion of the given DateTime instance according to language-specific conventions
 */
export const formatTimestampOnlyTime = (
  timestamp,
  locale = undefined,
  timeFormat = DEFAULT_TIME_FORMAT
) => {
  if (timestamp == null || isNaN(timestamp)) {
    console.error("formatTimestamp received invalid time input", timestamp);
    return "";
  }
  return new Date(timestamp).toLocaleTimeString(locale, timeFormat);
};

/**
 * Parse a date to [... ago] format
 * @param {number} date the timestamp property of the object
 * @param {"long" | "short" | "narrow"} style style of the formatted time
 * @param {string} [locale=DEFAULT_FALLBACK_LOCALE] Locale which specifies the language whose formatting conventions should be used
 * @returns {string} the formatted time
 */
export const formatTimeAgo = (date, style = "long", locale = DEFAULT_FALLBACK_LOCALE) => {
  const units = ["year", "month", "week", "day", "hour", "minute", "second"];
  if (date == null) {
    console.error("formatTimeAgo received invalid date input", date);
    return "";
  }
  let dateTime = DateTime.fromMillis(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";
  const relativeFormatter = new Intl.RelativeTimeFormat(locale, {
    numeric: "auto",
    style: style,
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const formatTimeRange = ({ startDate = 0, endDate = 0, unit = "months" }) => {
  const units = ["months", "days"];
  const startDateTime = DateTime.fromMillis(startDate);
  const endDateTime = DateTime.fromMillis(endDate);
  const diffValue = endDateTime.diff(startDateTime, units);
  return `${diffValue[unit]} ${unit}`;
};

/**
 * Format a date to [... ago] if it is within one day, otherwise use formatTimestamp() function
 * @param {number} time the timestamp property of the object
 * @param {"long" | "short" | "narrow"} [style="long"] style of the formatted time
 * @param {string} [locale=DEFAULT_FALLBACK_LOCALE] Locale which specifies the language whose formatting conventions should be used
 * @param {string} [numeric=DEFAULT_DATE_FORMAT] Defines whether the date should be written out fully in numeric (with leading zeros), e.g.:09/07/2022
 * @returns {string} the formatted time
 */
export const formatTimeAgoIfWithinOneDay = (
  time,
  style = "long",
  locale = DEFAULT_FALLBACK_LOCALE,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  if (time == null || isNaN(time)) {
    console.warn("formatTimeAgoIfWithinOneDay received invalid date input", time);
    return "";
  }
  const now = Date.now();
  const delta = now - time;
  const day = 8.64e7;
  if (delta < 1 * day) {
    return formatTimeAgo(time, style, locale);
  } else {
    return formatTimestamp(time, locale, dateFormat);
  }
};

export const formatAuditFieldsForEmailRendering = (property, value, projectInfo = null) => {
  if (property == null || value == null) {
    // value can be 0
    return null;
  }

  switch (property) {
    case "expectedDeadline":
      return formatTimestamp(value);
    case "categories":
      if (!Array.isArray(value)) {
        return customReplaceAll(JSON.stringify(value), '"', "");
      } else {
        return value.join(" & ");
      }
    case "auditReportList":
      if (!Array.isArray(value)) {
        return null;
      } else if (value.length === 0) {
        return null;
      } else {
        return value
          .sort((a, b) => a.createdAt - b.createdAt)
          .map((item) => {
            let fullReportName = getReportName(projectInfo, item);
            if (item.fileUrl != null && isValid(validateUrlRegex(item.fileUrl))) {
              fullReportName += " (PDF)";
            }
            if (item.showSignOff === true) {
              fullReportName += " (Signable)";
            }
            return fullReportName;
          })
          .join(", "); // Return string of comma-separated file names
      }
    case "assignedCertikDevList":
    case "attachments":
    case "images":
      if (!Array.isArray(value)) {
        return null;
      } else {
        return value.join(", ");
      }
    // TODO: Implement server-side version of richTxtToPlainTxt() function for this to work
    // case "scopeOfWorkGithub":
    // case "scopeOfWorkAdditional": // obsolete field, keep it for compatibility
    // case "scopeOfWorkOnChain":
    //   return richTxtToPlainTxt(value).split("\n");
    case "findings":
      return customReplaceAll(JSON.stringify(value, undefined, 2), '"', "").split("\n");
    case "status":
      return AUDIT_STATUS_LABELS[value].label;
    case "blockPublishingAction":
      return value.blocked ? "Blocked" : "Unblocked";
    default:
      return customReplaceAll(JSON.stringify(value), '"', "");
  }
};

/**
 * Replaces all instances of `targetString` with `replaceString` in 'srcString'.
 * This function intends to temporarily replicate node's `replaceAll` function since node's `replaceAll`
 * is currently not supported in node versions < 15, and our vercel environment is at v14.
 *
 * @param {string} srcString the string to perform replacement on
 * @param {string} targetString the string we want to match
 * @param {string} replaceString the string we want to replaced the matched string (target) with
 * @returns {string} returns the string with replacement done on
 */
export function customReplaceAll(srcString, targetString, replaceString) {
  if (targetString == null || srcString == null || replaceString == null) {
    throw new TypeError("customReplaceAll input strings cannot be null or undefined.");
  }

  replaceString = replaceString.replace(/\$/g, "$$$$");
  return srcString.replace(
    // eslint-disable-next-line no-useless-escape
    new RegExp(targetString.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|<>\-\&])/g, "\\$&"), "g"),
    replaceString
  );
}

/**
 * Helper function to convert milliseconds (1649721600000) to utz time (2022-04-12)
 * @param {number} msTime Milliseconds
 * @returns {string} Utz formatted time
 */
export function convertMsToUtz(msTime) {
  const date = new Date(msTime);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/**
 * Convert 2 unix timestamps in the *same year* to a friendly time string
 * E.g. start = 1649563200, end = 1650168000 => "10 April - 17 April, 2022"
 * @param {number} start Start unix timestamp (in seconds)
 * @param {number} end End unix timestamp (in seconds)
 * @param {Intl.DateTimeFormatOptions} options month, day, etc
 * @returns {string} Friendly time range e.g. 10 April - 17 April, 2022 (default)
 */
export function formatUnixTimestampsRangeToFriendlyString(
  start,
  end,
  options = { month: "long", day: "numeric" }
) {
  const startDate = new Date(start * 1000).toLocaleDateString(undefined, options);
  const endDate = new Date(end * 1000).toLocaleDateString(undefined, options);
  const year = new Date(end * 1000).getFullYear();
  return `${startDate} - ${endDate}, ${year}`;
}

/**
 * Convert 2 unix timestamps in any years to a friendly time string
 * E.g. start = 1649563200, end = 1650168000 => "10 April, 2022 - 17 April, 2022"
 * @param {number} start Start unix timestamp (in seconds)
 * @param {number} end End unix timestamp (in seconds)
 * @param {Intl.DateTimeFormatOptions} options month, day, etc
 * @returns {string} Friendly time range e.g. 10 April, 2022 - 17 April, 2022 (default)
 */
export function formatUnixTimestampsRangeToFriendlyFullString(
  start,
  end,
  options = { month: "long", day: "numeric" }
) {
  const startDate = new Date(start * 1000).toLocaleDateString(undefined, options);
  const startYear = new Date(start * 1000).getFullYear();
  const endDate = new Date(end * 1000).toLocaleDateString(undefined, options);
  const endYear = new Date(end * 1000).getFullYear();
  return `${startDate}, ${startYear} - ${endDate}, ${endYear}`;
}
