export const FEATURE_MAP = {
  audit: {
    id: "audit",
    name: "Audit",
    type: "page",
    route: "/audits/projects",
    fallbackPriority: 1,
  },
  auditOverview: {
    id: "auditOverview",
    name: "Audit Overview",
    type: "page",
    route: "/audits/overview",
  },
  securityScore: {
    id: "securityScore",
    name: "Security Score",
    type: "page",
    route: "/security-score",
  },
  dailySummaryEmail: {
    id: "dailySummaryEmail",
    name: "Daily Summary Email",
    type: "service",
  },
  invoice: {
    id: "invoice",
    name: "Invoice",
    type: "page",
    route: "/invoices",
  },
  splitView: {
    id: "splitView",
    name: "Split View",
    type: "layout",
  },
  dashboard: {
    id: "dashboard",
    name: "Dashboard",
    type: "page",
    route: "/dashboard",
    fallbackPriority: 0,
  },
  intercom: {
    id: "intercom",
    name: "Intercom",
    type: "service",
  },
  apiKey: {
    id: "apiKey",
    name: "API Key",
    type: "service",
  },
  marketplace: {
    id: "marketplace",
    name: "Marketplace",
    type: "service",
  },
  freeBugBounty: {
    id: "freeBugBounty",
    name: "Free Bug Bounty",
    type: "service",
  },
  overviewDashboard: {
    id: "overviewDashboard",
    name: "Audit Overview Dashboard",
    type: "service",
  },
  investigationCases: {
    id: "investigationCases",
    name: "Investigation Cases",
    type: "page",
  },
  referralProjectTracker: {
    id: "referralProjectTracker",
    name: "Referral Project Tracker",
    type: "page",
    route: "/audits/referral-projects",
  },
};
