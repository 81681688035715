/**
 * cleans a file name for better rendering/readability
 * @param {string} fileName the filename to clean
 * @param {boolean} removeExtension remove the files extension
 * @returns {string} cleaned filename
 */
export function cleanFileName(fileName, removeExtension = false) {
  if (fileName == null) {
    return null;
  }
  // TODO: REP and PRE might collide with project name
  if (fileName.match(/-?REP-/g)) {
    return "Final Report";
  }
  if (fileName.match(/-?PRE-/g)) {
    return "Preliminary Report";
  }
  if (fileName.match(/-\d+\.pdf$/gm)) {
    const name = fileName.split("-").slice(0, -1).join("-");
    return removeExtension ? name : name + ".pdf";
  }
  return fileName;
}
