import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useStore } from "./useStore";
import {
  VALID_STATUS_SET,
  NO_AUDIT_TEAM_FILTER_LABEL,
  AUDIT_ADVANCED_SEARCH,
  ADMIN_PROJECT_ADVANCED_SEARCH,
  UNRESPONSIVE_STATUS_ROLE_WHITELIST,
  AUDIT_STATUS,
  AUDIT_FILTER_ORDER_BY_MAP,
} from "../const";
import {
  fetchProjects,
  fetchProjectsWithLastComment,
  fetchProjectCountByStatus,
} from "../utils/frontend/fetchFromApi";
import {
  countUnique,
  countUniqueInNestedArray,
  parseLocalStorageItemIntoJson,
} from "../utils/frontend/utils";
import { useProjectDrawer } from "./useProjectDrawer";
import {
  DEFAULT_STATE,
  DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS,
} from "../components/SharedComponents/AdvancedSearch/Shared";
import {
  addOrRemoveUnresponsive,
  applyStatusFilter,
  applyTimeRangeAndOrderBy,
} from "../utils/common/project";

const ProjectContext = createContext([{}, () => {}]);

export function useProject() {
  return useContext(ProjectContext);
}

// creates an object where the keys are audit statuses and the value is an empty array
const defaultProjectsState = () =>
  [...VALID_STATUS_SET].reduce((accumulator, status) => ({ ...accumulator, [status]: [] }), {
    all: [],
  });

const DEFAULT_STATE_FOR_BACKEND = {
  statusList: Object.keys(DEFAULT_STATE.projectStatus).filter(
    (status) => status !== "all" && DEFAULT_STATE.projectStatus[status] === true
  ),
  timeRange: DEFAULT_STATE.timeRange,
  orderBy: DEFAULT_STATE.orderBy,
};

const DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND = {
  statusList: Object.keys(DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.projectStatus).filter(
    (status) =>
      status !== "all" && DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.projectStatus[status] === true
  ),
  timeRange: DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.timeRange,
  orderBy: DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.orderBy,
};

export function WithProject({ children, withLastComment = false, forAuditOverview = false }) {
  const [store] = useStore();
  const [selectedStatus, setSelectedStatus] = useState("all"); // AUDIT_STATUS or "all"
  const [projects, setProjects] = useState(defaultProjectsState());
  const [isLoading, setIsLoading] = useState(true); // Use this for UI loading state - isLoading finishes when we have usable data, but more data may be on the way!
  const [allDataFetched, setAllDataFetched] = useState(true); // When ALL data (e.g. when batching queries) is fetched
  const [certikTeams, setCertikTeams] = useState({});
  const [tenants, setTenants] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [hasNewProject, setHasNewProject] = useState(false);
  const tableRefreshSymbol = useProjectDrawer((state) => state.tableRefreshSymbol);
  const [countLoading, setCountLoading] = useState(true);
  const [countByStatusMap, setCountByStatusMap] = useState({});
  // previousAdvancedSearch is used to store previous filters when advancedSearch updates, so that we can check if the new filter should trigger a refetch
  const [advancedSearch, setAdvancedSearch] = useState(null);
  const [previousAdvancedSearch, setPreviousAdvancedSearch] = useState(null);

  const role = store?.userInfo?.role;

  /*******************
   * HELPER FUNCTIONS
   ******************/

  const getProjectsByFilterGroup = async (filterGroup) => {
    const fetchProjectsApi = withLastComment ? fetchProjectsWithLastComment : fetchProjects;
    let projects = await fetchProjectsApi(
      store?.userInfo?.userId,
      store?.userInfo?.authData,
      filterGroup
    );
    projects = projects || [];

    return { projects };
  };

  /**
   * Groups the projects list by status then updates the Certik teams, tenants, and projects.
   */
  const updateProjectsState = (projects) => {
    const groupedProjects = defaultProjectsState();
    projects?.forEach((_, index, projects) => {
      const keyedProject = { key: index, ...projects[index] };
      groupedProjects["all"].push(keyedProject);
      groupedProjects[projects[index].status].push(keyedProject);
    });

    console.log("groupedProjects", groupedProjects);
    setCertikTeams(
      countUniqueInNestedArray(
        projects,
        (project) => project.certikTeamNameList,
        true,
        NO_AUDIT_TEAM_FILTER_LABEL
      )
    );
    setTenants(countUnique(projects, (project) => project.tenantName));
    setProjects(groupedProjects);
  };

  /**
   * Gets the projects in up to 2 batches (the 2nd is just "completed" projects). Gets projects with a filtergroup - results will be sorted & filtered already.
   * After 1st batch arrives, updates `projects` and sets `isLoading=false`.
   * After 2nd batch arrives, updates `projects` and sets `awaitingSecondBatch`, which is `true` at the start of a 2-batch query, to `false`.
   */
  const getProjects = async () => {
    if (!store.userInfo) return;
    setIsLoading(true);
    setAllDataFetched(false);

    // For overview page, we need all the projects data
    const filterGroup = forAuditOverview
      ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND
      : advancedSearch;
    const firstCallStatusList = filterGroup.statusList.filter((i) => i !== AUDIT_STATUS.COMPLETED);
    // For filters w/ "completed" status, we split into 2 batches
    const includeCompletedProjects = filterGroup.statusList.includes(AUDIT_STATUS.COMPLETED);

    console.log("First API call", {
      ...filterGroup,
      statusList: firstCallStatusList,
    });
    const { projects: projectsFromFirstCall } = await getProjectsByFilterGroup({
      ...filterGroup,
      statusList: firstCallStatusList,
    });

    updateProjectsState(projectsFromFirstCall);
    setIsLoading(false);

    if (includeCompletedProjects) {
      const secondCallStatusList = [AUDIT_STATUS.COMPLETED];
      console.log("Second API call", {
        ...filterGroup,
        statusList: secondCallStatusList,
      });
      const { projects: projectsFromSecondCall } = await getProjectsByFilterGroup({
        ...filterGroup,
        statusList: secondCallStatusList,
      });
      const allProjects = applyFiltersAndSorting(
        forAuditOverview ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND : advancedSearch,
        [...projectsFromFirstCall, ...projectsFromSecondCall]
      );
      updateProjectsState(allProjects);
    }
    setAllDataFetched(true);
    console.groupEnd(); // start of console.group is in the useEffect hook
  };

  const getProjectCountByStatus = async () => {
    if (!store.userInfo) return;
    setCountLoading(true);
    const resp = await fetchProjectCountByStatus(
      store.userInfo?.userId,
      advancedSearch,
      withLastComment ? "admin" : "audit",
      store.userInfo?.authData
    );
    setCountByStatusMap({
      ...resp,
      all: Object.values(resp).reduce((accu, cur) => accu + cur, 0),
    });
    setCountLoading(false);
  };

  const shouldSortProjectsWithoutHttpCall = useMemo(() => {
    /* True when:
     * - advancedSearch (AKA the sort/filter) would result in a subset of the original dataset
     * - we need to sort batched data together
     * False when:
     * - filter/search could result in a larger dataset than we currently have
     * - no data exists yet
     * e.g. when going the statuses "completed" & "new" to just "new", we don't need more data. */
    // if the project list is empty, we need to fetch data first!
    if (!projects?.all?.length) return false;

    const next = advancedSearch;
    const previous = previousAdvancedSearch;
    // if all filters/sorting can be completed with a subset of current data, don't refetch data
    const statusFilterIsSubset = !!next.statusList?.every((status) =>
      previous.statusList?.includes(status)
    );
    console.log("Status filter is subset: " + statusFilterIsSubset);
    if (!statusFilterIsSubset) return false;

    const timeRangeIsSubset =
      next.timeRange === previous.timeRange ||
      previous.timeRange === -1 ||
      (next.timeRange < previous.timeRange && next.timeRange !== -1); // timeRange of -1 is for "all", in which it's true unless timeRange already -1
    console.log("Timerange is subset: " + timeRangeIsSubset);
    if (!timeRangeIsSubset) return false;

    // the orderBy affects how the timeRange filter is applied to the data, so changing it requires fetching all data again to re-apply the timeRange with new orderBy value (unless timeRange is "all")
    const orderByRequiresMoreData = next.orderBy !== previous.orderBy && next.timeRange !== -1;
    orderByRequiresMoreData && console.log("More data required due to orderBy change");
    if (orderByRequiresMoreData) return false;
    return true;
  }, [advancedSearch, previousAdvancedSearch, projects?.all?.length]);

  /**
   * Applies filter & sort to the main project dataset, then updates the state.
   * This is used to filter/sort on the frontend - if `advancedSearchIsSubset=false`, MORE DATA may be required, so you should `getProjects()` instead!
   */
  const applyFilterGroupAndUpdateProjectsState = (filterGroup) => {
    console.info("Filtering & sorting on frontend");
    const allProjects = projects.all; // projects is grouped by status; use "all" to get full project array
    const result = applyFiltersAndSorting(filterGroup, allProjects);
    updateProjectsState(result);
  };

  const applyFiltersAndSorting = (filterGroup, projectList = []) => {
    if (!projectList.length) return []; // no need to sort an empty list :)
    const filteredByStatus = applyStatusFilter(projectList, filterGroup);
    let result = addOrRemoveUnresponsive(projectList, filteredByStatus, filterGroup, role);
    return applyTimeRangeAndOrderBy(result, filterGroup, AUDIT_FILTER_ORDER_BY_MAP);
  };

  /****************
   * EFFECT HOOKS
   ***************/

  /**
   * Main effect hook: fetches & filters/sorts project data
   * Also runs when batched data needs to be sorted together
   */
  useEffect(() => {
    if (!advancedSearch || !allDataFetched) return;
    void getProjectCountByStatus();
    console.group("advancedSearch changed or data fetched");
    console.log("New advancedSearch:");
    console.log(advancedSearch);
    console.log("Previous advancedSearch:");
    console.log(previousAdvancedSearch);
    if (shouldSortProjectsWithoutHttpCall) {
      // don't trigger fetch if the new advanced search filters/sort are a subset of the existing filters/sort
      projects?.all?.length &&
        applyFilterGroupAndUpdateProjectsState(
          forAuditOverview ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND : advancedSearch
        );
      console.groupEnd();
    } else {
      console.info("Requires larger dataset - fetching...");
      void getProjects();
    }
    setPreviousAdvancedSearch(advancedSearch); // set state as previous so we can compare it with future advancedSearch state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.userInfo, tableRefreshSymbol, advancedSearch]);

  useEffect(() => {
    if (role != null) {
      const targetDefaultState = UNRESPONSIVE_STATUS_ROLE_WHITELIST.includes(role)
        ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND
        : DEFAULT_STATE_FOR_BACKEND;

      const persistentFilter = parseLocalStorageItemIntoJson(
        withLastComment ? ADMIN_PROJECT_ADVANCED_SEARCH : AUDIT_ADVANCED_SEARCH
      );
      if (persistentFilter) {
        // if filter found in local storage on first render, apply it
        const filterGroup = {
          statusList: Object.keys(persistentFilter.projectStatus).filter(
            (status) => status !== "all" && persistentFilter.projectStatus[status] === true
          ),
          timeRange: persistentFilter.timeRange,
          orderBy: persistentFilter.orderBy,
        };
        setAdvancedSearch(filterGroup);
        setPreviousAdvancedSearch(filterGroup);
      } else {
        setAdvancedSearch(targetDefaultState);
        setPreviousAdvancedSearch(targetDefaultState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const state = {
    selectedStatus,
    projects,
    isLoading, // first batch is loading
    certikTeams,
    tenants,
    searchQuery,
    hasNewProject,
    countLoading,
    countByStatusMap,
    allDataFetched, // second batch has not arrived yet - starts awaiting at beginning of first batch
  };

  const reducers = {
    setSelectedStatus,
    setProjects,
    setIsLoading,
    setCertikTeams,
    setTenants,
    getProjects,
    getProjectCountByStatus,
    setSearchQuery,
    setHasNewProject,
    setAdvancedSearch,
  };

  return <ProjectContext.Provider value={[state, reducers]}>{children}</ProjectContext.Provider>;
}
