import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";

const ReactQuill = typeof window === "object" ? require("react-quill") : () => false;
import {
  cls,
  trimRichWhitespace,
  hasNonEnglishCharacters,
  allowedCharsForMentionList,
  highlightingIllegalCharacters,
  handleImgsInInput,
  handleInvisibleCharInInput,
} from "../../utils/frontend/utils";
import styles from "./CommentEditor.module.css";
import styled from "styled-components";
import {
  SendIcon28,
  SendIconFocused28,
  EmojiIcon,
  OnlineIcon,
  OfflineIcon,
} from "../SharedComponents/Icons";
import { Space } from "../SharedComponents/Space";
import { message } from "../SharedComponents/message";
import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip, Row, Col, Popover } from "antd";
import { COLORS, COMMENT_FORMAT_WHITELIST, COMMENT_SOURCE_MODULE } from "../../const";
import { USER_STATUS, USER_STATUS_LABEL_MAP } from "../../const/user";
import { withTranslation } from "react-i18next";
import { CommentSuggestionList } from "./CommentSuggestionList";
import { renderToStaticMarkup } from "react-dom/server";

export const USER_STATUS_ICON_MAP = {
  [USER_STATUS.ONLINE]: <OnlineIcon />,
  [USER_STATUS.OFFLINE]: <OfflineIcon />,
};
const ToolbarWrapper = styled.div.attrs({
  id: "toolbar",
})`
  background-color: #ffffff;
  padding: 8px 12px 8px 4px !important;
  border: none !important;
  border-radius: 2px;
`;

const ToolRow = styled.span`
  float: left;
`;

const SendButton = styled.span`
  float: right;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: ${(props) => (props.disabled ? "var(--gray-500)" : "var(--gray-800)")};
  cursor: ${(props) => (props.disabled || props.$loading ? "not-allowed" : "pointer")};
`;

const TextEditor = styled.div.attrs({
  id: "text-editor",
})`
  & > .quill > .ql-container.ql-snow {
    border: none !important;
    border-radius: 2px;
    max-height: 200px;
  }

  & > .quill > .ql-container.ql-snow > .ql-editor {
    max-height: 200px;
    overflow-y: auto;
  }

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  border: 1px solid var(--gray-300);
  border-radius: 2px;

  &:focus-within {
    box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75);
    border-color: var(--gray-800);
  }
`;

if (typeof window === "object") {
  require("quill-mention");
}

const EmojiButton = ({ addEmoji }) => {
  const [hover, setHover] = useState(false);

  const PopoverContent = (
    <Row style={{ fontSize: 18 }} gutter={8}>
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u2705");
          }}
        >
          &#x2705;
        </button>
      </Col>{" "}
      {/* checkmark */}
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u{1F64F}");
          }}
        >
          &#x1F64F;
        </button>
      </Col>{" "}
      {/* prayer */}
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u{1F604}");
          }}
        >
          &#x1F604;
        </button>
      </Col>{" "}
      {/* happy */}
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u{1F61E}");
          }}
        >
          &#x1F61E;
        </button>
      </Col>{" "}
      {/* sad */}
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u{1F44D}");
          }}
        >
          &#x1F44D;
        </button>
      </Col>{" "}
      {/* thumbsUp */}
      <Col>
        <button
          onClick={(_e) => {
            addEmoji("\u{1F44E}");
          }}
        >
          &#x1F44E;
        </button>
      </Col>{" "}
      {/* thumbsDown */}
    </Row>
  );

  return (
    <Popover content={PopoverContent} trigger="click" overlayClassName={cls(styles, ["popover"])}>
      <button
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <EmojiIcon color={hover ? "#0066cc" : "#444444"} />
      </button>
    </Popover>
  );
};

const CustomToolbar = ({
  onSend,
  disabled,
  loading,
  t,
  hasNonEnglishCharacters,
  addEmoji,
  hideSendButton = false,
}) => {
  return (
    <ToolbarWrapper>
      <ToolRow>
        <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
          <option></option>
        </select>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
          <EmojiButton addEmoji={addEmoji} />
        </span>
      </ToolRow>
      {!hideSendButton && (
        <Tooltip
          title={
            hasNonEnglishCharacters
              ? t("components.Comment.CommentEditor.onlyEnglishCharacters")
              : ""
          }
          overlayStyle={{ width: "fit-content", maxWidth: "300px" }}
          placement={"topRight"}
        >
          <SendButton
            onClick={() => !disabled && !loading && onSend()}
            disabled={disabled}
            $loading={loading}
            data-testid="comment-send-button"
          >
            {loading ? (
              <LoadingOutlined />
            ) : !disabled ? (
              <SendIconFocused28 color={COLORS["gray-800"]} size={28} />
            ) : (
              <SendIcon28 color={COLORS["gray-400"]} size={28} />
            )}
          </SendButton>
        </Tooltip>
      )}
    </ToolbarWrapper>
  );
};

const MentionListItemWithStatus = (item) => {
  return renderToStaticMarkup(
    <Row gutter={16}>
      <Col>{item.value}</Col>
      <Col>
        <Row>
          {USER_STATUS_ICON_MAP[item.status]}
          {USER_STATUS_LABEL_MAP[item.status]}
        </Row>
      </Col>
    </Row>
  );
};

class CommentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.reactQuillRef = null;
    this.commentCache = "";
    this.state = {
      refresher: 1,
    };
    this.modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          image: this.props.onPickImage,
        },
      },
      mention: {
        allowedChars: new RegExp(`^[${allowedCharsForMentionList}]*$`),
        mentionDenotationChars: ["@"],
        // spaceAfterInsert: true,
        source: function (searchTerm, renderList, mentionChar) {
          let values;
          if (mentionChar === "@") {
            values = props.atValues;
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values?.length; i++)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        renderItem: (item, _searchTerm) => {
          if (item.status != null) {
            return MentionListItemWithStatus(item);
          } else {
            return `<span>${item.value}</span>`;
          }
        },
      },
    };
  }

  onSubmit = (sourceComment) => {
    let userIds = [];
    let quill = this.reactQuillRef.getEditor();
    const quillContent = quill.getContents();
    for (let content of quillContent.ops) {
      if (content.insert.mention) {
        userIds.push(content.insert.mention.id);
      }
      if (content.insert.image) {
        message.error(
          "Images can't be pasted into message text. Please use the attachment tool instead."
        );
        return;
      }
    }
    this.props.makeComment(userIds, sourceComment);
  };

  addEmoji = (emoji) => {
    const quill = this.reactQuillRef.getEditor();
    quill.insertText(quill.getSelection(true).index, emoji);
  };

  render() {
    const {
      t,
      onChange,
      value,
      disable,
      numAttachments,
      loading,
      suggestions,
      onClickSuggestion,
      hideSendButton,
    } = this.props;

    return (
      <Space direction="vertical" size={8} width="100%">
        <Tooltip
          visible={hasNonEnglishCharacters(this.commentCache)}
          title={t("components.Comment.CommentEditor.markInvalidCharacters")}
          overlayStyle={{ width: "fit-content", maxWidth: "350px" }}
          placement={"topLeft"}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <TextEditor>
            <div data-cy="commentEditorQuill">
              <ReactQuill
                placeholder={t(
                  // TODO: store the lable info into a mapping
                  this.props.sourceModule === COMMENT_SOURCE_MODULE.REPORT
                    ? this.props.hasNotifyButton === false
                      ? "components.Comment.CommentEditor.reportAddComment"
                      : "components.Comment.CommentEditor.reportAddCommentWithNotfiyButton"
                    : "components.Comment.CommentEditor.defaultAddComment"
                )}
                theme="snow"
                onChange={(e) => {
                  const { reformattedInput, imgSrcs } = handleImgsInInput(e);
                  if (imgSrcs?.length > 0) {
                    // Possible future impl: converting imgSrcs into attachment
                    message.error(
                      "Images can't be dragged and dropped into message text. Please use the attachment tool instead."
                    );
                    // Set text of quill to the new input to force an update in the component
                    if (this.reactQuillRef) {
                      const quill = this.reactQuillRef.getEditor();
                      quill.setText(reformattedInput);
                    }
                    onChange(reformattedInput);
                  } else {
                    // Remove invisible char
                    const reformattedInput = handleInvisibleCharInInput(e);
                    // To avoid endless loop, we must first invoke onChange()
                    // and then invoke highlightingIllegalCharacters(),
                    // we need to use commentCache as well.
                    onChange(reformattedInput);
                    if (this.reactQuillRef) {
                      let comment = "";
                      const quill = this.reactQuillRef.getEditor();
                      const quillContent = quill.getContents();
                      for (let content of quillContent.ops) {
                        if (!content.insert.mention) {
                          comment += content.insert;
                        }
                      }
                      if (this.commentCache !== comment) {
                        this.commentCache = comment;
                        highlightingIllegalCharacters(quill);
                        // Update state to force the component re-render
                        this.setState({ refresher: this.state.refresher + 1 });
                      }
                    }
                  }
                }}
                value={value}
                modules={this.modules}
                ref={(node) => (this.reactQuillRef = node)}
                formats={COMMENT_FORMAT_WHITELIST}
              />
            </div>
            <CustomToolbar
              onSend={this.onSubmit}
              t={t}
              hasNonEnglishCharacters={hasNonEnglishCharacters(this.commentCache)}
              disabled={
                disable ||
                hasNonEnglishCharacters(this.commentCache) ||
                (trimRichWhitespace(value) === "" && numAttachments === 0)
              }
              loading={loading}
              addEmoji={this.addEmoji}
              hideSendButton={hideSendButton}
            />
          </TextEditor>
        </Tooltip>
        <CommentSuggestionList
          suggestions={suggestions}
          onClick={(suggestion) => {
            if (!this.reactQuillRef) return;
            // get the quill instance
            const quill = this.reactQuillRef.getEditor();
            // set the editor text
            quill.setText(suggestion);
            // put cursor to end of text
            quill.setSelection(quill.getLength());
            // focus the editor
            this.reactQuillRef.focus();
            // run callback if it was supplied
            if (typeof onClickSuggestion === "function") {
              onClickSuggestion(suggestion);
            }
          }}
          onSend={this.onSubmit}
        />
      </Space>
    );
  }
}

export default withTranslation()(CommentEditor);
