import React, { useState, useEffect } from "react";
import { Row, Col, Input, Typography } from "antd";
import { useStore } from "../../hooks/useStore";
import {
  fetchUserActionsByProjectId,
  fetchUserActionsByTenantId,
} from "../../utils/frontend/fetchFromApi";
import { Paragraph } from "./Text";
import { Table } from "./Table";
import { COLORS } from "../../const/colors";
import { Box } from "./Box";
import { SearchOutlined } from "@ant-design/icons";
import SkeletonTable from "./SkeletonTable";
const { Text } = Typography;
import { formatTimestampWithTime } from "../../utils/common/format";
import { Dialog } from "./Dialog";
import { Space } from "./Space";
import { Title } from "./Typography";
import { ACTION_OPTIONS } from "../../const/actionLog";
import { FEATURE_MAP } from "../../const/feature";
import { customerServiceIdAdapter } from "../../utils/frontend/utils";

const ACTION_LOG_TEMPLATE_MAP = {
  accelerator: {
    [ACTION_OPTIONS["accelerator"].PUBLISH_REPORT]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              The report {row?.meta?.reportVersion} is published, AccID:{" "}
              {row?.meta?.acceleratorProjectId}
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `The report ${row?.meta?.reportVersion} is published, AccID: ${row?.meta?.acceleratorProjectId}`;
      },
    },
  },
  // "audit drawer": {
  //   "click save button in project accounts tab": async (blob, exceptionBlobList) => {
  //   },
  //   "click save button in project detail tab": async (blob, exceptionBlobList) => {
  //   },
  // },
  "audit drawer cross sale": {
    [ACTION_OPTIONS["audit drawer cross sale"].PUBLISH_REPORT]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              {/* Before Sep 2, 2022, the reportFileName was not recorded in meta */}
              {row?.meta?.reportFileName ? row.meta.reportFileName : "Report"} is published in
              skyharbor
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row.meta.reportFileName} Report is published in skyharbor`;
      },
    },
  },
  "audit drawer overview": {
    [ACTION_OPTIONS["audit drawer overview"].FILL_ACCOUNT_INFO]: {
      Component: function () {
        return (
          <>
            <Row>Client filled the account information</Row>
          </>
        );
      },
      SearchString: function () {
        return `Client filled the account information`;
      },
    },
    [ACTION_OPTIONS["audit drawer overview"].SIGN_OFF]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              {row?.meta?.publish
                ? "Client signed-off report for publishing"
                : "Client signed-off report, but not for publishing"}
            </Row>
          </>
        );
      },
      SearchString: function (_row) {
        return `Client signed-off report for publishing , but not for publishing `;
      },
    },
    [ACTION_OPTIONS["audit drawer overview"].CLICK_ENABLED]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Text code>{row?.userId}</Text> {row?.meta?.checked ? "enabled" : "disabled"} Emblem
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} ${row?.meta?.checked ? "enabled" : "disabled"} Emblem`;
      },
    },
    //   "click checkbox": async (blob, exceptionBlobList) => {
    //   },
    //   "click sign off button": async (blob, exceptionBlobList) => {
    //   },
    //   "click unpublish button": async (blob, exceptionBlobList) => {
    //   },
  },
  "audit report": {
    // TODO: remove "click Share button", this one will be backfilled and updated to "click share button", also tooltip is WIP
    [ACTION_OPTIONS["audit report"].SHARE]: {
      Component: function () {
        return (
          <>
            <Row>Share report link generated</Row>
          </>
        );
      },
      SearchString: function (_row) {
        return `Share report link generated`;
      },
    },
    [ACTION_OPTIONS["audit report"].SHARE_2]: {
      Component: function () {
        return (
          <>
            <Row>Share report link generated</Row>
          </>
        );
      },
      SearchString: function (_row) {
        return `Share report link generated`;
      },
    },
    [ACTION_OPTIONS["audit report"].DOWNLOAD_PDF]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Text code>{row?.userId}</Text> downloaded report
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} downloaded report`;
      },
    },
    [ACTION_OPTIONS["audit report"].EXPORT_COMMENTS]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Text code>{row?.userId}</Text> exported comments
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} exported comments`;
      },
    },
    [ACTION_OPTIONS["audit report"].NOTIFY_CERTIK]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Text code>{row?.userId}</Text> clicked &quot;Notify CertiK&quot; for review
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} clicked &quot;Notify CertiK&quot; for review`;
      },
    },
  },
  // Hack for front-end rendering becasue we didn't backfill trace table userId from
  // "superadmin@certik.org" to "customer.service.skyharbor@certik.com"
  organization: {
    [ACTION_OPTIONS["organization"].INVITE_USER]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{customerServiceIdAdapter(row?.meta?.inviterId)}</Text> invited new user{" "}
                <Text code>{row?.userId}</Text>to {row?.tenantName}
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${customerServiceIdAdapter(row?.meta?.inviterId)} invited new user ${
          row?.userId
        } to ${row?.tenantName}`;
      },
    },

    [ACTION_OPTIONS["organization"].TOGGLE_FEATURE]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text>{" "}
                {row?.meta?.enabled === true ? "enabled" : "disabled"} feature{" "}
                <Text code>{FEATURE_MAP[row?.meta?.feature]?.name || row?.meta?.feature}</Text>
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} ${row?.meta?.enabled === true ? "enabled" : "disabled"} feature ${
          FEATURE_MAP[row?.meta?.feature]?.name || row?.meta?.feature
        }`;
      },
    },
    [ACTION_OPTIONS["organization"].TOGGLE_ALL_FEATURES]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text>{" "}
                {row?.meta?.enabled === true ? "enabled" : "disabled"} all features
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} ${
          row?.meta?.enabled === true ? "enabled" : "disabled"
        } feature all features`;
      },
    },
    [ACTION_OPTIONS["organization"].UPDATE_TENANT_DETAIL]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text> updated {row?.tenantName}
                {`'`}s {row?.meta?.field}
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId}  updated ${row?.tenantName}'s ${row?.meta?.field}`;
      },
    },

    [ACTION_OPTIONS["organization"].CREATE_API_KEY]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text> created API Key{" "}
                <Text code>{row?.meta?.apiKeyName}</Text>
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} created API Key ${row?.meta?.apiKeyName}`;
      },
    },
    [ACTION_OPTIONS["organization"].EDIT_API_KEY]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text> updated API Key name to{" "}
                {row?.meta?.apiKeyName && <Text code>{row?.meta?.apiKeyName}</Text>}
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} updated API Key name to  ${row?.meta?.apiKeyName}`;
      },
    },

    [ACTION_OPTIONS["organization"].DELETE_API_KEY]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.userId}</Text> deleted API Key{" "}
                {row?.meta?.apiKeyName && <Text code>{row?.meta?.apiKeyName}</Text>}
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.userId} deleted API Key ${row?.meta?.apiKeyName}`;
      },
    },
  },
  signup: {
    [ACTION_OPTIONS["signup"].SIGNUP_RESPONSE]: {
      Component: function ({ row }) {
        return (
          <>
            <Row>
              <Col>
                <Text code>{row?.meta?.email}</Text> has accepted the invitation to{" "}
                {row?.tenantName}
              </Col>
            </Row>
          </>
        );
      },
      SearchString: function (row) {
        return `${row?.meta?.email} has accepted the invitation to ${row?.tenantName}`;
      },
    },
  },
};

export default function ActivityTableModal({
  visible,
  onCancel,
  projectId,
  tenantId,
  page = "project",
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [projectActions, setProjectActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [store] = useStore();

  const columns = [
    {
      title: "TIME",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend"],
      sorter: (a, b) => b?.createdAt - a?.createdAt,
      render: (_, row) => (
        <div name="date-col">
          <Paragraph level={2}>{formatTimestampWithTime(row.createdAt)}</Paragraph>
        </div>
      ),
    },
    {
      title: "ACTIVITY",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        const isExistActionComponent =
          row?.module in ACTION_LOG_TEMPLATE_MAP &&
          row?.action in ACTION_LOG_TEMPLATE_MAP[row.module];
        const ActionComponent =
          isExistActionComponent && ACTION_LOG_TEMPLATE_MAP[row.module][row.action].Component;
        return (
          <div name="activity-col">
            {ActionComponent && <ActionComponent row={row}></ActionComponent>}
          </div>
        );
      },
    },
  ];

  const filter = (dataSource) => {
    return dataSource.filter((item) =>
      item?._searchString?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
  };

  const backfillSearchString = (dataSource) => {
    if (!dataSource) {
      return [];
    }
    return dataSource.map((row) => {
      const resp = row;
      if (ACTION_LOG_TEMPLATE_MAP[row?.module]?.[row?.action]?.SearchString(row)) {
        resp["_searchString"] = ACTION_LOG_TEMPLATE_MAP[row.module][row.action].SearchString(row);
      }
      // console.log(resp["_searchString"]);
      return resp;
    });
  };
  useEffect(() => {
    const loadActions = async () => {
      console.log("loading!");
      setIsLoading(true);
      let resp = [];
      if (page === "project" && projectId) {
        resp = await fetchUserActionsByProjectId(
          projectId,
          store?.userInfo?.userId,
          store?.userInfo?.authData
        );
      }
      if (page === "organization" && tenantId) {
        resp = await fetchUserActionsByTenantId(
          tenantId,
          store?.userInfo?.userId,
          store?.userInfo?.authData
        );
      }
      console.log("resp", backfillSearchString(resp));
      setProjectActions(backfillSearchString(resp));
      setIsLoading(false);
    };
    store.userInfo && visible && loadActions();
  }, [store.userInfo, projectId, visible]);
  return (
    <Dialog visible={visible} onCancel={onCancel} width={"1024px"} footer={null}>
      <Space direction="vertical" $fillWidth style={{ margin: "24px 0 8px 0" }}>
        <Title level={1} color="gray-800" size={24}>
          View Activity History
        </Title>
        <Box mt={8} mb={16}>
          <Row>
            <Col span={10}>
              <Input
                placeholder={"Search"}
                prefix={<SearchOutlined style={{ color: COLORS["gray-6"] }} />}
                onChange={(e) => setSearchQuery(e.target.value)}
              ></Input>
            </Col>
          </Row>
        </Box>
        <Box>
          <Row>
            <Col span={24}>
              {isLoading ? (
                <SkeletonTable rowCount={5} columns={columns}></SkeletonTable>
              ) : (
                <Table columns={columns} dataSource={filter(projectActions)} />
              )}
            </Col>
          </Row>
        </Box>
      </Space>
    </Dialog>
  );
}
