/** The format we can expect the chain's data to be in. EVM is our original ETH format.
 * This is used in the PLATFORMS `dataFormat` value to help determine things like which
 * transaction table to display.
 */
export enum CHAIN_DATA_FORMAT {
  EVM = "evm",
  BTC = "btc",
}

interface Platform {
  name: string;
  fullName: string;
  ticker: string;
  auditSupport: boolean;
  riskInspectorSupport: boolean;
  dataFormat?: CHAIN_DATA_FORMAT;
  validatorRules?: {
    address?: {
      baseRegExp?: RegExp;
      regExp?: string;
      placeholder?: string;
      caseSensitive?: boolean;
    };
    tx?: {
      regExp?: string;
      placeholder?: string;
    };
    prefix?: {
      regExp?: string;
      placeholder?: string;
    };
  };
  // Audit stuff:
  txUrlPrefix?: string;
  addressUrlPrefix?: string;
}

interface PlatformKV {
  [uppercaseChain: string]: Platform;
}
export const PLATFORMS: PlatformKV = {
  ARBITRUM: {
    name: "Arbitrum",
    fullName: "Arbitrum",
    ticker: "ARB",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://arbiscan.io/tx",
    addressUrlPrefix: "https://arbiscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(arbiscan.io)/",
        placeholder: "https://arbiscan.io/...",
      },
      tx: {
        regExp: "^https://(arbiscan.io)",
        placeholder: "https://arbiscan.io/...",
      },
      prefix: {
        regExp: "^https://(arbiscan.io)",
        placeholder: "https://arbiscan.io/...",
      },
    },
  },
  AVALANCHE: {
    name: "Avalanche",
    fullName: "Avalanche",
    ticker: "AVAX",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://www.avax.network/tx", // TODO:
    addressUrlPrefix: "https://avascan.info/blockchain/all/address",
    validatorRules: {
      address: {
        regExp: "^https://",
        placeholder: "https://...",
      },
      tx: {
        regExp: "^https://",
        placeholder: "https://...",
      },
      prefix: {
        regExp: "^https://",
        placeholder: "https://...",
      },
    },
  },
  BSC: {
    name: "BNB Chain",
    fullName: "BNB Chain",
    ticker: "BNB",
    auditSupport: true,
    riskInspectorSupport: true,
    txUrlPrefix: "https://bscscan.com/tx",
    addressUrlPrefix: "https://bscscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(bscscan.com)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://bscscan.com/address/...",
      },
      tx: {
        regExp: "^https://(bscscan.com)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://bscscan.com/tx/...",
      },
      prefix: {
        regExp: "^https://(bscscan.com/",
        placeholder: "https://bscscan.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  CRONOS: {
    name: "Cronos",
    fullName: "Cronos",
    ticker: "CRO",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://cronoscan.com/tx",
    addressUrlPrefix: "https://cronoscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
      tx: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
      prefix: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
    },
  },
  ETH: {
    name: "ETH",
    fullName: "Ethereum",
    ticker: "ETH",
    auditSupport: true,
    riskInspectorSupport: true,
    txUrlPrefix: "https://etherscan.io/tx",
    addressUrlPrefix: "https://etherscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(etherscan.io)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://etherscan.io/address/0x...",
      },
      tx: {
        regExp: "^https://(etherscan.io)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://etherscan.io/tx/...",
      },
      prefix: {
        regExp: "^https://(etherscan.io)/",
        placeholder: "https://etherscan.io/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  BTC: {
    name: "BTC",
    fullName: "Bitcoin",
    ticker: "BTC",
    auditSupport: false,
    riskInspectorSupport: true,
    txUrlPrefix: "https://www.blockchain.com/explorer/transactions/btc",
    addressUrlPrefix: "https://www.blockchain.com/explorer/addresses/btc",
    validatorRules: {
      address: {
        baseRegExp: /^([13][a-km-zA-HJ-NP-Z1-9]{25,34})|([bB][cC]1[a-zA-Z0-9]{11,71})$/,
        regExp:
          "^https://(blockchain.com)/explorer/addresses/btc/(([13][a-km-zA-HJ-NP-Z1-9]{25,34})|[bB][cC]1[a-zA-Z0-9]{11,71}))$", // https://en.bitcoin.it/wiki/Invoice_address, https://en.bitcoin.it/wiki/BIP_0173#Segwit_address_format
        placeholder: "https://blockchain.com/explorer/addresses/btc/...",
        caseSensitive: true,
      },
      tx: {
        regExp: "^https://(blockchain.com)/explorer/transactions/btc/[0-9a-zA-Z]{0,}$",
        placeholder: "https://blockchain.com/explorer/transactions/btc/...",
      },
      prefix: {
        regExp: "^https://(blockchain.com)/",
        placeholder: "https://blockchain.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.BTC,
  },
  FANTOM: {
    name: "Fantom",
    fullName: "Fantom",
    ticker: "FTM",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://ftmscan.com/tx",
    addressUrlPrefix: "https://ftmscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
      tx: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
      prefix: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
    },
  },
  OPTIMISM: {
    name: "Optimism",
    fullName: "Optimism",
    ticker: "OP",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://optimistic.etherscan.io/tx",
    addressUrlPrefix: "https://optimistic.etherscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
      tx: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
      prefix: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
    },
  },
  POLYGON: {
    name: "Polygon",
    fullName: "Polygon",
    ticker: "MATIC",
    auditSupport: true,
    riskInspectorSupport: true,
    txUrlPrefix: "https://polygonscan.com/tx",
    addressUrlPrefix: "https://polygonscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(polygonscan.com)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://polygonscan.com/address/0x...",
      },
      tx: {
        regExp: "^https://(polygonscan.com)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://polygonscan.com/tx/0x...",
      },
      prefix: {
        regExp: "^https://(polygonscan.com)/",
        placeholder: "https://polygonscan.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  SOLANA: {
    name: "Solana",
    fullName: "Solana",
    ticker: "SOL",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://explorer.solana.com/tx",
    addressUrlPrefix: "https://explorer.solana.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
        caseSensitive: true,
      },
      tx: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
      },
      prefix: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
      },
    },
  },
  TERRA: {
    name: "Terra",
    fullName: "Terra",
    ticker: "LUNA",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://finder.terra.money/tx",
    addressUrlPrefix: "https://finder.terra.money/address",
    validatorRules: {
      address: {
        regExp: "^https://(finder.terra.money)/mainnet/address/",
        placeholder: "https://finder.terra.money/mainnet/address/...",
      },
      tx: {
        regExp: "^https://(finder.terra.money)/mainnet/tx/",
        placeholder: "https://finder.terra.money/mainnet/tx/...",
      },
      prefix: {
        regExp: "^https://(finder.terra.money)/mainnet/",
        placeholder: "https://finder.terra.money/mainnet/...",
      },
    },
  },
  FILECOIN: {
    name: "Filecoin",
    fullName: "Filecoin",
    ticker: "FIL",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "https://filfox.info/",
    addressUrlPrefix: "https://filfox.info/",
    validatorRules: {
      address: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
      tx: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
      prefix: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
    },
  },
} as const;

// TODO: support other option
export const PLATFORM_OTHER: PlatformKV = {
  OTHER: {
    name: "Other",
    fullName: "Other",
    ticker: "",
    auditSupport: true,
    riskInspectorSupport: false,
    txUrlPrefix: "",
    addressUrlPrefix: "",
    validatorRules: {
      address: {
        regExp: "[sS]*",
        placeholder: "",
      },
      tx: {
        regExp: "[sS]*",
        placeholder: "",
      },
      prefix: {
        regExp: "[sS]*",
        placeholder: "",
      },
    },
  },
};
