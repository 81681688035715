export enum SIDEBAR_KEYS_CONFIG {
  DASHBOARD = "1",
  AUDITS = "2",
  AUDITS_OVERVIEW = "2a",
  AUDITS_PROJECTS = "2b",
  AUDITS_REFERRAL_PROJECTS = "2c",
  SECURITY_SCORE = "security_score",
  INVOICES = "4",
  RISK_MANAGER = "7",
  RISK_OVERVIEW = "7a",
  RISK_INSPECTOR = "7b",
  RISK_MONITORING_GROUP_MANAGER = "7c",
  RISK_BLACKLISTS = "7d",
  RISK_BOOKMARKS = "7e",
  RISK_ALERTING_RULES = "7f",
  RISK_AUDITTRAILS = "7g",
  RISK_INVESTIGATION_CASES = "7h",
  COLLABORATOR = "11",
  ACCOUNT_PERSONAL_INFO = "13",
  ADMIN_TOOLS = "15",
  ADMIN_USERS = "15a",
  ADMIN_PROJECTS = "15b",
  ADMIN_ORGANIZATIONS = "15c",
  ORGANIZATION = "18",
  MARKETPLACE = "19",
  MARKETPLACE_AUDITED_PROJECTS = "19a",
  MARKETPLACE_INTEREST_TRACKER = "19b",
}
