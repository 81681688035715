import type { SankeyConfig } from "@ant-design/plots";
import { Plot } from "@ant-design/plots";
import type { SankeyOptions } from "@antv/g2plot";
import { Row } from "antd";
import { sum } from "lodash";
import dynamic from "next/dynamic";
import { ReactNode, useCallback, useMemo } from "react";
import { COLORS } from "../../../const";
import Skeleton from "../Skeleton";
import Paragraph from "../Text";
import { TooltipCard } from "../Tooltip";

// Dynamic import needed - see https://github.com/ant-design/ant-design-charts/issues/1802
const Sankey = dynamic<SankeyConfig>(
  () => import("@ant-design/plots").then((plots) => plots.Sankey),
  {
    ssr: false,
  }
);

// Consts & helpers

const SEP = "#";
const IN_SUFFIX = "INFLOW";
const OUT_SUFFIX = "OUTFLOW";
const TARGET_LABEL = "target";

export type OnClickCounterpartyEdge = (
  keyName: string,
  direction?: typeof IN_SUFFIX | typeof OUT_SUFFIX
) => void;

const sankeyPadding = [0, -7, 0]; // top, right, bottom, left; -7 makes it flush with edge of canvas

const toRawFieldName = (nameWithSuffix) => nameWithSuffix?.split("#")[0];
function getValue(fieldMap, fieldName, valueName) {
  return fieldMap[toRawFieldName(fieldName)][valueName];
}

/**
 * Reorders an object based on the order of a specified keyArray.
 * Keys not found in keyArray will be tacked on the end.
 */
function reorderData(data: object, keyArray: string[]) {
  const dataCopy = { ...data }; // Make a copy so deleting doesn't affect the original data
  const reorderedData = {};

  (keyArray || []).forEach((key) => {
    // Go through keyArray in order
    if (dataCopy[key]) {
      // If the data obj has a matching key, add it into the result object
      reorderedData[key] = dataCopy[key];
      delete dataCopy[key];
    }
  });
  return { ...reorderedData, ...dataCopy }; // Add any properties not listed in keyArray onto the end
}

// =====================
//         MAIN
// =====================

interface SankeyProps extends Partial<SankeyConfig> {
  /** Inflow object keys represent the names of the source entities */
  inflow: { [source: string]: number };
  /** Outflow object keys represent the names of the destination entities */
  outflow: { [destination: string]: number };
  /** Maps the entites to their colors & labels */
  fieldMap: { [entity: string]: { label: string; color: string } };
  /** If you want to ensure the chart displays fields in a specific order, specify a list of data keys in the order you want to use */
  specifyFieldOrder?: string[];
  height?: number;
  /** Click handler for clicking on edge (the flow between nodes) */
  onClickEdge?: OnClickCounterpartyEdge;
  /** Caption that can be displayed at bottom of tooltip */
  tooltipCaption?: string | ReactNode | ((label, direction) => ReactNode | string);
  /** Format the number value for display; e.g. decimal to percent */
  formatValue?: (n: number) => string;
  loading?: boolean;
}
/**
 * A sankey chart that displays inflow and outflow to/from a target entity.
 * Note: if labels are being hidden, try increasing the height
 */
export default function InflowOutflowSankey({
  inflow,
  outflow,
  fieldMap,
  specifyFieldOrder,
  tooltipCaption,
  onClickEdge,
  formatValue = (n) => String(n),
  style,
  height,
  loading = false,
  ...props
}: SankeyProps) {
  const inflowEmpty = useMemo(() => sum(Object.values(inflow)) === 0, [inflow]);
  const outflowEmpty = useMemo(() => sum(Object.values(outflow)) === 0, [outflow]);

  // The diagram doesn't like when you use the same name twice (e.g. Bob -> Target -> Bob), so we split the data into inflow/outflow and append a suffix to differentiate them
  // Inflow: { Bob: 5 }; Outflow: { Bob: 3 } --> [{ source: Bob#INFLOW, target: target, value: 5 }, { source: target, target: Bob#OUTFLOW, value: 3 }]
  const data = useMemo(() => {
    const result = [];
    if (!inflow || !outflow) return result;

    // Reorder data to ensure chart has the same order (optional)
    const inflowOrdered = specifyFieldOrder?.length
      ? reorderData(inflow, specifyFieldOrder)
      : inflow;
    const outflowOrdered = specifyFieldOrder?.length
      ? reorderData(outflow, specifyFieldOrder)
      : outflow;

    Object.entries(inflowOrdered || {}).forEach(([source, value]) => {
      result.push({
        source: `${source}${SEP}${IN_SUFFIX}`,
        target: TARGET_LABEL,
        value: value ?? 0,
      });
    });
    Object.entries(outflowOrdered || {}).forEach(([destination, value]) => {
      result.push({
        source: TARGET_LABEL,
        target: `${destination}${SEP}${OUT_SUFFIX}`,
        value: value ?? 0,
      });
    });
    console.info("sankey data", result);
    return result;
  }, [inflow, outflow, specifyFieldOrder]);

  // Formats chart labels for display
  const label = useMemo(
    () => ({
      callback: (_, name: string) => {
        if (name === TARGET_LABEL) return { content: null }; // Don't label target (middle) node
        const isOutflow = name?.includes(OUT_SUFFIX);
        const value =
          data?.find((item) => item.source === name || item.target === name)?.value || 0;
        return {
          content: `${getValue(fieldMap, name, "label")} ${formatValue(value)}`,
          style: {
            fill: COLORS["gray-700"],
            fontSize: 12,
            lineHeight: 16,
            fontWeight: 500,
            textAlign: isOutflow ? "right" : "left",
            textBaseline: "middle",
            stroke: "#fff", // text outline
            lineOpacity: 0.5,
            lineWidth: 1,
            cursor: onClickEdge ? "pointer" : "default",
          },
          offsetX: isOutflow ? -20 : 20,
        };
      },
      layout: [],
    }),
    [data, fieldMap, formatValue, onClickEdge]
  );

  // Style of nodes (bars at each end)
  const nodeStyle = useCallback(
    ({ name }) => ({
      fill: getValue(fieldMap, name, "color"),
      stroke: getValue(fieldMap, name, "color"),
      lineWidth: 2,
    }),
    [fieldMap]
  );

  // Style of edges (the flowy stuff between nodes)
  const edgeStyle = useCallback(
    (node) => {
      const counterParty = node?.source === TARGET_LABEL ? node.target : node.source;
      const hasSecondaryColor = getValue(fieldMap, counterParty, "secondaryColor") != null;
      return {
        fill: getValue(fieldMap, counterParty, "color"),
        stroke:
          getValue(fieldMap, counterParty, "secondaryColor") ||
          getValue(fieldMap, counterParty, "color"),
        fillOpacity: hasSecondaryColor ? 1 : 0.35,
        strokeOpacity: hasSecondaryColor ? 1 : 0.35,
        lineWidth: 2,
        x: 100,
      };
    },
    [fieldMap]
  );

  // Node hover style
  const nodeState = useMemo(
    () => ({
      active: {
        style: (el) => ({
          fill: getValue(fieldMap, el.data?.name, "color"),
          stroke: getValue(fieldMap, el.data?.name, "color"),
          lineWidth: 2,
        }),
      },
    }),
    [fieldMap]
  );

  // Edge hover style
  const edgeState = useMemo(
    () => ({
      active: {
        style: (el) => {
          const node = el.data;
          const counterParty = node?.source === TARGET_LABEL ? node.target : node.source;
          const hasSecondaryColor = getValue(fieldMap, counterParty, "secondaryColor") != null;
          return {
            fill: getValue(fieldMap, counterParty, "color"),
            stroke: getValue(fieldMap, counterParty, "color"),
            lineWidth: 2,
            fillOpacity: hasSecondaryColor ? 0.5 : 0.55,
            strokeOpacity: 0.55,
            cursor: onClickEdge ? "pointer" : "default",
          };
        },
      },
    }),
    [fieldMap, onClickEdge]
  );

  // Tooltip component
  const tooltipConfig = useMemo(
    () => ({
      customContent(_, ttips: any[]) {
        if (!ttips.length) return [];
        const { data, value } = ttips[0];
        const nameToUse = data.source === TARGET_LABEL ? data.target : data.source;
        const color = getValue(fieldMap, nameToUse, "color");
        const secondaryColor = getValue(fieldMap, nameToUse, "secondaryColor") || color;
        const source = getValue(fieldMap, data.source, "label");
        const target = getValue(fieldMap, data.target, "label");
        const valueStr = formatValue(value);
        const direction = data?.source === TARGET_LABEL ? "outflow" : "inflow";
        return (
          <TooltipCard
            primaryColor={color}
            secondaryColor={secondaryColor}
            align={direction === "inflow" ? "left" : "right"}
          >
            <Paragraph level={3} med>
              <span style={{ ...(direction === "outflow" && { fontWeight: 600 }) }}>{source}</span>{" "}
              {"->"}
              <span style={{ ...(direction === "inflow" && { fontWeight: 600 }) }}>{target}</span>
            </Paragraph>
            <Paragraph level={3}>
              {valueStr} of {direction}
            </Paragraph>
            {tooltipCaption && (
              <Paragraph level={3} marginTop={4}>
                {typeof tooltipCaption === "function"
                  ? tooltipCaption(getValue(fieldMap, nameToUse, "label"), direction)
                  : tooltipCaption}
              </Paragraph>
            )}
          </TooltipCard>
        );
      },
    }),
    [fieldMap, formatValue, tooltipCaption]
  );

  // Initialize handler when loaded
  const onReady = useCallback(
    (plot: Plot<SankeyOptions>) => {
      plot.on("edge:click", ({ data }) => {
        const counterParty =
          data?.data?.source === TARGET_LABEL ? data?.data?.target : data?.data?.source;
        // Pass counterparty key name and whether it's INFLOW or OUTFLOW (see IN_SUFFIX, OUT_SUFFIX)
        onClickEdge?.(toRawFieldName(counterParty), counterParty?.split(SEP)[1]);
      });
      plot.on("label:click", ({ data }) => {
        onClickEdge?.(toRawFieldName(data?.data?.name), data?.data?.name?.split(SEP)[1]); // Same as above
      });
      // console.log(">> PLOT", plot); // for debugging :O)
    },
    [onClickEdge]
  );

  return (
    <div style={style}>
      <Row justify="space-between" style={{ paddingBottom: 3.35 }}>
        <Paragraph level={2} weight={600}>
          Inflow
        </Paragraph>
        <Paragraph level={2} weight={600}>
          Target
        </Paragraph>
        <Paragraph level={2} weight={600}>
          Outflow
        </Paragraph>
      </Row>
      <Skeleton type="paragraph" loading={loading} block height={height ? height : undefined}>
        <div style={{ height: height ? height : undefined }}>
          <div
            style={{
              padding: inflowEmpty ? "0px 0px 0px 50%" : outflowEmpty ? "0px 50% 0px 0px" : null,
            }}
          >
            {/* @ts-ignore */}
            <Sankey
              data={data}
              sourceField="source"
              targetField="target"
              weightField="value"
              label={label}
              nodeStyle={nodeStyle}
              nodeState={nodeState}
              nodeWidthRatio={0.01}
              nodePaddingRatio={0.1}
              padding={sankeyPadding}
              edgeStyle={edgeStyle}
              edgeState={edgeState}
              tooltip={tooltipConfig}
              onReady={onReady}
              height={height}
              {...props}
            />
          </div>
        </div>
      </Skeleton>
    </div>
  );
}
