export const BOTTLENECK_KEYS = {
  FINDING_COMMENT_GET: "findingCommentGet",
};

export const BOTTLENECK_CONFIG = {
  [BOTTLENECK_KEYS.FINDING_COMMENT_GET]: {
    maxConcurrent: 20,
    minTime: 300,
  },
};
