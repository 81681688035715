import { Component } from "react";
import { Result } from "antd";
import { Logger } from "../../utils/frontend/logger/browser-logger";
import { BUILD_VERSION_CONFIG } from "../../const";
import { safeParse } from "../../utils/frontend/utils";
import { localStore } from "../../utils/frontend/localStore";
import { datadogRum } from "@datadog/browser-rum";

// Error boundaries currently have to be classes.
export class ErrorBoundary extends Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, _errorInfo) {
    // Not log to ddog from dev env
    if (process.env.NEXT_PUBLIC_ENV === "development") {
      return;
    }
    datadogRum.addError(error);
    console.error("ErrorBoundary browser error:", error);

    const userId = localStorage.getItem("client-portal-user-id") || null;
    // Get current build version
    const localBuildVersionBlob = safeParse(localStore?.get(BUILD_VERSION_CONFIG));
    const localVersion = localBuildVersionBlob?.build?.value?.buildVersion;

    //send error to Datadog
    const errorContent = {
      body: {
        app: process.env.NEXT_PUBLIC_APP,
        label: "browser_error",
      },
      errorMessage: error.toString(),
      errorStack: error?.stack, // string
      userId,
      localVersion,
    };
    Logger.log(errorContent, {}, Logger.levels.ERROR);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Application Error"
          subTitle="Something went wrong, the SkyHarbor team has been notified of the error."
        />
      );
    }
    return this.props.children;
  }
}
