export enum LogLevels {
  ERROR = "error",
  DEBUG = "debug",
  WARN = "warn",
  INFO = "info",
}

export enum API_BROWSER_LOG_KEYS {
  API_REQUEST_SESSION_EXPIRED = "API_BROWSER_REQUEST_SESSION_EXPIRED",
  API_REQUEST_INFO = "API_BROWSER_REQUEST_INFO",
  API_REQUEST_ERROR_PARSE = "API_BROWSER_REQUEST_ERROR_PARSE",
  API_REQUEST_TIMEOUT = "API_BROWSER_REQUEST_TIMEOUT",
  API_REQUEST_ERROR_NOT_2XX = "API_BROWSER_REQUEST_ERROR_NOT_2XX",
  API_REQUEST_ERROR_401_FALLBACK = "API_BROWSER_REQUEST_ERROR_401_FALLBACK",
  API_REQUEST_ERROR_403_FALLBACK = "API_BROWSER_REQUEST_ERROR_403_FALLBACK",
  API_REQUEST_ERROR_4XX_LOG = "API_BROWSER_REQUEST_ERROR_4XX",
  API_REQUEST_ERROR_500 = "API_BROWSER_REQUEST_ERROR_500",
  API_REQUEST_ERROR_5XX_LOG = "API_BROWSER_REQUEST_ERROR_5XX",
  API_REQUEST_ERROR_502_OR_503_LOG = "API_BROWSER_REQUEST_ERROR_502_503",
  API_REQUEST_FIRING = "API_BROWSER_REQUEST_FIRING",
  API_REQUEST_FIRED = "API_BROWSER_REQUEST_FIRED",
}

// TODO config in env
export const DATADOG_LOG_SERVICE = "Certik Client Portal";

export const DATADOG_RUM_SERVICE = "skyharbor";

export const DATADOG_SITE = "datadoghq.com";
