import React, { useState, useCallback, useEffect } from "react";
import { checkUserSession } from "../../utils/frontend/fetchFromApi";
import { AUTH_DATA, USER_ID, COLORS } from "../../const";
import { localStore } from "../../utils/frontend/localStore";
import { Dialog } from "../SharedComponents/Dialog";
import { WarningLineIcon, Header, Paragraph } from "../SharedComponents";
import { Row } from "antd";
import { Button } from "../SharedComponents/Button";
import router from "next/router";
import { useStore } from "../../hooks";
import { Logger } from "../../utils/frontend/logger/browser-logger";

export default function SessionChecker() {
  const [store] = useStore();
  const [sessionExpired, setSessionExpired] = useState(false);

  async function updateSessionStatus() {
    // Browser log to monitor SessionChecker invocation
    const today = new Date();
    const invocationTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const userId = localStore.get(USER_ID);
    const authData = localStore.get(AUTH_DATA);
    const content = {
      body: {
        label: "invoke_session_checker",
      },
      invocationTime,
      userId,
    };
    Logger.log(content);
    // Remote config to control sesson checker
    if (store.configs?.sessionCheckerEnabled !== true) {
      return;
    }
    // If session already expired, no need to do the check again
    if (sessionExpired) {
      return;
    }

    if (!userId || !authData) {
      setSessionExpired(false);
      return;
    }
    const authResp = await checkUserSession(userId, JSON.parse(authData));
    console.log(`Trigger updateSessionStatus at ${invocationTime}: `, authResp);

    if (authResp?.result !== true) {
      setSessionExpired(true);
    }
  }

  const updateSessionStatusCallback = useCallback(updateSessionStatus, []);

  useEffect(() => {
    // Check user session every 6 hours, to be tuned
    const interval = setInterval(updateSessionStatusCallback, 6 * 3600 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [updateSessionStatusCallback]);

  return (
    <>
      {sessionExpired && (
        <Dialog size="small" visible={true} closable={false} mask={true}>
          <Row align="middle">
            {" "}
            <WarningLineIcon color={COLORS["gray-800"]} />
            <Header level={3} style={{ margin: "8px" }}>
              Refresh your page
            </Header>
          </Row>
          <Paragraph level={2} style={{ margin: "8px 0 24px 24px" }}>
            You have signed out in another tab or window, please refresh your page.
          </Paragraph>
          <Row justify="center">
            <Button
              onClick={() => {
                setSessionExpired(false);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                router.push("/login?force=true");
              }}
              style={{ marginBottom: "8px" }}
            >
              Refresh
            </Button>
          </Row>
        </Dialog>
      )}
    </>
  );
}
