/* eslint-disable no-unused-vars */
import create from "zustand";
import { devtools } from "zustand/middleware";
import { message } from "../components/SharedComponents/message";
import {
  getUserProjectInfo,
  getCollaboratorsForMention,
  updateProject,
  signOffReport,
  confirmReport,
  showSignOff,
  createJiraTicket,
  fetchProgressList,
  getContentfulInfoByAccId,
  fetchS3PresignedUrl,
  getIncrementalFindingsReport,
  getReportCommentCount,
  getProjectSecurityScoreDetailsByProjectId,
  securityScoreSignOff,
} from "../utils/frontend/fetchFromApi";
import {
  formUniqueFiles,
  deleteReports,
  uploadFiles,
  generateUrlValidator,
  tokenValidator,
  coinGeckoValidator,
  coinMarketCapValidator,
  twitterLinkValidator,
  telegramLinkValidator,
  certikDotComPublishLinkValidator,
} from "../utils/frontend/utils";
import { mentionListAdapter } from "../utils/common/utils";
import { validateProjectName, validateProjectTenantIds } from "../utils/common/validators";
import { AUDIT_STATUS } from "../const/audit";
import i18next from "../components/SharedComponents/i18n";
import { constructAuditStatusState } from "../components/SharedComponents/constructAuditStatusState";
import { JIRA_TICKET_ACTION_TYPE } from "../const/jira";
import { globalWarningNotification } from "../components/SharedComponents/notification";
import { AUTO_SIGN_OFF_PERIOD } from "../const/security-score";

const isTokenTickerValid = async (value) => {
  if (value == null || value === "") {
    return true;
  }
  return await tokenValidator(value);
};

const isCoinGeckoLinkValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return coinGeckoValidator(value);
};

const isCoinMarketCapLinkValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return coinMarketCapValidator(value);
};

const isTelegramLinkValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return telegramLinkValidator(value);
};

const isTwitterLinkValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return twitterLinkValidator(value);
};

const isPublishLinkValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return certikDotComPublishLinkValidator(value);
};

const isURLValid = (value) => {
  if (value == null || value === "") {
    return { passed: true };
  }
  return generateUrlValidator({})(value);
};

const projectDrawerStore = (set, get) => ({
  projectLoading: false,
  selectedProjectId: null,
  submitting: false,
  projectInfo: null,
  modifiedProjectInfo: null,
  collaborators: null,
  tableRefreshSymbol: Symbol(),
  projectRefreshSymbol: Symbol(),
  signOffModalVisible: false,
  signOffReportId: null,
  publishModalVisible: false,
  communicationModalVisible: false,
  publishReportId: null,
  isPublish: false,
  addedAttachments: [],
  deleteAttachmentNameList: [],
  progress: null,
  progressLoading: false,
  auditSteps: null,
  tenantNameMap: {},
  set,
  getProjectData: async (userId, authData, id, shouldGetProgressList = true) => {
    const startState = { projectLoading: true, selectedProjectId: id };
    if (shouldGetProgressList) {
      startState.progressLoading = true;
    }
    set(startState);
    try {
      const [info, incrementalReports] = await Promise.all([
        getUserProjectInfo(userId, id, authData),
        getIncrementalFindingsReport(userId, authData, id),
      ]);
      const progressListItems = {};

      const setProgressListItems = async () => {
        if (shouldGetProgressList) {
          let progress = null;
          let auditSteps = null;
          try {
            // get the data
            progress = await fetchProgressList(id, userId, authData);
            if (progress != null) {
              auditSteps = constructAuditStatusState(info, progress);
            }
          } catch (error) {
            // catch the errors
            console.error(error);
          } finally {
            // set the loading state to false
            progressListItems.progressLoading = false;
            progressListItems.progress = progress;
            progressListItems.auditSteps = auditSteps;
          }
        }
      };

      // Get security score and collaborators
      const [securityScoreDetails, collaboratorsResp, contentfulResult] = await Promise.all([
        getProjectSecurityScoreDetailsByProjectId(userId, info.tenantId, authData, info.id),
        getCollaboratorsForMention(info.id, info.tenantId, userId, authData, false, info.tenantIds),
        info.acceleratorProjectId && info.acceleratorProjectId !== "unknown"
          ? getContentfulInfoByAccId(userId, info.tenantId, authData, info.acceleratorProjectId)
          : Promise.resolve(null),
        fetchProgressList(id, userId, authData),
        setProgressListItems(),
      ]);

      // set Contentful Id (certik only)
      if (
        contentfulResult &&
        info.acceleratorProjectId &&
        info.acceleratorProjectId !== "unknown"
      ) {
        info.contentfulId = contentfulResult.success ? contentfulResult?.result?.sysId : null;
      }

      info.securityScoreDetails = securityScoreDetails?.score ? securityScoreDetails : null;
      const collaboratorsList = mentionListAdapter(collaboratorsResp, info.tenantName);
      // this is to support the old property `signedOffBy`
      if (info.auditReportList != null && info.auditReportList.length > 0) {
        info.auditReportList.forEach((report) => {
          // if there's no signed off by property but it is signed off
          if (!report.signedOff && report.signedOffBy) {
            report.signedOff = {
              value: true,
              updatedAt: report.updatedAt,
              updatedBy: report.signedOffBy,
            };
          }
          delete report.signedOffBy;
        });
      }

      // incremental report if any
      let incrementalReportItem;
      if (incrementalReports?.length > 0) {
        incrementalReportItem = {
          ...incrementalReports[0],
          findings: [],
          count: {
            clientCommentAmount: 0,
            certikCommentAmount: 0,
            lastCommentByCertikAt: 0,
            lastCommentByClientAt: 0,
          },
        };

        await Promise.all(
          incrementalReports.map(async (report) => {
            // Recalculate finding
            report.findings.forEach((finding) =>
              incrementalReportItem.findings.push({
                ...finding,
                reportUploadTime: report.uploadTime,
              })
            );
            // Recalculate Comments
            const reportCommentCount = await getReportCommentCount(report.id, id, userId, authData);
            incrementalReportItem.count.clientCommentAmount +=
              reportCommentCount.clientCommentAmount;
            incrementalReportItem.count.certikCommentAmount +=
              reportCommentCount.certikCommentAmount;
            incrementalReportItem.count.lastCommentByCertikAt = Math.max(
              incrementalReportItem.count.lastCommentByCertikAt,
              reportCommentCount.lastCommentByCertikAt
            );
            incrementalReportItem.count.lastCommentByClientAt = Math.max(
              incrementalReportItem.count.lastCommentByClientAt,
              reportCommentCount.lastCommentByClientAt
            );
          })
        );
      }

      set({
        projectInfo: {
          ...info,
          incrementalReport: {
            reportItem: incrementalReportItem,
          },
        },
        collaborators: collaboratorsList,
        projectLoading: false,
        projectRefreshSymbol: Symbol(),
        addedAttachments: [],
        deleteAttachmentNameList: [],
        ...progressListItems,
      });
    } catch (err) {
      set({ projectInfo: null, projectLoading: false });
      message.error(
        i18next.t("components.SharedComponents.message.fetchFail", { dataName: "project" })
      );
      console.error(err.message);
    }
  },
  getProgressList: async (userId, authData, id) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    // check for projectId
    if (!id) return false;

    // 1. set the loading state
    set({ progressLoading: true });
    let progress = null;
    try {
      // get the data
      progress = await fetchProgressList(id, userId, authData);
    } catch (error) {
      // catch the errors
      console.error(error);
    } finally {
      // set the loading state to false
      set({ progressLoading: false, progress });
    }
    return true;
  },
  submitChanges: async (userId, tenantId, authData) => {
    if (userId == null || authData == null) {
      return false;
    }
    const projectInfo = get().projectInfo;
    const modifiedProjectInfo = get().modifiedProjectInfo;
    const errorMessages = [];
    const projectNameValid = validateProjectName(modifiedProjectInfo.projectName);
    const telegramValidate = isTelegramLinkValid(modifiedProjectInfo.telegramLink);
    const twitterValidate = isTwitterLinkValid(modifiedProjectInfo.twitterLink);
    // const tokenValidate = await isTokenTickerValid(modifiedProjectInfo.tokenTicker);
    const geckoValid = isCoinGeckoLinkValid(modifiedProjectInfo.coinGeckoLink || "");
    const marketCapValid = isCoinMarketCapLinkValid(modifiedProjectInfo.coinMarketCapLink || "");
    const publishLinkValid = isPublishLinkValid(modifiedProjectInfo.publishedLink || "");
    const companyWebsiteLinkValid = isURLValid(modifiedProjectInfo.companyWebsiteLink || "");
    const tenantValid = validateProjectTenantIds(modifiedProjectInfo.tenantIds);
    if (!projectNameValid.valid) {
      errorMessages.push(projectNameValid.reason);
    }

    if (!telegramValidate.passed) {
      // @ts-ignore
      errorMessages.push("Invalid Telegram link: " + telegramValidate.reason);
    }

    if (!twitterValidate.passed) {
      // @ts-ignore
      errorMessages.push("Invalid Twitter link: " + twitterValidate.reason);
    }

    // if (!tokenValidate) {
    //   errorMessages.push("Invalid token");
    // }

    if (!publishLinkValid.passed) {
      // @ts-ignore
      errorMessages.push("Invalid Publish link: " + publishLinkValid.reason);
    }

    if (!marketCapValid.passed) {
      // @ts-ignore
      errorMessages.push("Invalid CoinMarketCap link: " + marketCapValid.reason);
    }

    if (!geckoValid.passed) {
      // @ts-ignore
      errorMessages.push("Invalid Coin Gecko link: " + geckoValid.reason);
    }

    if (!companyWebsiteLinkValid.passed) {
      errorMessages.push("Invalid Company link: " + companyWebsiteLinkValid.reason);
    }
    const statusCompleted = modifiedProjectInfo.status === AUDIT_STATUS.COMPLETED;
    const requestToPublish = modifiedProjectInfo.clientRequestToPublish;
    const publishedLink = modifiedProjectInfo.publishedLink;
    if (statusCompleted && requestToPublish && (publishedLink == null || publishedLink == "")) {
      errorMessages.push("Cannot complete project without supplying a published link");
    }

    // Set proejct status as COMPLETE is publish link is provided, and if user does not set status manually.
    // TODO: Add validator to publish link
    if (
      (publishedLink != null || publishedLink !== "") &&
      projectInfo.status === modifiedProjectInfo.status &&
      projectInfo.publishedLink !== publishedLink
    ) {
      modifiedProjectInfo.status = AUDIT_STATUS.COMPLETED;
    }

    if (!tenantValid.valid) {
      errorMessages.push("Invalid Tenant: " + tenantValid.reason);
    }
    console.log(" modifiedProjectInfo.status: " + modifiedProjectInfo.status);

    if (errorMessages.length > 0) {
      message.error(errorMessages[0]); // show only the first error message
      // for (const msg of errorMessages) {
      //   message.error(msg);
      // }
      return false;
    }

    // All fields are valid, start updating
    set({ submitting: true });

    const { addedAttachments, deleteAttachmentNameList } = get();

    const uniqueAttchmntList = formUniqueFiles(addedAttachments);
    const uniqueAttchmntNameList = uniqueAttchmntList.map((item) => item.uniqueFileName);
    console.log("file unique names:", uniqueAttchmntNameList);
    // fetch s3 presigned url
    const urlList = await fetchS3PresignedUrl(
      userId,
      tenantId,
      authData,
      uniqueAttchmntNameList,
      "PUT",
      "FILE"
    );
    // TODO: change input to function
    const attachmentList =
      (urlList !== undefined &&
        urlList &&
        (await uploadFiles(uniqueAttchmntList, urlList, userId))) ||
      [];

    // Handle attachments deletion
    const deleteUrlList = await fetchS3PresignedUrl(
      userId,
      tenantId,
      authData,
      deleteAttachmentNameList,
      "DELETE",
      "FILE"
    );
    if (urlList === undefined || deleteUrlList === undefined || !urlList || !deleteUrlList) {
      globalWarningNotification(
        "Upload/Delete file failed",
        "you are not allowed to upload/delete file in this organization"
      );
    }
    // TODO: change input to function
    deleteUrlList !== undefined && deleteUrlList && (await deleteReports(deleteUrlList, userId));

    const resp = await updateProject(
      userId,
      modifiedProjectInfo.id,
      {
        ...modifiedProjectInfo,
        projectName: modifiedProjectInfo.projectName.trim(),
        attachments:
          (urlList !== undefined &&
            deleteUrlList !== undefined && [
              ...(modifiedProjectInfo.attachments || []),
              ...attachmentList,
            ]) ||
          projectInfo.attachments,
      },
      authData
    );
    if (resp === true) {
      message.success(i18next.t("components.SharedComponents.message.changesPosted"));
      // Remove lastFindingCommentByCertikAt and lastFindingCommentByClientAt if all reports are deleted
      // However, we are not sure whether the comment indicated by comment icon has beem deleted.
      // Becasue we are using project level timestamp. We might need report level timestamp in the future.
      // if (reportList.length === 0 && deleteReportNameList.length > 0) {
      //   console.log("update comment check");
      //   const latestProject = await getUserProjectInfo(
      //     store.userInfo.userId,
      //     store.drawerProjectId,
      //     store.userInfo.authData
      //   );
      //   await updateProjectCommentCheck(
      //     store.userInfo.userId,
      //     modifiedProjectInfo.id,
      //     {
      //       version: latestProject.version,
      //       tenantId: latestProject.tenantId,
      //       lastFindingCommentByCertikAt: 0,
      //       lastFindingCommentByClientAt: 0,
      //     },
      //     store.userInfo.authData
      //   );
      // }
    } else {
      message.error(i18next.t("components.SharedComponents.message.changesFail"));
      console.error(resp);
    }
    await get().getProjectData(userId, authData, modifiedProjectInfo.id);
    set({
      tableRefreshSymbol: Symbol(),
      submitting: false,
      addedAttachments: [],
      deleteAttachmentNameList: [],
    });
    return true;
  },
  confirmReport: async (userId, authData, reportId, confirmed) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    set({ projectLoading: true });

    // get the project info
    const { projectInfo } = get();

    const resp = await confirmReport(userId, authData, reportId, projectInfo.id, confirmed);

    if (resp !== null) {
      message.success(i18next.t("components.SharedComponents.message.projectConfirmSuccess"));
    } else {
      message.error(i18next.t("components.SharedComponents.message.projectConfirmFail"));
      console.error(resp);
    }
    await get().getProjectData(userId, authData, projectInfo.id);
    set({ projectLoading: false });
    return true;
  },
  signOff: async (userId, authData, values) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    set({ submitting: true });
    // get the project info
    const { projectInfo, signOffReportId } = get();

    // make sure the project has reports
    const auditReportList = projectInfo.auditReportList;

    if (auditReportList == null || auditReportList.length === 0) {
      set({ submitting: false });
      return false;
    }

    // determine the next status
    let nextStatus;
    let clientRequestToPublish = projectInfo.clientRequestToPublish;
    switch (projectInfo.status) {
      case AUDIT_STATUS.PRE_REPORT_READY:
        nextStatus = AUDIT_STATUS.PRE_REPORT_SIGNED_OFF;
        break;
      case AUDIT_STATUS.FINAL_REPORT_READY:
        clientRequestToPublish = !!values.publish;
        if (clientRequestToPublish) {
          nextStatus = AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF;
        } else {
          nextStatus = AUDIT_STATUS.COMPLETED;
        }
        break;
      default:
        // can only sign off when PRE_REPORT_READY || FINAL_REPORT_READY
        set({ submitting: false });
        return;
    }

    const signOffResp = await signOffReport(
      userId,
      authData,
      signOffReportId,
      projectInfo.id,
      projectInfo.version,
      clientRequestToPublish,
      nextStatus
    );

    console.log("signOffResp", signOffResp);

    if (signOffResp === true) {
      await Promise.all([
        nextStatus === AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF
          ? createJiraTicket(userId, authData, projectInfo.id, JIRA_TICKET_ACTION_TYPE.PUBLISH)
          : Promise.resolve(null),
        securityScoreSignOff(AUTO_SIGN_OFF_PERIOD, projectInfo?.id, userId, authData, "auto"),
      ]);

      message.success(i18next.t("components.SharedComponents.message.projectSignOffSuccess"));
    } else {
      message.error(i18next.t("components.SharedComponents.message.projectSignOffFail"));
      console.error(signOffResp);
    }
    await get().getProjectData(userId, authData, projectInfo.id);
    set({ tableRefreshSymbol: Symbol(), submitting: false, signOffModalVisible: false });
    return true;
  },
  manualSecurityScoreSignOff: async (userId, authData, pendingPeriod) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    // check for pendingPeriod
    if (pendingPeriod === undefined || pendingPeriod === null) return false;

    set({ submitting: true });

    // get the project info
    const { projectInfo } = get();

    await securityScoreSignOff(pendingPeriod, projectInfo?.id, userId, authData, "manual");

    await get().getProjectData(userId, authData, projectInfo?.id);
    set({ tableRefreshSymbol: Symbol(), submitting: false });
    return true;
  },
  publish: async (userId, authData, values) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    set({ submitting: true });

    // get the project info
    const { projectInfo } = get();

    // update the project
    const resp = await updateProject(
      userId,
      projectInfo.id,
      {
        ...projectInfo,
        clientRequestToPublish: values.clientRequestToPublish,
      },
      authData
    );

    if (resp === true) {
      const jiraTicketResp = await createJiraTicket(
        userId,
        authData,
        projectInfo.id,
        values.clientRequestToPublish
          ? JIRA_TICKET_ACTION_TYPE.PUBLISH
          : JIRA_TICKET_ACTION_TYPE.UNPUBLISH
      );

      message.success(i18next.t("components.SharedComponents.message.changesPosted"));
    } else {
      message.error(i18next.t("components.SharedComponents.message.changesFail"));
      console.error(resp);
    }
    await get().getProjectData(userId, authData, projectInfo.id);
    set({ tableRefreshSymbol: Symbol(), submitting: false, publishModalVisible: false });

    return true;
  },
  toggleShowSignoff: async (userId, authData, report, showSignoff) => {
    // check for userId
    if (!userId) return false;

    // check for authData
    if (!authData) return false;

    const { projectInfo } = get();

    set({ projectLoading: true });

    const resp = await showSignOff(userId, authData, report.id, projectInfo.id, showSignoff);

    if (resp !== null) {
      message.success(i18next.t("components.SharedComponents.message.changesPosted"));
    } else {
      message.error(i18next.t("components.SharedComponents.message.changesFail"));
      console.error(resp);
    }
    await get().getProjectData(userId, authData, projectInfo.id);
    set({ projectLoading: false });

    return true;
  },
});

// add the redux devtools if were in development
export const useProjectDrawer =
  process.env.NEXT_PUBLIC_ENV !== "development"
    ? create(projectDrawerStore)
    : create(devtools(projectDrawerStore));
