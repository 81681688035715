import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Row, Col, Skeleton, Popover } from "antd";
import { cls } from "../../utils/frontend/utils";
import styles from "./index.module.css";
import { Paragraph } from "../SharedComponents";
import { fetchUser } from "../../utils/frontend/fetchFromApi";
import { assigneeEmailMapToGroup, certikRoleEnum, COLORS, roleNameMap } from "../../const";
import { useStore } from "../../hooks";
import {
  DEFAULT_OPTION_LABEL,
  DEFAULT_OPTION_VALUE,
  DEPARTMENT_NAME_MAP,
  JOB_TITLE_NAME_MAP,
} from "../../const/user";
import { useRouter } from "next/router";
import { SUPPORT_EMAIL_ADDRESS } from "../../const/certik";

export default function AvatarPopover({ avatarWrapperProps, comment = {}, hasPopover = true }) {
  return hasPopover ? (
    <Popover
      zIndex={1000}
      trigger="hover"
      destroyTooltipOnHide={false}
      placement="top"
      getPopupContainer={(triggerNode) =>
        triggerNode.closest(".popup-container") || triggerNode.parentElement
      }
      overlayInnerStyle={{
        padding: 0,
        border: `1px solid ${COLORS["gray-100"]}`,
        borderRadius: 4,
        width: "max-content",
      }}
      content={<AvatarPopoverContent avatarWrapperProps={avatarWrapperProps} comment={comment} />}
    >
      <AvatarWrapper {...avatarWrapperProps} />
    </Popover>
  ) : (
    <AvatarWrapper {...avatarWrapperProps} />
  );
}

function AvatarPopoverContent({ avatarWrapperProps = {}, comment }) {
  const [commentUserInfo, setCommentUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [store] = useStore();
  const router = useRouter();
  const isSharedReportPage = useMemo(() => router.pathname.includes("shared-report"), [router]);
  const isSelf = useMemo(() => {
    return (
      avatarWrapperProps?.userId === store?.userInfo?.userId ||
      comment?.userId === store?.userInfo?.userId
    );
  }, [store, avatarWrapperProps, comment]);
  const isCommentAnonymous = comment?.isAnonymous || comment?.fromDiscord || comment?.fromSlack;
  // Hack to determine if its Customer Service
  const isCustomerServiceComment =
    comment?.userId === "superadmin@certik.org" ||
    (comment?.userName === "Customer Service" && comment?.role === "certikAdmin");

  useEffect(() => {
    async function fetchCommentUser() {
      if (isSelf) {
        setCommentUserInfo(store?.userInfo);
      } else {
        setIsLoading(true);
        if (
          comment?.userId &&
          !Object.keys(assigneeEmailMapToGroup).includes(comment?.userId) &&
          !isCommentAnonymous &&
          !isCustomerServiceComment &&
          !isSharedReportPage
        ) {
          // Has a userId
          // Is not a user group email
          // Is not an anonymous user
          // Is not a customer service comment
          // Is not shared report page
          const resp = await fetchUser(comment.userId, null, null, true);
          setCommentUserInfo(resp);
        } else {
          setCommentUserInfo({ ...comment });
        }
      }
      setIsLoading(false);
    }

    fetchCommentUser().catch(console.error);
  }, [
    comment,
    isCommentAnonymous,
    isCustomerServiceComment,
    isSelf,
    store?.userInfo,
    isSharedReportPage,
  ]);

  return (
    <Row wrap={false} gutter={12} align="top" justify="flex-start">
      <Col>
        <AvatarWrapper {...avatarWrapperProps} style={{ cursor: "default" }} />
      </Col>
      {isLoading ? (
        <Skeleton.Button block active />
      ) : (
        <Col style={{ width: "100%", flexDirection: "column", alignItems: "flex-start" }}>
          <Row>
            <Paragraph level={2} weight={600}>
              {isCommentAnonymous
                ? "Anonymous User"
                : commentUserInfo?.userName || avatarWrapperProps?.userName || ""}
            </Paragraph>
            <Paragraph level={2} color={COLORS["gray-500"]} style={{ marginLeft: 6 }}>
              {isSelf ? "you" : ""}
            </Paragraph>
          </Row>
          {commentUserInfo?.department ? (
            <Paragraph level={3} weight={400} color={COLORS["gray-700"]}>
              {DEPARTMENT_NAME_MAP[commentUserInfo?.department] ||
                (!commentUserInfo?.department ||
                commentUserInfo?.department === DEFAULT_OPTION_VALUE
                  ? DEFAULT_OPTION_LABEL
                  : store?.userInfo?.department)}
            </Paragraph>
          ) : null}
          {commentUserInfo?.jobTitle ? (
            <Paragraph level={3} weight={400} color={COLORS["gray-700"]}>
              {JOB_TITLE_NAME_MAP[commentUserInfo?.jobTitle] ||
                (!commentUserInfo?.jobTitle || commentUserInfo?.jobTitle === DEFAULT_OPTION_VALUE
                  ? DEFAULT_OPTION_LABEL
                  : commentUserInfo?.jobTitle)}
            </Paragraph>
          ) : null}
          {!isCustomerServiceComment && (commentUserInfo?.tenantName || commentUserInfo?.role) ? (
            <Paragraph level={3} weight={400} color={COLORS["gray-700"]}>
              {certikRoleEnum.includes(commentUserInfo?.role) ? (
                // No need to show tenant name for certik users
                roleNameMap[commentUserInfo?.role]
              ) : (
                <>
                  {commentUserInfo?.tenantName}{" "}
                  {commentUserInfo?.tenantName && commentUserInfo?.role && "("}
                  {roleNameMap[commentUserInfo?.role]}
                  {commentUserInfo?.tenantName && commentUserInfo?.role && ")"}
                </>
              )}
            </Paragraph>
          ) : null}
          {/* For email, if not self or customer service, inherit logic from original comment item only. E.g. certikAdmin, certikEng emails should not be exposed. */}
          <Paragraph level={3} weight={400} color={COLORS["gray-700"]}>
            {isSelf
              ? commentUserInfo?.userId ||
                commentUserInfo?.email ||
                comment?.userId ||
                comment?.email
              : isCustomerServiceComment
                ? SUPPORT_EMAIL_ADDRESS
                : comment?.userId || comment?.email || ""}
          </Paragraph>
        </Col>
      )}
    </Row>
  );
}

// As of Nov 2022 we have moved over to use `AvatarPopover`, which encompasses this component instead.
// Do not export this component, use `AvatarPopover` instead.
function AvatarWrapper({
  avatarUrl,
  size,
  userName,
  color = "orange",
  disabled,
  style = {},
  additionalShowPictureCondition = true,
  ...restProps
}) {
  const avatarClasses = ["avatar"];
  const [error, setError] = useState(false);
  if (disabled) {
    avatarClasses.push("disabled");
  }
  return (
    <Skeleton
      loading={!userName && !avatarUrl}
      active
      paragraph={false}
      title={false}
      avatar={{
        shape: "circle",
        size: size,
      }}
    >
      {!error && avatarUrl && avatarUrl !== "" && additionalShowPictureCondition ? (
        <Avatar
          {...restProps} // for popover props to be passed down
          src={avatarUrl}
          size={size}
          className={cls(styles, avatarClasses)}
          style={style}
          onError={() => setError(true)}
        />
      ) : (
        <Avatar
          {...restProps} // for popover props to be passed down
          size={size}
          className={cls(styles, avatarClasses)}
          style={{
            background: color,
            fontSize: "13px",
            lineHeight: "22px",
            ...style,
          }}
        >
          {userName &&
            userName
              .split(" ")
              .map((item) => item.slice(item.indexOf("(") + 1, item.indexOf("(") + 2))
              .join("")}
        </Avatar>
      )}
    </Skeleton>
  );
}
