export const REDIS_KEY_PREFIX_GET_PROJECT_BY_TENANT_ID = "getProjectsByTenantId";
export const REDIS_KEY_PREFIX_GET_PROJECT_TABLE = "getProjectTable";
export const REDIS_KEY_PREFIX_GET_SKYNET_BY_TENANT_ID = "getSkynetByTenantId";
export const REDIS_KEY_PREFIX_GET_SKYNET_TABLE = "getSkynetTable";
export const REDIS_KEY_PREFIX_GET_SKYNET_WITH_VMS_BY_TENANT_ID = "getSkynetByTenantIdWithVms";
export const REDIS_KEY_PREFIX_GET_SKYNET_TABLE_WITH_VMS = "getSkynetTableWithVms";
export const REDIS_KEY_PREFIX_GET_CONTRACT_BY_TENANT_ID = "getContractByTenantId";
export const REDIS_KEY_PREFIX_GET_MARKETPLACE_PROJECT_TABLE = "getMarketplaceProjectTable";
export const REDIS_KEY_PREFIX_GET_MARKETPLACE_ACTIONS_BY_PERIOD = "getMarketplaceActionsByPeriod";
export const REDIS_TTL_IN_SIX_HOUR = 6 * 60 * 60;
export const REDIS_USER_IP_DEVICE_TTL_DAY = 24 * 60 * 60;
export const REDIS_TTL_ONE_HOUR = 60 * 60;

export const REDIS_USER_TOKEN_TTL_15_MINUTES = 15 * 60;

export const REDIS_KEY_PREFIX_GET_TABLE_NAME_MAP = {
  audit: REDIS_KEY_PREFIX_GET_PROJECT_TABLE,
  skynet: REDIS_KEY_PREFIX_GET_SKYNET_TABLE,
};

export const REDIS_KEY_PREFIX_GET_BY_TENANT_ID_NAME_MAP = {
  audit: REDIS_KEY_PREFIX_GET_PROJECT_BY_TENANT_ID,
  skynet: REDIS_KEY_PREFIX_GET_SKYNET_BY_TENANT_ID,
};
