import styled from "styled-components";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { COLORS } from "../../const";
import { Select as AntdSelect } from "antd";
import { Space, Text, ArrowDropDownIconV2 } from "./";

const WideDiv = styled.div`
  ${(props) => props.wide && "width: 100%;"}
`;

// SEE UI SPEC FIGMA: https://www.figma.com/file/pDdSldZf4Eqax8jET4FZzO/SkyHarbor-UI-Spec?node-id=253%3A75887
const StyledSelect = styled(AntdSelect)`
  width: 100%;

  &.ant-select-single .ant-select-selection-item {
    padding-right: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px !important;
    color: ${COLORS["gray-800"]};
    top: 6px;
  }

  &.ant-select-single .ant-select-selection-search {
    left: 8px;
  }

  .ant-select-arrow {
    top: Calc(50% - 8.33px + 6px);
    right: 13.83px;
    margin-top: 0;
    height: 8.33px;
  }

  .ant-select-selector {
    padding-left: 8px !important;
    padding-right: 8px !important;
    overflow: hidden;
    height: 100%;
  }

  &.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 21px !important;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
  &.ant-select:hover {
    border-color: var(--gray-5);
  }

  &.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: var(--gray-800) !important;
    box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: var(--ck2);
  }

  &.ant-select-disabled {
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const ChunkySelect = styled(StyledSelect)`
  &:is(.ant-select, .ant-select.ant-select-status-error):not(.ant-select-customize-input)
    .ant-select-selector {
    height: 48px;
    border-radius: 4px;
    border-color: var(--gray-400);
    background: ${(p) => p.$background || "var(--gray-50)"};

    :hover {
      border-color: var(--gray-400);
    }

    .ant-select-selection-search-input {
      height: 48px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      top: 0;
      display: flex;
      align-items: center;
    }
  }
`;

/**
 * Hook containing state and handlers to support the custom Select behavior needed for maxTagLength
 */
function useMaxTagLengthProps(defaultValue, maxTagLength) {
  const [tagValues, setTagValues] = useState(
    Array.isArray(defaultValue) && defaultValue?.length ? defaultValue : []
  );
  const [currentTagValue, setCurrentTagValue] = useState("");

  const onTagCharInput = useCallback(
    (value) => {
      if (value?.length > maxTagLength) return;
      setCurrentTagValue(value);
    },
    [maxTagLength]
  );
  function onInputClear() {
    // Clearing tags triggers onDeselect for all tags, so we don't need to do that here
    setCurrentTagValue("");
  }
  function onTagDeselect(deselectedVal) {
    setTagValues((prev) => prev?.filter((val) => val !== deselectedVal));
  }
  const onTagSelect = useCallback(
    // Gets called when clicking a predefined tag option in the dropdown and also when submitting a user-defined tag
    (selectedVal) => {
      if (currentTagValue) setCurrentTagValue("");
      setTagValues((prev) => [...prev, selectedVal]);
    },
    [currentTagValue]
  );
  return useMemo(
    () => ({
      onSearch: onTagCharInput,
      value: tagValues,
      searchValue: currentTagValue,
      onClear: onInputClear,
      onDeselect: onTagDeselect,
      onSelect: onTagSelect,
    }),
    [currentTagValue, onTagCharInput, onTagSelect, tagValues]
  );
}

/**
 * Select input with dropdown based on AntD Select.
 * Determines dropdown placement based on window space available.
 *
 * See {@link https://ant.design/components/select/} for full props
 *
 * @param {any} props
 * @param {boolean} [props.usePopupContainer] Instead of parenting dropdown to parent element, parent to an element with class "popup-container" (if it doesn't work, try setting the element's position: relative)
 * @param {number} [props.maxTagLength] Limit length of user-defined tags
 * @param {number} [props.maxTagTextLength] Truncate tags, useful if you allow large tag lengths
 * @param {boolean} [props.wide] Full-width
 * @param {object} [props.style]
 */
export function Select({
  wide = false,
  style = {},
  maxTagLength,
  containerStyle = {},
  usePopupContainer = false,
  chunky = false,
  ...props
}) {
  const tagPropsWithMaxLength = useMaxTagLengthProps(props.defaultValue, maxTagLength);

  /**
   * Dropdown placement logic
   */
  const [selectPlacement, setSelectPlacement] = useState("bottomLeft");
  const [listHeight, setListHeight] = useState(0);
  const selectRef = useRef(null); // ref for the select input
  const { width, ...restStyle } = style; // width should go on outermost div, which dictates the component's width

  const getDropdownHeight = useCallback((node) => {
    // Calculates the height of the select menu dropdown
    // Callback ref passed to the dropdown div, only runs once dropdown is loaded into DOM (when dropdown opened for first time)
    if (node != null) {
      const height = parseInt(getComputedStyle(node).height, 10); // calculate height of the dropdown list
      handleDropdownOpen(true, height); // setting state is asynchronous so we call this in order to get the dropdown placement set for the first time it's opened
      setListHeight(height);
    }
  }, []);

  const handleDropdownOpen = (open, specifiedHeight) => {
    // Determines which location to place the dropdown based on height in viewport as well as listHeight
    // specifiedHeight is optional and gets used instead of listHeight
    if (open) {
      const rect = selectRef.current.getBoundingClientRect();
      const offsetTop = rect.top;
      const offsetBottomFromTop = rect.bottom;
      const offsetBottom = window.innerHeight - offsetBottomFromTop;
      if (offsetBottom < (specifiedHeight || listHeight) && offsetBottom < offsetTop) {
        setSelectPlacement("topLeft");
      } else {
        setSelectPlacement("bottomLeft");
      }
    }
  };

  const SelectComponent = chunky ? ChunkySelect : StyledSelect;

  return (
    <WideDiv ref={selectRef} wide={wide} style={{ width: width, ...containerStyle }}>
      {/* This outer div is needed because we can't use ref on a function component */}
      <SelectComponent
        dropdownRender={(originNode) => {
          return (
            <div className="select-dropdown" ref={getDropdownHeight}>
              {originNode}
            </div>
          );
        }}
        onDropdownVisibleChange={handleDropdownOpen}
        placement={selectPlacement}
        suffixIcon={<ArrowDropDownIconV2 size="10px" style={{ pointerEvents: "none" }} />}
        getPopupContainer={(triggerNode) =>
          usePopupContainer ? triggerNode.closest(".popup-container") : triggerNode.parentElement
        }
        style={restStyle}
        {...(props.mode === "tags" && maxTagLength && tagPropsWithMaxLength)} // we only need to use custom stuff if maxTagLength is used - otherwise we can default to AntD behavior
        {...props}
      />
    </WideDiv>
  );
}

const StyledOption = styled(AntdSelect.Option)``;

/**
 *
 * @param {any} icon The icon component
 * @param {string} label The text that shows on the option
 * @param {object} props
 * @param {string} props.className The additional class to option
 * @param {boolean} props.disabled Disable this option
 * @param {string} props.title title attribute of Select Option
 * @param {string | number} props.value Default to filter with this property
 * @example
 * ```jsx
    <Option value="jack">Jack</Option>
    <Option value="lucy">Lucy</Option>
    <Option value="disabled" disabled>
      Disabled
    </Option>
    <Option value="Yiminghe">yiminghe</Option>
 * ```
 */
export function Option({ icon = undefined, label = "", ...props }) {
  // So far icon, label and selected are invalid attributes
  // Our Option is the wrapper of Option of antd
  return (
    <StyledOption {...props}>
      <Space>
        {icon}
        <Text>{label}</Text>
      </Space>
    </StyledOption>
  );
}
