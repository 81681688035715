export const SKYNET_METER_COLORS = {
  POOR: {
    range: [0, 30],
    primary: "#CF1322",
    secondary: "#FFCCC7",
  },
  MEDIUM: {
    range: [30, 55],
    primary: "#D48806",
    secondary: "#FFD8BF",
  },
  GOOD: {
    range: [55, 80],
    primary: "#237804",
    secondary: "#C9DABC",
  },
  BEST: {
    range: [80, 100],
    primary: "#135200",
    secondary: "#C9DABC",
  },
};

export const SKYNET_SOCIAL_SCORE_MAP = {
  NEGATIVE: {
    label: "Negative",
    range: [0, 50],
  },
  NEUTRAL: {
    label: "Neutral",
    range: [50, 80],
  },
  POSITIVE: {
    label: "Positive",
    range: [80, 100],
  },
};

export const SKYNET_MARKET_SCORE_MAP = {
  NEUTRAL: {
    label: "Neutral",
    range: [0, 90],
  },
  STABLE: {
    label: "Stable",
    range: [90, 95],
  },
  ROBUST: {
    label: "Robust",
    range: [95, 100],
  },
};

export const SKYNET_BADGES = {
  CERTIK_KYC: "certikKyc",
  VERIFIED_CONTRACT: "verifiedContract",
  VERIFIED_EMBLEM: "verifiedEmblem",
  ACTIVE_BOUNTY: "activeBounty",
  SKYNET_IDENTIFICATION: "skynetIdentification",
  KYC_BRONZE: "kycBronze",
  KYC_SILVER: "kycSilver",
  KYC_GOLD: "kycGold",
};

export const SKYNET_BADGES_LABELS = {
  [SKYNET_BADGES.CERTIK_KYC]: "CertiK KYC",
  [SKYNET_BADGES.VERIFIED_CONTRACT]: "Verified Contract",
  [SKYNET_BADGES.VERIFIED_EMBLEM]: "Verified Emblem",
  [SKYNET_BADGES.ACTIVE_BOUNTY]: "Active Bounty",
  [SKYNET_BADGES.SKYNET_IDENTIFICATION]: "Skynet Identification",
  [SKYNET_BADGES.KYC_BRONZE]: "KYC Bronze",
  [SKYNET_BADGES.KYC_SILVER]: "KYC Silver",
  [SKYNET_BADGES.KYC_GOLD]: "KYC Gold",
};

export const SKYNET_BADGES_FOR_MEETING_LABELS = {
  [SKYNET_BADGES.CERTIK_KYC]: "CertiK KYC",
  [SKYNET_BADGES.VERIFIED_CONTRACT]: "Verified Contract",
  [SKYNET_BADGES.VERIFIED_EMBLEM]: "Verified Emblem",
  [SKYNET_BADGES.ACTIVE_BOUNTY]: "Active Bounty",
};

export const SKYNET_PROJECT_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
};

export const SKYNET_PROJECT_STATUS_MAP = {
  [SKYNET_PROJECT_STATUS.PENDING]: {
    label: "Pending",
    value: SKYNET_PROJECT_STATUS.PENDING,
  },
  [SKYNET_PROJECT_STATUS.APPROVED]: {
    label: "Approved",
    value: SKYNET_PROJECT_STATUS.APPROVED,
  },
};

export const SKYNET_CHAIN_PREFIX = {
  ETH: "eth",
  BSC: "bsc",
  HECO: "heco",
  POLYGON: "polygon",
  ONE: "one",
};

export const SKYNET_SCAN_MAP = {
  [SKYNET_CHAIN_PREFIX.ETH]: "https://etherscan.io",
  [SKYNET_CHAIN_PREFIX.BSC]: "https://bscscan.com",
  [SKYNET_CHAIN_PREFIX.HECO]: "https://hecoinfo.com",
  [SKYNET_CHAIN_PREFIX.POLYGON]: "https://polygonscan.com",
  [SKYNET_CHAIN_PREFIX.ONE]: "https://explorer.harmony.one",
};

export const SKYNET_SAFETY_METRIC_DESCRIPTIONS = {
  ["Exploits & Inclidents"]: "High scoring projects have never been hacked.",
  ["Bug Bounty"]: "High scoring projects have well promoted bug bounty programs.",
  ["Decentralization"]:
    "High scoring projects have few special privileges held by an internal team.",
  ["Historical Audits"]: "High scoring projects have been audited by leading security firms.",
  ["Established Reputation"]:
    "High scoring projects have established well-known reputations and security efforts in regards to their publicly available project offering.",
  ["Active Usage"]: "High scoring projects are used on a daily basis by end users.",
  ["Explorer Verification"]:
    "High scoring projects have their source code and tags easily available online.",
  ["Active Maintenance"]:
    "High scoring projects are updated at least weekly either on the protocol or community level.",
  ["Documentation"]:
    "High scoring projects have document hubs or white papers with detailed explanations of its technical stacks.",
  ["Testnet Deployment"]:
    "High scoring projects maintain contracts on testnets for third parties to interact with.",
  ["Source Code Quality"]:
    "High scoring projects have a significant portion of their source code commented and covered by tests.",
  ["Major Exchange Listings"]:
    "High scoring projects have been listed on major exchanges with deep liquidity or high legitimate volume.",
  ["Insurance & Protections"]:
    "High scoring projects have insurance-like coverages for end users to protect their assets.",
  ["Open Source"]: "High scoring projects have public repositories available on GitHub.",
  ["Accessible Identities"]:
    "High scoring projects have the names of their team members transparent and easy to find.",
};

export const SKYNET_HISTORY_PROPERTIES = {
  contractAddressUrlList: "Contracts",
  website: "Website",
  github: "GitHub",
  status: "Status",
  projectName: "Project Name",
  priceUpperBound: "PriceUpperBound",
  skynetAlias: "Skynet Alias",
  priceLowerBound: "Price Lower Bound",
  tagList: "Tags",
  coinGeckoLink: "CoinGecko link",
  telegram: "Telegram",
  twitter: "Twitter",
  coinMarketCapLink: "CoinMarketCap link",
  tokenTicker: "Token ticker",
};

export const ISSUE_STATUS_VMS = {
  TO_ADDRESS: "to_address",
  ACKNOWLEDGED: "acknowledged",
  DISPUTED: "disputed",
  DEPRECATED: "deprecated",
  JOIN_SHARED_CHANNEL: "join_shared_channel",
  ACTION_TAKEN: "action_taken",
  PENDING_FOR_REVIEW: "pending_for_review",
  RESOLVED: "resolved",
};

export const ISSUE_STATUS = {
  TO_ADDRESS: "to_address",
  ACKNOWLEDGED: "acknowledged",
  ACTION_TAKEN: "action_taken",
  PENDING_FOR_REVIEW: "pending_for_review",
  DISPUTED: "disputed",
  RESOLVED: "resolved",
};

export const ISSUE_STATUS_ORDER = {
  [ISSUE_STATUS.TO_ADDRESS]: 0,
  [ISSUE_STATUS.ACKNOWLEDGED]: 1,
  [ISSUE_STATUS.DISPUTED]: 1,
  [ISSUE_STATUS.ACTION_TAKEN]: 2,
  [ISSUE_STATUS.PENDING_FOR_REVIEW]: 3,
  [ISSUE_STATUS.RESOLVED]: 4,
};

export const ISSUE_STATUS_READABLE_NAME_MAP = {
  [ISSUE_STATUS.TO_ADDRESS]: "To Address",
  [ISSUE_STATUS.ACKNOWLEDGED]: "Acknowledged",
  [ISSUE_STATUS.ACTION_TAKEN]: "Action Taken",
  [ISSUE_STATUS.PENDING_FOR_REVIEW]: "Pending For Review",
  [ISSUE_STATUS.DISPUTED]: "Disputed",
  [ISSUE_STATUS.RESOLVED]: "Resolved",
};

export const ISSUE_STATUS_VMS_READABLE_NAME_MAP = {
  [ISSUE_STATUS_VMS.TO_ADDRESS]: "To Address",
  [ISSUE_STATUS_VMS.ACKNOWLEDGED]: "Acknowledged",
  [ISSUE_STATUS_VMS.ACTION_TAKEN]: "Action Taken",
  [ISSUE_STATUS_VMS.PENDING_FOR_REVIEW]: "Pending For Review",
  [ISSUE_STATUS_VMS.DISPUTED]: "Disputed",
  [ISSUE_STATUS_VMS.RESOLVED]: "Resolved",
  [ISSUE_STATUS_VMS.DEPRECATED]: "Deprecated",
  [ISSUE_STATUS_VMS.JOIN_SHARED_CHANNEL]: "Join Shared Channel",
};

export const SKYNET_SIGNAL_ACTION_TYPE = {
  UserAcknowledge: "UserAcknowledge",
  UserDispute: "UserDispute",
  UserSubmitEvidence: "UserSubmitEvidence",
  UserUpdateEvidence: "UserUpdateEvidence",
  CertiKUpdatePendingReview: "CertiKUpdatePendingReview",
  CertiKUpdateResolved: "CertiKUpdateResolved",
  AdminAction: "AdminAction",
  UserAction: "UserAction",
};

export const TEXT_MAP = {
  Alert: {
    previewDescription:
      "Certik has identified some alerts for your project. These alerts normally indicate severe risk associated with your project.",
    description: (dynamicContent) =>
      `CertiK has identified the following alert for your project:  \n\n ${dynamicContent}.  \n\n Please note: these alerts normally indicate severe risk associated with your project.`,
    reason:
      "An alert signals the community that there is a high risk associated with your project. ",
    remediation:
      "If you believe this alert was made in error, please contact us immediately using the “DISPUTE” option in the [Action] section.  Please include any evidence you can to help us work with you on remediation. ",
    certikHelp:
      "We take alerts seriously.  If you need support remediating what has been flagged, please contact us immediately in the [Messages] section.",
  },
  Audit: {
    previewDescription:
      "Your past audits still have findings that are unresolved completely. Please revisit below related audits for improvement.",
    description:
      "Your past audits still have findings that are unresolved completely. Please revisit below related audits for improvement.",
    reason:
      "During your audit, CertiK flagged security findings.  Currently, some findings are unresolved.  Unresolved findings can expose your project to security risks and lower your Skynet Scores. ",
    remediation: (showSecondParagraph) =>
      `We understand that some issue improvement take time to mitigate or resolve. We encourage the project team re-evaluate the unresolved findings periodically for improvement or update. ${
        showSecondParagraph
          ? "\n\n Please talk with our expert if you have a new any security improvement plan."
          : ""
      }`,
    certikHelp:
      "If you have already resolved or mitigated these issues, but they are not reflected correctly in your report, please let us know.  Either reach out to your Certik representative directly, or input the relevant details using the “DISPUTE” option in the [Action] section. ",
  },
  "Blockchain Explorer Verification": {
    previewDescription: `CertiK has failed to identify your protocol's deployment and verification on any mainnet blockchain.`,
    description: `CertiK has failed to identify your protocol's deployment and verification on any mainnet blockchain. `,
    reason:
      'It is necessary to deploy so users may interact with your protocol. Additionally, source code verification provides transparency for users interacting with smart contracts. As with traditional contracts, a smart contract should provide end users with clarity on what they are "digitally signing" for and give users the ability to audit the code to independently verify that it actually performs its supposed function(s). ',
    remediation: `To remediate this Security Score deduction,
1. Deploy your protocol's smart contracts on a mainnet blockchain
2. Via the relevant block explorer tool, verify and publish contract source code for your protocol's smart contracts.
Note, this is a two-step process:
a. First, provide the address and information about the environment in which the smart contract will be created, (or find your contract from Etherscan and point it directly to the [Smart Contract Verification tool](https://etherscan.io/verifyContract).)
b. Next, provide detailed information about the smart contract itself.
When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation e.g. providing the relevant transaction hash(es). If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error because your contract(s) are already deployed and verified on chain, kindly inform us with the “DISPUTE” option in the [Action] section and include the relevant transaction hash(es). ",
  },
  "Blockchain Medium": {
    previewDescription: `CertiK has failed to identify your protocol's listing on CoinMarketCap and CoinGecko. `,
    description: `CertiK has failed to identify your protocol's listing on CoinMarketCap and CoinGecko. `,
    reason:
      "Listing on CoinMarketCap and CoinGecko establishes your protocol amongst a growing multi-chain ecosystem of Web3 protocols and their respective cryptocurrencies.  Both CoinMarketCap and CoinGecko empower users with unbiased, accurate information to support independent informed conclusions.",
    remediation: `To remediate this Security Score deduction,
1. Apply [here](https://support.coinmarketcap.com/hc/en-us/requests/new?ticket_form_id=360000493112) to add your cryptoasset to CoinMarketCap.
2. Follow [CoinGecko's guide](https://support.coingecko.com/hc/en-us/articles/7291312302617-How-to-list-new-cryptocurrencies-on-CoinGecko-) to list new cryptocurrencies.
3. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation e.g. relevant documentation of the listing process for each site. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error because your protocol and/or cryptocurrency is already listed on CoinMarketCap or CoinGecko, kindly inform us with the “DISPUTE” option in the [Action] section and include the relevant links. ",
  },
  "Bug Bounty": {
    previewDescription: `CertiK has no record of your project's participation in any ongoing bug bounty program. `,
    description: `CertiK has no record of your project's participation in any ongoing bug bounty program. `,
    reason:
      "Participation in an ongoing bug bounty program is a key component of any security-related best practice as ethical hackers can test your project code as a method to identify and fix vulnerabilities.",
    remediation: `To remediate this Security Score deduction,
1. Enroll in a bug bounty program for your protocol. If you are interested in CertiK's Bug Bounty program for your project, please contact your BD.
2. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation e.g. providing the relevant details and/or documentation of the enrollment process. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error because your protocol is already participating in an ongoing bug bounty program, kindly inform us with the “DISPUTE” option in the [Action] section and include the relevant details and/or documentation of the enrollment process.",
  },
  Centralization: {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Comment Ratio": {
    previewDescription: `CertiK has determined your project's codebase has a low ratio of comment code to functional code.`,
    description:
      "CertiK has determined your project's codebase has a low ratio of comment code to functional code. ",
    reason:
      "Commenting is an important practice to maintain long-term functionality of your software.  It provides context to other developers who work on the same codebase. ",
    remediation: `To remediate this Security Score deduction,
1. Add sufficient comments throughout your protocol's codebase as context for your functional code.
2. Resubmit your protocol's codebase for audit.
3. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp: `If you believe this message is an error because your protocol's codebase already includes sufficient comments for the functional code, kindly inform us with the “DISPUTE” option in the [Action] section.`,
  },
  Insurance: {
    previewDescription: `CertiK has no record of your project's subscription to any insurance policy.`,
    description: `CertiK has no record of your project's subscription to any insurance policy.`,
    reason:
      "Subscribing to an insurance policy minimizes risk to the community and other interested parties in unforeseen scenarios.",
    remediation: `To remediate this Security Score deduction,
1. Subscribe to an insurance policy for your project.
2. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation e.g. providing relevant details and/or documentation of your subscription to an insurance policy. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error because your project is subscribed to an insurance policy, kindly inform us with the “DISPUTE” option in the [Action] section.",
  },
  "Market Cap": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Market Volatility": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Open Source": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Privileged Operations": {
    previewDescription: `CertiK has identified a relatively high number of privileged call functions within your project's smart contract(s).`,
    description: `CertiK has identified a relatively high number of privileged call functions within your project's smart contract(s).`,
    reason:
      "Misuse of privileged functions, either intentionally or unintentionally by authorized users or unauthorized external entities can be a reason for concern and security risk.",
    remediation: `To remediate this Security Score deduction, you would ideally
1. Eliminate the privileged call functions from your protocol codebase.
2. Resubmit your protocol's codebase for audit.
3. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error because your protocol codebase does not include any privileged call functions, kindly inform us with the “DISPUTE” option in the [Action] section. Alternatively, if you would like further assistance to remediate, please drop us a message in the [Messages] section.",
  },
  "Project History": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Social Medium": {
    previewDescription:
      "CertiK has identified that one or more of your project's social media accounts are no longer active.",
    description: (medium) =>
      `CertiK has identified that your project's ${medium} account is no longer active.`,
    reason:
      "It is best practice for teams to use social media establish an open channel for communication with the community.  Typically, projects use Social Media to making announcements about project developments and provide other relevant news.",
    remediation: (medium) => `To remediate this Security Score deduction,
1. Reactivate your project's ${medium} account.
2. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp: (medium) =>
      `If you believe this message is an error because your project's ${medium} was never deactivated, kindly inform us with the “DISPUTE” option in the [Action] section. `,
  },
  "Social Sentiment": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Static Code Analysis": {
    previewDescription: "",
    description: "",
    reason: "",
    remediation: "",
    certikHelp: "",
  },
  "Team Identity": {
    previewDescription: `CertiK was unable to find your project's team members' identities or evidence of KYC completion during project onboarding.`,
    description: `CertiK was unable to find your project's team members' identities or evidence of KYC completion during project onboarding.`,
    reason: `Completing a KYC and/or publicly sharing team members' identities  establishes trust between a project team and its community. Users and other interested parties can be confident in interacting with your protocol when your team is doxxed or KYC'd while your team can be held accountable in unforeseen scenarios.`,
    remediation: `To remediate this Security Score deduction,
1. Either KYC or add public documentation of your team members' identities to your project website or docs. If you are interested in CertiK's KYC services for your project, please contact your BD.
2. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation e.g. relevant details and/or documentation of the KYC process. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp: `If you believe this message is an error because your project team is KYC'ed or there exists public documentation of team members' identities, please let us know. This can be an easy fix - inform us with the “DISPUTE” option in the [Action] section.`,
  },
  "Test Coverage": {
    previewDescription:
      "CertiK does not have any test coverage data for your project.  We measure test coverage with regular, automated scanning of your code-base.",
    description:
      "CertiK does not have any test coverage data for your project.  We measure test coverage with regular, automated scanning of your code-base.",
    reason:
      "Test coverage is a best practice to ensure functional software quality.  Low quality software can lead to unintended behavior and be a risk to your users.",
    remediation: `This score directly relates on the functional test coverage of your project's code-base.  To remediate, you can increase the density of your test coverage. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation. If there are any questions, please drop us a message in the [Messages] section. `,
    certikHelp:
      "If you believe this is flagged in error, please choose the “DISPUTE” option in the [Action] section and share the URL to your code repository.  We will work with you to ensure our active scanning is accurate.",
  },
  "Test Environment": {
    previewDescription: "CertiK has no record of your project deploying on a blockchain testnet.",
    description: "CertiK has no record of your project deploying on a blockchain testnet.",
    reason:
      "Testnets are valuable to test and experiment with your protocol without risk to real funds or the protocol on mainnet.",
    remediation: `To remediate this Security Score deduction,
1. Deploy following project developments first on testnet.
2. When you are done, inform us by following the steps outlined in the [Action] section to acknowledge the issue and submit evidences of remediation. If there are any questions, please drop us a message in the [Messages] section.`,
    certikHelp:
      "If you believe this message is an error, kindly choose the “DISPUTE” option in the [Action] section and provide us evidence(s) of your project being deployed on a blockchain testnet.",
  },
  "Whale Detection": {
    previewDescription: `CertiK has identified the following addresses holding noteworthy portions of the circulating supply of your project's native protocol asset.`,
    description: `CertiK has identified the following addresses holding noteworthy portions of the circulating supply of your project's native protocol asset.`,
    reason:
      "'Whales' can add volatility to cryptocurrency markets by moving large amounts of tokens in a short timeframe.  This can result in supply shocks to liquidity markets and fluctuations in price.  In worst cases, profit-seeking market participants can intentionally destabilize the trading environment for other retail traders.",
    remediation: `It is possible what we think is a whale is actually an address you use as part of normal project operations (e.g., if a liquidity pool contract address or burn address).  If this is the case, inform us by choosing the "DISPUTE" option in the [Action] section or drop us a message in the [Messages] section.  Your score will be adjusted accordingly and we will update our “whale watchers” accordingly.`,
    certikHelp: `If you believe this message is in error, kindly inform us via the "DISPUTE" option in the [Action] section and provide the addresses tjat should not be considered whales (e.g., if a liquidity pool contract address or burn address)`,
  },
};

export const SIGNAL_WHITELIST = [
  "Audit",
  "Alert",
  "Blockchain Explorer Verification",
  "Blockchain Medium",
  "Bug Bounty",
  "Comment Ratio",
  "Insurance",
  "Privileged Operations",
  "Social Medium",
  "Team Identity",
  "Test Coverage",
  "Test Environment",
  "Whale Detection",
  "Centralization",
];

export const DECORATED_SKYNET_WHITELIST = [
  "id",
  "tenantId",
  "tenantName",
  "projectName",
  "skynetAlias",
  "badgeData",
  "trustScoreData",
  "contractAddressUrlList",
  "telegramMeta",
  "platforms",
  "tenantIds",
];

//not currently used, but optional way to enforce schema.
//Could be used in preview or development when cache results are missing properties added recently - in that case it can bypass cache
export const DECORATED_SKYNET_SCHEMA = {
  id: "",
  tenantId: "",
  tenantName: "",
  projectName: "",
  //removing this, since project may be missing skynetAlias
  // skynetAlias: "",
  badgeData: {
    skynetIdentification: false,
    certikKyc: false,
    verifiedContract: false,
    verifiedEmblem: false,
    activeBounty: false,
  },
  trustScoreData: {
    trustScores: {
      communityMarketScore: 0,
      communityMarketScoreWithoutCap: 0,
      postedAt: 0,
      score: 0,
      scoreWithoutCap: 0,
      securityScore: 0,
      securityScoreWithoutCap: 0,
    },
    previousTrustScores: [],
    previousScanTime: 0,
    nextScanTime: 0,
    activeSignals: {},
    issueStatusSummary: {
      to_address: 0,
      acknowledged: 0,
      action_taken: 0,
      pending_for_review: 0,
      disputed: 0,
      resolved: 0,
    },
    reformattedIssueList: [],
  },
};

export const DECORATE_SKYNET_OPTIONS = {
  TENANT_NAME: "tenantName",
  TRUST_SCORE: "trustScore",
  VMS: "vms",
};

//makese sure all keys in schema are present in target with same type
//if there are additional keys in target, still returns true
export const validateSchema = (target, schema) => {
  for (var key in schema) {
    //check if target has schema attribute
    if (!(key in target)) {
      console.log(`schema validation false, target missing property: ${key}`);
      return false;
    }
    //check if attribute types match
    //if target[key]===null rather than undefined, schema matches since it was intentionally defined as null
    if (typeof target[key] !== typeof schema[key] && target[key] !== null) {
      console.log(
        `schema validation false, target property ${key} has type ${typeof target[
          key
        ]} when ${typeof schema[key]} was expected`
      );
      return false;
    }
    //if type is {}, run function recursively to check contents of object
    if (typeof schema[key] == "object") {
      const recursiveResult = validateSchema(target[key], schema[key]);
      if (!recursiveResult) return false;
    }
  }
  // console.log("schema validation true");
  return true;
};

export const SKYNET_REMEDIATION_TYPE = {
  VMS: "vms",
  // TODO: backfill all entryType with empty value to signal in CLIENT_PORTAL_SKYNET_SIGNAL_REMEDIATION_TABLE
  SIGNAL: "signal",
};

export const SKYNET_FINDING_TYPE = {
  CENTRALIZATION_RISK: "cmrisk",
};
