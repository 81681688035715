import { FEATURE_MAP } from "./feature";

export const AppConfig = {
  defaultFeatureIdList: [
    FEATURE_MAP.audit.id,
    FEATURE_MAP.securityScore.id,
    FEATURE_MAP.intercom.id,
    FEATURE_MAP.splitView.id,
    FEATURE_MAP.auditOverview.id,
    FEATURE_MAP.dailySummaryEmail.id,
  ],
  supportedFeatureIdList: [
    FEATURE_MAP.audit.id,
    FEATURE_MAP.securityScore.id,
    FEATURE_MAP.intercom.id,
    FEATURE_MAP.splitView.id,
    FEATURE_MAP.auditOverview.id,
    FEATURE_MAP.dailySummaryEmail.id,
    FEATURE_MAP.invoice.id,
    FEATURE_MAP.dashboard.id,
    FEATURE_MAP.marketplace.id,
    FEATURE_MAP.freeBugBounty.id,
    FEATURE_MAP.overviewDashboard.id,
    FEATURE_MAP.apiKey.id,
    FEATURE_MAP.referralProjectTracker.id,
  ],
  serviceName: "SkyHarbor",
  domain: process.env.NEXT_PUBLIC_SKYHARBOR_DOMAIN,
};

/** Corresponds to NextJS router.pathname, AKA the route under our /pages directory */
export const PUBLIC_ROUTES = {
  "/shared-report/[linkId]": true,
  "/shared-report/incremental/[linkId]": true,
  "/risk-manager/search": true,
  "/risk-manager/transaction-flow-analyzer": true,
};
