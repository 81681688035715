// TODO fix jest mock issue
// import { DeviceUUID } from "device-uuid";

let initialized = false;
let deviceId = "";
let deviceDua = "";

export const getDeviceId = () => {
  if (!initialized) {
    const { DeviceUUID } = require("device-uuid"); // eslint-disable-line
    const du = new DeviceUUID().parse();
    const dua = [
      du.language,
      du.platform,
      du.os,
      du.browser,
      du.resolution,
      du.version,
      du.pixelDepth,
      du.source,
      // du.cpuCores, not reliable, browser can report different values: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/hardwareConcurrency
      du.isAuthoritative,
      du.silkAccelerated,
      du.isKindleFire,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isiPod,
      du.isSmartTV,
      // du.pixelDepth,
      du.isTouchScreen,
    ];

    deviceDua = dua.splice(0, 7).join(":");
    deviceId = du.hashMD5(dua.join(":"));
    initialized = true;
  }
  return deviceId;
};

export const getDeviceDua = () => {
  if (!initialized) {
    getDeviceId();
  }
  return deviceDua;
};
