import { SERVICE_NAME } from "./types";

export const SUPPORT_EMAIL_ADDRESS = process.env.NEXT_PUBLIC_CLIENT_SUPPORT_EMAIL_ADDRESS;

export const EMAIL_SUBJECT_TEMPLATE = `${SERVICE_NAME} Support`;

export const EMAIL_BODY_TEMPLATE = `Dear ${SERVICE_NAME} Team, \n\nI would like to get your support on the following issues. Please see details below: \n\nBest, Client`;

export const ENCODED_EMAIL_BODY_TEMPLATE = encodeURIComponent(EMAIL_BODY_TEMPLATE);

// Used in Risk Manager for system-generated data
export const CERTIK_SYSTEM = {
  DISPLAY_NAME: "System",
  FULL_NAME: "CertiK System",
  USERID: "certik.com",
  AVATAR_SRC: "/ck-logo.png",
};

export const SystemEvidenceCreatedByInfo = {
  id: CERTIK_SYSTEM.USERID,
  userId: CERTIK_SYSTEM.USERID,
  userName: CERTIK_SYSTEM.DISPLAY_NAME,
  avatarUrl: CERTIK_SYSTEM.AVATAR_SRC,
};
