import { useState, useEffect } from "react";
import { useStore } from "./useStore";
import { checkIfFeatureEnabled } from "../utils/frontend/utils";
import { AppConfig } from "../const/appConfigs";

export function useVisibility(feature) {
  const [store] = useStore();
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [storeLoading, setStoreLoading] = useState(true);
  useEffect(() => {
    if (feature === null) {
      setIsPageVisible(true);
      setStoreLoading(false);
      return;
    }
    let flag = checkIfFeatureEnabled(store, feature);
    if (!AppConfig?.supportedFeatureIdList.includes(feature)) {
      flag = false;
    }
    if (flag != null) {
      setStoreLoading(false);
      setIsPageVisible(flag);
    }
  }, [store, feature]);

  return feature ? [isPageVisible, storeLoading] : [true, false];
}
