import React, { useMemo } from "react";
import { Comment as AntdComment, Tooltip, Image, Skeleton, Space, Checkbox } from "antd";
import AvatarPopover from "../AvatarPopover";
import Interweave from "interweave";
import { getAvatarColorPerUserId } from "../../utils/frontend/utils";
import { orEmpty } from "../../utils/common/array";
import { DateTime } from "luxon";
import { ConditionalWrap } from "./ConditionalWrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatTimestampWithTime } from "../../utils/common/format";
import { COLORS } from "../../const";
import { Paragraph } from "./Text";

const StyledCheckBox = styled(Checkbox)``;

const StyledComment = styled(AntdComment)`
  padding-right: 16;

  & > .ant-comment-inner {
    padding: 12px 0px;
  }

  & > .ant-comment-inner > .ant-comment-content > .ant-comment-content-author {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
`;

const StyledImage = styled(Image)`
  max-width: 100%;
`;

function getFormattedDates(time, locale) {
  if (!time) return { full: "", relative: "" };
  const datetime = DateTime.fromMillis(time);
  const full = formatTimestampWithTime(datetime);
  const relative = datetime.toRelative({ locale });
  return { full, relative };
}

/**
 *
 * @param {Object} props
 * @param {import("../../types/project-comment").ProjectComment} props.comment
 * @param {boolean} [forDownload=false]
 * @param {boolean} [loading=false]
 *
 */
export function Comment({ comment, forDownload = false, loading = false, selectOptions = null }) {
  const { i18n } = useTranslation();

  const { full, relative } = getFormattedDates(comment?.createdAt, i18n.language);
  const displayedTime = forDownload ? full : relative;
  const color = comment?.userId ? getAvatarColorPerUserId(comment?.userId) : undefined;

  const decodeAvatarUrl = (url) => {
    return Buffer.from(url || "", "base64").toString("ascii");
  };

  const avatarWrapperProps = useMemo(() => {
    return {
      size: 32,
      avatarUrl:
        !loading && comment?.avatarUrlEncoded
          ? decodeAvatarUrl(comment?.avatarUrl)
          : comment?.avatarUrl,
      userName: !loading && comment?.userName,
      color: color,
      additionalShowPictureCondition: !forDownload,
      userId: comment?.userId,
    };
  }, [loading, comment, color, forDownload]);

  return (
    <StyledComment
      style={{ paddingRight: 16 }}
      author={
        <Skeleton
          loading={loading}
          active
          paragraph={false}
          title={{
            style: { marginTop: 0, marginBottom: 0, height: 14, width: 150, borderRadius: "0px" },
          }}
        >
          <span style={{ color: COLORS["gray-800"], fontSize: 13, fontWeight: 600 }}>
            {comment.userName}
          </span>
        </Skeleton>
      }
      avatar={
        <>
          {selectOptions?.isSelectCommentMode ? (
            <Space size={15}>
              <StyledCheckBox
                type="checkbox"
                onChange={(event) => {
                  let action = "SELECT";
                  if (!event.target.checked) {
                    action = "UN_SELECT";
                  }
                  selectOptions?.commentOnSelect(comment, action);
                }}
              ></StyledCheckBox>
              <AvatarPopover avatarWrapperProps={avatarWrapperProps} comment={comment} />
            </Space>
          ) : (
            <>
              <AvatarPopover avatarWrapperProps={avatarWrapperProps} comment={comment} />
            </>
          )}
        </>
      }
      content={
        <Skeleton
          loading={loading}
          active
          paragraph={false}
          title={{ style: { marginTop: 12, marginBottom: 12, height: 20, borderRadius: "0px" } }}
        >
          <div>
            <Interweave content={comment?.text} />
            {orEmpty(comment?.images).map((image, idx) => (
              <StyledImage src={image} alt={image} key={idx} />
            ))}
          </div>
        </Skeleton>
      }
      datetime={
        !loading && (
          <ConditionalWrap
            condition={!forDownload}
            wrap={(children) => (
              <Tooltip
                title={full}
                getPopupContainer={(node) => node.closest(".popup-container") || node.parentElement}
              >
                {children}
              </Tooltip>
            )}
          >
            <Paragraph color="gray-500" level={3} weight={500} lineHeight="16px">
              {displayedTime}
            </Paragraph>
          </ConditionalWrap>
        )
      }
      data-cy="comment"
      data-testid={loading ? "comment-loading" : "comment-content"}
    />
  );
}
