import { useState, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { Row, Skeleton } from "antd";
import { Box } from "./Box";
import { Header } from "./Header";
import { Paragraph } from "./Text";
import { useAsyncEffect, useStore } from "../../hooks";
import { Option, Select } from "./Select";
import {
  fetchTenantInfo,
  getSlackChannelList,
  getDiscordChannelList,
  updateTenantInfo,
} from "../../utils/frontend/fetchFromApi";
import { Button } from "./Button";
import { message } from "./message";
import { APP_TYPE, APP_TYPE_LABEL_MAP } from "../../const";

export function RedirectCard({ countDown, redirectRoute }) {
  const [count, setCount] = useState(countDown);
  const router = useRouter();

  useEffect(() => {
    if (count == 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(redirectRoute);
    }
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);

  return (
    <>
      <Box
        mb={10}
        mt={20}
        style={{
          fontWeight: "500",
          fontSize: "20px",
        }}
      >
        Stay Tuned
      </Box>
      <Box mt={20} ml={30} mr={30}>
        You will be automatically re-directed back to SkyHarbor after:
      </Box>
      <Box mt={40} mb={45}>
        {count} seconds
      </Box>
      <Button type="primary" onClick={() => router.push(redirectRoute)}>
        Go back to SkyHarbor now
      </Button>
    </>
  );
}

/**
 * Slack/Discord channel selector card with redirect. After saving user's selected channel, redirects.
 * @param {string} props.redirectRoute The route to redirect to after saving
 * @param {string} props.platform The platform for which channels can be selected. Either APP_TYPE.SLACK or APP_TYPE.DISCORD
 * @returns {React.ReactNode}
 */
export function RedirectCardWithChannelPicker({ redirectRoute, platform = APP_TYPE.SLACK }) {
  const router = useRouter();
  const [store] = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [modifiedTenant, setModifiedTenant] = useState({});
  const [channelList, setChannelList] = useState([]);
  const { platformLabel, platformChannel } = useMemo(() => {
    return {
      platformLabel: APP_TYPE_LABEL_MAP[platform],
      platformChannel:
        platform === APP_TYPE.SLACK
          ? "slackChannel"
          : platform === APP_TYPE.DISCORD
            ? "discordChannel"
            : undefined,
    };
  }, [platform]);

  async function fetchTenant(tenantId) {
    const tenant = await fetchTenantInfo(
      tenantId,
      store?.userInfo?.userId,
      store?.userInfo?.authData
    );
    if (tenant) {
      setModifiedTenant({
        ...tenant,
        [platformChannel]: {}, // Force users to pick a channel even if they have existing
      });
    } else {
      // Either no permissions or the tenant truly doesnt exist
      message.error("No tenant found");
      await router.push(redirectRoute);
      return;
    }
  }

  async function fetchPlatformChannels(tenantId) {
    let platformData;
    if (platform === APP_TYPE.SLACK) {
      platformData = await getSlackChannelList(
        "tenant",
        store.userInfo?.userId,
        tenantId,
        store.userInfo?.authData
      );
    } else if (platform === APP_TYPE.DISCORD) {
      platformData = await getDiscordChannelList(
        "tenant",
        store.userInfo?.userId,
        tenantId,
        store.userInfo?.authData
      );
    }
    setChannelList(platformData?.channelList || []);
  }

  useAsyncEffect(async () => {
    const tenantId = router?.query?.tenantId || store?.userInfo?.tenantId;
    if (tenantId && store?.userInfo) {
      setIsLoading(true);
      await Promise.all([fetchTenant(tenantId), fetchPlatformChannels(tenantId)]);
      setIsLoading(false);
    }
  }, [router?.query?.tenantId, store?.userInfo]);

  const onChangeChannelOption = (data) => {
    setModifiedTenant((prev) => ({
      ...prev,
      [platformChannel]: {
        id: data.value,
        name: data.label,
      },
    }));
  };

  const handleSave = async () => {
    await onUpdateTenantInfo();
  };

  function trimSpace(tenant) {
    const trimmed = {
      ...tenant,
      name: tenant?.name?.trim(),
      description: tenant?.description?.trim(),
    };
    return trimmed;
  }

  const onUpdateTenantInfo = async (showLoading = true) => {
    showLoading && setIsLoading(true);
    const trimmedModifiedTenant = trimSpace(modifiedTenant);

    const response = await updateTenantInfo(
      trimmedModifiedTenant,
      store.userInfo?.userId,
      store.userInfo?.authData
    );

    if (!response || !response.info) {
      message.error(response?.error || response?.errMsg || "Updating tenant info failed");
      showLoading && setIsLoading(false);
      return;
    } else {
      message.success(`${platformLabel} channel set successfully!`);
      await router.push(redirectRoute);
    }
  };

  return (
    <Row
      style={{
        flexDirection: "column",
        paddingBottom: 24,
        paddingTop: 44,
        paddingLeft: 40,
        paddingRight: 40,
      }}
    >
      <Header marginTop={8} marginBottom={8} level={3}>
        Select a Public {platformLabel} Channel
      </Header>
      <Paragraph marginTop={8} marginBottom={16} level={2}>
        A public {platformLabel} channel is recommended for you and your team to receive SkyHarbor
        notifications.
      </Paragraph>
      <Paragraph level={2} style={{ paddingBottom: 4 }}>
        Select a channel
      </Paragraph>
      {isLoading ? (
        <Skeleton.Button active block />
      ) : (
        <Select
          showSearch
          wide={true}
          labelInValue
          placeholder="Select"
          filterOption={(input, option) => {
            return option.children.toLowerCase().includes(input.toLowerCase());
          }}
          onChange={onChangeChannelOption}
        >
          {channelList?.map((item) => (
            <Option key={item.id} value={item.id}>
              {"#" + item.name}
            </Option>
          ))}
        </Select>
      )}
      <Button
        type="primary"
        onClick={handleSave}
        style={{ marginTop: 24, width: "fit-content", alignSelf: "center" }}
        disabled={
          !modifiedTenant?.[platformChannel] ||
          Object.keys(modifiedTenant?.[platformChannel] || []).length === 0
        }
      >
        Save
      </Button>
    </Row>
  );
}
